import React, { Component, ReactNode } from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { AssetCollection } from "../../../../api/models/AssetCollection";
import { GroupItem } from "./GroupItem";
import { AssetOrGroupSearchResult, SearchResultType } from "../../../../search/AssetOrGroupResult";
import { ThirtyFpsSelect } from "@mui/icons-material";
import { FilterVisibleState } from "./FilterBar";
import { UserService } from "../../../../services/UserService";

export interface GroupListProps
{
    groups: Array<AssetCollection>;
    onEditGroup: (group: AssetCollection) => void;
    onViewGroup: (group: AssetCollection) => void;
    onDeleteGroup: (group: AssetCollection) => void;
    onRemoveGroup: (group: AssetCollection) => void;
    canEditParent: boolean;

    searchResults: Array<AssetOrGroupSearchResult>;
    filter: FilterVisibleState;
    searching: boolean;
}

interface GroupListState 
{
    rows: Array<JSX.Element>;
}

export class GroupList extends Component<GroupListProps, GroupListState>
{
    constructor(props: GroupListProps)
    {
        super(props);

        this.state = {
            rows: this.buildRows(this.props.groups)
        };
    }

    public componentDidMount(): void
    {
        if (this.props.searchResults?.length > 0 && this.props.searching)
        {
            this.buildFromSearchResults();
        }
        else 
        {
            this.buildFromGroups();
        }
    }

    public componentDidUpdate(prevProps: Readonly<GroupListProps>, prevState: Readonly<GroupListState>, snapshot?: any): void
    {
        if (this.props.searchResults?.length !== prevProps.searchResults?.length || this.props.filter !== prevProps.filter)
        {
            if (this.props.searching === false)
            {
                console.log("componentDidUpdate");
                this.buildFromGroups();
            }
            else
            {
                console.log("componentDidUpdate");
                this.buildFromSearchResults();
            }
        }
    }

    private buildFromGroups(): void 
    {
        console.log("buildFromGroups", this.props.groups);

        this.setState({
            rows: this.buildRows(this.props.groups)
        });
    }

    private groupAllowed(group: AssetCollection): boolean 
    {
        if (this.props.filter === FilterVisibleState.PublicOnly && group.isPublic === false)
        {
            return false;
        }

        if (this.props.filter === FilterVisibleState.PrivateOnly && group.isPublic !== false)
        {
            return false;
        }

        // Both
        return true;
    }

    private buildFromSearchResults(): void 
    {
        console.log("buildFromSearchResults", this.props.searchResults, this.props.searching);

        let results: Array<AssetCollection> = [];

        for (let i = 0; i < this.props.searchResults.length; i++)
        {
            const result = this.props.searchResults[i];

            // This way the results are in order from the search array
            for (let j = 0; j < this.props.groups.length; j++)
            {
                const group = this.props.groups[j];

                if (result.id === group.id)
                {
                    results.push(group);
                }
            }
        }

        this.setState({
            rows: this.buildRows(results),
        });
    }


    private buildRows(groups: Array<AssetCollection>): Array<JSX.Element> 
    {
        let rows: Array<JSX.Element> = [];

        for (let i = 0; i < groups.length; i++)
        {
            const group: AssetCollection = groups[i];

            if (this.groupAllowed(group) === false)
            {
                continue;
            }

            rows.push(
                <Grid xs={12} sm={4} lg={2} key={"grid_" + group.id}>
                    <GroupItem
                        key={group.id}
                        group={group}
                        canEditGroup={group.hasPermissionToEdit(UserService.user)}
                        onEditGroup={this.props.onEditGroup.bind(this, group)}
                        onDeleteGroup={this.props.onDeleteGroup.bind(this, group)}
                        onViewGroup={this.props.onViewGroup.bind(this, group)}
                        onRemoveGroup={this.props.onRemoveGroup.bind(this, group)}
                        canEditParent={this.props.canEditParent}
                    />
                </Grid>
            );
        }
        return rows;
    }

    public render(): JSX.Element
    {
        return (
            <Grid container spacing={0} sx={{ width: "100%" }}>
                {this.state.rows}
            </Grid>
        );
    }
}