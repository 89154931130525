import { DIEvent } from "../../DIEvent";

export class ReadyPlayMeUserCreatedEvent extends DIEvent
{
    public static USER_CREATED: string = "readyPlayMeUserCreatedEvent";

    constructor(type: string, public userId: number)
    {
        super(type);
    }
}