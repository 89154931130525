import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export default function DropdownMenu({ children = null })
{
    return (
        <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
                <React.Fragment>
                    <Button variant="text" {...bindTrigger(popupState)} style={{
                        minWidth: "25px",
                        padding: "4px",
                        borderRadius: 8,
                        background: "#77ccbe"
                    }}>
                        <MoreHorizIcon style={{ fill: "#000", fontSize: 25 }} />
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                        {children}
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    );
}