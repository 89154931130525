import React, { Component } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { App } from "../../App";
import { Page } from "../../Page";
import { withRouter } from "../withRouter";
import { RouterProps } from "../RouterProps";
import { UserService } from "../../services/UserService";
import { User } from "../../api/models/User";
import { Campus } from "../../api/models/Campus";
import { CampusAvatar, CampusAvatarSize } from "../pages/campus/components/CampusAvatar";
import FaceIcon from '@mui/icons-material/Face';
import { ProfileImage } from "./ProfileImage";

export interface HeaderProps extends RouterProps
{
    currentCampus?: Campus;
}

interface HeaderState 
{
    anchor: any;
    user: User;
}

class Header extends Component<HeaderProps, HeaderState>
{
    constructor(props: HeaderProps)
    {
        super(props);

        this.state = {
            anchor: null,
            user: null
        };
    }

    public componentDidMount(): void
    {
        this.setState({
            user: UserService.user
        });
    }

    private onOpenMenu(event: React.MouseEvent<HTMLElement>): void
    {
        this.setState({
            anchor: event.currentTarget
        });
    }

    private onCloseMenu(event: React.MouseEvent<HTMLElement>): void
    {
        this.setState({
            anchor: null
        });
    }

    private onProfileClicked(): void 
    {
        App.I.changePage(Page.User);
        this.closeDropdown();
    }

    private onLogoutClicked(): void 
    {
        App.I.logout();
    }

    private onNavItemClicked(page: Page): void 
    {
        App.I.changePage(page);
    }

    private closeDropdown(): void 
    {
        this.setState({
            anchor: null
        });
    }

    public render()
    {
        const navActiveStyle: React.CSSProperties = {
            background: "#82ccbe",
            color: "black",
            borderRadius: "50px",
            padding: "5px 20px"
        };

        return (
            <>
                <Container maxWidth="xl">
                    <Box style={{ padding: "20px 0 20px 0", display: "flex", flexDirection: "row" }}>

                        <Box>
                            <Typography
                                variant="h2"
                                noWrap
                                component="a"
                                href="/"
                                title="VR Campus"
                                sx={{
                                    mr: 2,
                                    color: "inherit",
                                    textDecoration: "none",
                                    fontWeight: 600,
                                    fontSize: "2.2rem"
                                }}
                            >
                                VR Campus
                            </Typography>
                        </Box>

                        <Box style={{ marginLeft: "auto" }}>
                            <Button title="Profile" onClick={this.onOpenMenu.bind(this)} style={{ width: "64px", height: "64px", padding: 0 }}>
                                <ProfileImage user={this.state.user} />
                            </Button>

                            <Menu
                                sx={{ mt: "45px" }}
                                id="menu-appbar"
                                anchorEl={this.state.anchor}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(this.state.anchor)}
                                onClose={this.onCloseMenu.bind(this)}
                            >
                                <MenuItem onClick={this.onProfileClicked.bind(this)}>
                                    Your Profile
                                </MenuItem>

                                <MenuItem onClick={this.onLogoutClicked.bind(this)}>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </Container>

                <AppBar position="static">
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <Box sx={{ flexGrow: 0, marginRight: "auto", position: "relative" }}>
                                <Button
                                    title="Dashboard"
                                    onClick={this.onNavItemClicked.bind(this, Page.Dashboard)}
                                    style={this.props.router.location.pathname === Page.Dashboard ? navActiveStyle : {}}
                                    variant="text" sx={{ mr: 2 }}
                                >
                                    Dashboard
                                </Button>


                                <Button
                                    title="Campuses"
                                    onClick={this.onNavItemClicked.bind(this, Page.Campuses)}
                                    style={this.props.router.location.pathname === Page.Campuses ? navActiveStyle : {}}
                                    variant="text" sx={{ mr: 2 }}
                                >
                                    Campuses
                                </Button>

                                <Button
                                    title="Assets"
                                    onClick={this.onNavItemClicked.bind(this, Page.User)}
                                    style={this.props.router.location.pathname === Page.User ? navActiveStyle : {}}
                                    variant="text" sx={{ mr: 2 }}>
                                    Your Profile
                                </Button>
                            </Box>
                            {/*
                            <Box style={{ position: "absolute", top: "-5px", bottom: 0, right: 0, margin: 0 }}>
                                {this.props.currentCampus ?
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Typography sx={{ pr: 2, fontWeight: 500 }}>
                                            {this.props.currentCampus.name}
                                        </Typography>
                                        <CampusAvatar file={this.props.currentCampus.thumbnail} size={CampusAvatarSize.Medium} />
                                    </Box>
                                    : null
                                }
                            </Box>
                            */}
                        </Toolbar>
                    </Container>
                </AppBar>
            </>
        );
    }
}

export default withRouter<HeaderProps>(Header);