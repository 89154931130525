import React, { Component, ReactNode } from "react";
import { Session } from "../../../../api/models/Session";
import { Box, List, Typography } from "@mui/material";
import { SessionRowItem } from "./SessionRowItem";
import dayjs from "dayjs";

export interface SessionListProps
{
    sessions: Array<Session>;
    onSessionClicked: (session: Session) => void;
    dateFormatTodayOnly?: boolean;
    activeSession?: Session;
}

interface SessionListState 
{
}

export class SessionList extends Component<SessionListProps, SessionListState>
{
    constructor(props: SessionListProps)
    {
        super(props);

        this.state = {
        };
    }

    private buildRows(): ReactNode 
    {
        let rows: Array<ReactNode> = [];
        for (let i = 0; i < this.props.sessions.length; i++)
        {
            const session: Session = this.props.sessions[i];
            const active: boolean = this.props.sessions[i].id === this.props.activeSession?.id;

            rows.push(
                <Box style={{ display: "flex" }} key={"box_" + session.id}>

                    <Box style={{ marginRight: 4, paddingRight: 4 }}>
                        <Typography variant="h6" component="h6" style={{ display: "flex", alignItems: "center", width: "110px" }}>
                            {dayjs(session.start).format("hh:mma")}
                        </Typography>
                    </Box>

                    <SessionRowItem
                        key={session.id}
                        session={session}
                        onClick={this.props.onSessionClicked.bind(this, session)}
                        showDayOnly={this.props.dateFormatTodayOnly}
                        active={active}
                    />
                </Box>
            );
        }
        if (rows.length == 0)
        {
            rows.push(<Typography key={"no-sessions"} component="p">No sessions found</Typography>);
        }
        return rows;
    }

    public render(): JSX.Element
    {
        return (
            <List sx={{ width: "100%" }} component="nav" aria-label="session list">
                {this.buildRows()}
            </List>
        );
    }
}