import React, { Component } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Header from "../../components/Header";
import { Autocomplete, Box, Button, FormControl, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { App } from "../../../App";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { UserGroup } from "../../../api/models/UserGroup";
import { UserGroupService } from "../../../services/UserGroupService";
import { Campus } from "../../../api/models/Campus";
import { RouterProps } from "../../RouterProps";
import { CampusAvatar } from "../campus/components/CampusAvatar";
import { BaseBox } from "../../components/BaseBox";
import { User } from "../../../api/models/User";
import { CampusService, CampusUser } from "../../../services/CampusService";
import { withRouter } from "../../withRouter";

export interface UserGroupEditPageProps extends RouterProps
{
}

interface UserGroupEditPageState
{
    userGroup: UserGroup;
    groupUsers: Array<User>;
    allUsers: Array<UserListItem>;
    currentCampus: Campus;
    pageError: Error;
    loading: boolean;
    usersToAdd: Array<UserListItem>;
}

interface UserListItem
{
    userId: number;
    label: string;
}

class UserGroupEditPage extends Component<UserGroupEditPageProps, UserGroupEditPageState>
{
    private campusService: CampusService;
    private userGroupService: UserGroupService;

    constructor(props: UserGroupEditPageProps)
    {
        super(props);

        this.campusService = new CampusService();
        this.userGroupService = new UserGroupService();

        this.state = {
            userGroup: null,
            groupUsers: [],
            allUsers: [],
            currentCampus: null,
            pageError: null,
            loading: true,
            usersToAdd: []
        };

        App.I.setPageTitle("User Group edit");
    }

    public componentDidMount(): void
    {
        this.fetchData();
    }

    private async fetchData(): Promise<void>
    {
        try
        {
            let campusId: number = this.props.router.params.campusId;
            let campus: Campus = await this.campusService.getCampusById(campusId);


            let groupId: number = this.props.router.params.groupId;
            let group: UserGroup = await this.userGroupService.getUserGroup(groupId);

            let groupUsers: Array<User> = await this.userGroupService.getUsersByGroup(groupId);

            let allUsers: Array<CampusUser> = await this.campusService.getCampusUsers(campusId);
            // Convert to form select
            let allUsersFiltered: Array<UserListItem> = [];
            for (let user of allUsers)
            {
                if (groupUsers.find(u => u.id == user.id))
                {
                    // Skip if already in group
                    continue;
                }
                allUsersFiltered.push({
                    label: user.email,
                    userId: user.id
                });
            }

            this.setState({
                currentCampus: campus,
                userGroup: group,
                allUsers: allUsersFiltered,
                groupUsers: groupUsers,
                loading: false
            });
        }
        catch (e)
        {
            this.setState({
                pageError: e,
                loading: false
            });
        }
    }

    private onAddUerToGroupChanged(e, values: Array<UserListItem>): void
    {
        this.setState({
            usersToAdd: values
        });
    }

    private onAddUserClicked(): void
    {
        if (this.state.usersToAdd.length == 0)
        {
            console.log("UserGroupEditPage ** No users to add");
            return;
        }
        this.addUsersToGroup(this.state.usersToAdd);
    }

    private async addUsersToGroup(users: Array<UserListItem>): Promise<void>
    {
        let ids: Array<number> = users.map(u => u.userId);
        try
        {
            if (ids.length == 0)
            {
                throw new Error("No users to add");
            }

            await this.userGroupService.addUsersToGroup({
                groupId: this.state.userGroup.id,
                userIds: ids
            });

            document.location = `/campuses/${this.state.currentCampus.id}/user-groups/${this.state.userGroup.id}`;
        }
        catch (err)
        {
            this.setState({
                pageError: err
            });
        }
    }

    private onRemoveUserClicked(user: User): void
    {
        this.removeUser(user);
    }

    private async removeUser(user: User): Promise<void>
    {
        await this.userGroupService.removeUserFromGroup(this.state.userGroup.id, user.id);
        document.location = `/campuses/${this.state.currentCampus.id}/user-groups/${this.state.userGroup.id}`;
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Header />

                <Container component="main" maxWidth="xl">

                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4, mb: 4 }}>
                        <Link color="inherit" href="/">
                            Dashboard
                        </Link>
                        <Link color="inherit" href="/campuses">
                            Campuses
                        </Link>
                        <Link color="inherit" href={`/campuses/${this.state.currentCampus?.id}`}>
                            {this.state.currentCampus?.name}
                        </Link>
                        <Link color="inherit" href={`/campuses/${this.state.currentCampus?.id}/users`}>
                            User Manager
                        </Link>
                        <Link color="inherit" href={`/campuses/${this.state.currentCampus?.id}/user-groups`}>
                            User Groups
                        </Link>
                        <Typography color="text.primary">Edit Group</Typography>
                    </Breadcrumbs>

                    <BaseBox style={{ marginBottom: "2rem" }}>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid xs={12} md={2}>
                                    <CampusAvatar file={this.state.currentCampus?.icon} />
                                </Grid>

                                <Grid xs={12} md={6}>
                                    <Box style={{ marginTop: "100px" }}>
                                        <Typography variant="h3" component="h2" fontWeight={600}>
                                            Edit group
                                        </Typography>
                                        <Typography sx={{ mt: 1 }} component="p">
                                            {this.state.userGroup?.name}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </BaseBox>

                    <Box>
                        <Grid container spacing={2}>

                            <Grid xs={12} md={3}>
                                <Box
                                    component={Paper}
                                    px={2}
                                >
                                    <Typography component="h6" variant="h6" sx={{ pl: 4, pt: 4 }}>
                                        Add users to group
                                    </Typography>

                                    <Grid xs={12} md={12} py={4}>
                                        <FormControl fullWidth>
                                            {this.state.allUsers ?
                                                <Autocomplete
                                                    multiple
                                                    disablePortal
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextField {...params} label="Select users" />}
                                                    onChange={this.onAddUerToGroupChanged.bind(this)}
                                                    options={this.state.allUsers}
                                                    value={this.state.usersToAdd}
                                                />
                                                : null
                                            }
                                        </FormControl>
                                        <Grid container sx={{ mt: 1 }} md={12} xs={12} justifyContent={"flex-end"}>
                                            <Button
                                                variant="contained"
                                                disabled={this.state.loading || this.state.usersToAdd.length == 0}
                                                onClick={this.onAddUserClicked.bind(this)}
                                            >
                                                Add
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid xs={12} md={9}>
                                <Box component={Paper}>
                                    <Typography component="h6" variant="h6" sx={{ pl: 3, pt: 4 }}>
                                        Users
                                    </Typography>
                                    {
                                        this.state.groupUsers?.length > 0 ?

                                            <TableContainer component={Paper} sx={{ px: 3, py: 4 }}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Name</TableCell>
                                                            <TableCell>Email</TableCell>
                                                            <TableCell>Manage</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            this.state.groupUsers?.map((user: User) =>
                                                                <TableRow key={user.id}>
                                                                    <TableCell>
                                                                        {user.name ?? user.email}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {user.email}
                                                                    </TableCell>
                                                                    <TableCell sx={{ py: 1, textAlign: "right", width: "1%", whiteSpace: "nowrap" }}>
                                                                        <Button
                                                                            variant="contained"
                                                                            sx={{ py: 0.25 }}
                                                                            onClick={this.onRemoveUserClicked.bind(this, user)}
                                                                        >
                                                                            Remove
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            :
                                            <Typography component="p" sx={{ p: 2 }}>
                                                No users
                                            </Typography>
                                    }
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                </Container>
            </>
        );
    }
}

export default withRouter<UserGroupEditPageProps>(UserGroupEditPage);
