import React, { Component } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { App } from "../../../App";
import { UserService } from "../../../services/UserService";
import { HeaderNoLinks } from "../../components/HeaderNoLinks";
import { Shape, ShapeType } from "../../components/Shape";
import { Page } from "../../../Page";
import { ErrorMessage } from "../../components/ErrorMessage";

export interface ResetPasswordPageProps
{
}

interface ResetPasswordPageState 
{
    code: string;
    newPassword: string;
    newPasswordConfirm: string;
    errorMessage: string;
    screen: Screen;
}

enum Screen 
{
    ResetPassword,
    ResetSuccess
}

export class ResetPasswordPage extends Component<ResetPasswordPageProps, ResetPasswordPageState>
{
    constructor(props: ResetPasswordPageProps)
    {
        super(props);

        this.state = {
            code: new URLSearchParams(window.location.search).get("code"),
            newPassword: "",
            newPasswordConfirm: "",
            errorMessage: null,
            screen: Screen.ResetPassword
        };

        App.I.setPageTitle("Reset your password");
    }

    private async onSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> 
    {
        e.preventDefault();

        try
        {
            const userService: UserService = new UserService();
            const response = await userService.resetPassword({
                code: this.state.code,
                password: this.state.newPassword,
                passwordConfirmation: this.state.newPasswordConfirm,
            });
            if (response.success)
            {
                this.setState({
                    screen: Screen.ResetSuccess
                });
            }
        }
        catch (error)
        {
            this.setState({
                errorMessage: error.message
            });
        }
    }

    private onNewPasswordChanged(e: React.FormEvent<HTMLInputElement>): void
    {
        this.setState({
            newPassword: e.currentTarget.value
        });
    }

    private onNewPasswordConfirmChanged(e: React.FormEvent<HTMLInputElement>): void
    {
        this.setState({
            newPasswordConfirm: e.currentTarget.value
        });
    }

    public render(): JSX.Element
    {
        return (
            <>
                <HeaderNoLinks />

                <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", position: "relative", zIndex: 1 }}>

                    <Grid xs={12} sm={12} md={12}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "#343759",
                                paddingTop: 4,
                                paddingBottom: 2,
                                paddingLeft: 6,
                                paddingRight: 6,
                                borderRadius: 8,
                                width: "650px",
                                maxWidth: "100%",
                            }}
                        >
                            {this.state.screen === Screen.ResetPassword ?
                                <>
                                    <Typography component="h1" variant="h1" sx={{ pt: 2 }}>
                                        Reset your password
                                    </Typography>

                                    <Typography component="h2" variant="h5" sx={{ mt: 2, mb: 2 }}>
                                        Enter your new password below
                                    </Typography>

                                    <Box component="form" noValidate onSubmit={this.onSubmit.bind(this)} sx={{ mt: 1 }}>

                                        <Grid container spacing={4}>
                                            <Grid xs={12}>
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    type="password"
                                                    id="password"
                                                    label="Password"
                                                    name="password"
                                                    autoFocus
                                                    autoComplete="new-password"
                                                    onChange={this.onNewPasswordChanged.bind(this)}
                                                    value={this.state.newPassword}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={4}>
                                            <Grid xs={12}>
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    type="password"
                                                    id="passwordConfirm"
                                                    label="Confirm password"
                                                    name="passwordConfirm"
                                                    autoComplete="new-password"
                                                    onChange={this.onNewPasswordConfirmChanged.bind(this)}
                                                    value={this.state.newPasswordConfirm}
                                                />
                                            </Grid>
                                        </Grid>

                                        {this.state.errorMessage ?
                                            <ErrorMessage message={this.state.errorMessage} />
                                            : null
                                        }

                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <div>
                                                <Link href={Page.Login} variant="body2">
                                                    {"Cancel"}
                                                </Link>
                                            </div>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2, marginLeft: "auto", display: "flex" }}
                                            >
                                                Save password
                                            </Button>
                                        </Box>
                                    </Box>
                                </>
                                :
                                <>
                                    <Typography component="h1" variant="h1" sx={{ pt: 2 }}>
                                        Success
                                    </Typography>

                                    <Typography component="h2" variant="h5" sx={{ mt: 2, mb: 2 }}>
                                        Your password has been changed, you can now login
                                    </Typography>

                                    <Link href={Page.Login} variant="body2">
                                        {"Log in"}
                                    </Link>
                                </>
                            }
                        </Box>
                    </Grid>
                </Box >
                <Shape shape={ShapeType.Shape1} width={150} style={{ left: "-3%", top: "35%" }} />
                <Shape shape={ShapeType.Shape2} width={120} style={{ right: "25%", bottom: "-1%" }} />
                <Shape shape={ShapeType.Shape3} width={150} style={{ right: "10%", top: "1%" }} />
            </>
        );
    }
}