import { Box, Typography } from "@mui/material";
import React, { Component } from "react";
import { User } from "../../api/models/User";
import objectImage1 from "../assets/images/1.png";
import objectImage2 from "../assets/images/2.png";
import objectImage3 from "../assets/images/3.png";

export interface ShapeProps
{
    shape: ShapeType;
    width?: number;
    height?: number;
    style: React.CSSProperties;
}

export enum ShapeType
{
    Shape1,
    Shape2,
    Shape3
}

interface ShapeState 
{
}

export class Shape extends Component<ShapeProps, ShapeState>
{
    constructor(props: ShapeProps)
    {
        super(props);

        this.state = {
        };
    }

    public render()
    {
        let image: string = null;

        if (this.props.shape === ShapeType.Shape1) 
        {
            image = objectImage1;
        }

        if (this.props.shape === ShapeType.Shape2)
        {
            image = objectImage2;
        }

        if (this.props.shape === ShapeType.Shape3)
        {
            image = objectImage3;
        }

        return (
            <div style={{
                backgroundImage: `url( ${image} )`,
                width: this.props.width,
                height: this.props.width,
                backgroundSize: "cover",
                position: "absolute",
                zIndex: 0,
                ...this.props.style
            }}>
            </div>
        );
    }
}