import { BaseAPI } from "./BaseAPI";
import { StrapiAPIPostRequest } from "./response/StrapiAPIPostRequest";
import { APIResponse } from "./rest/APIResponse";
import { RestRequest } from "./rest/RestRequest";

export interface UpdateUserGroupRequest
{
    id?: number;
    name?: string;
    campusId: number;
}

export interface CreateUserGroupRequest
{
    name: string;
    campus: number;
}

export interface AddUsersToGroupRequest
{
    groupId: number;
    userIds: Array<number>;
}

export class UserGroupAPI extends BaseAPI
{
    constructor(
        baseUrl: string,
        headers: { [name: string]: string } = {}
    )
    {
        super(baseUrl, headers);
    }

    public async getUserGroupById(id: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/user-groups/${id}`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async getUsersByGroup(id: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/user-groups/${id}/users`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async getUserGroupsByCampus(campusId: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/campuses/${campusId}/user-groups`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async updateUserGroup(requestData: UpdateUserGroupRequest): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/user-groups/${requestData.id}`), this.headers);
        let response: APIResponse = await request.put(requestData);
        return response;
    }

    public async createUserGroup(requestData: CreateUserGroupRequest): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/user-groups`), this.headers);
        let jsonRequest: StrapiAPIPostRequest = { data: requestData };
        let response: APIResponse = await request.post(jsonRequest);
        return response;
    }

    public async deleteUserGroupById(id: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/user-groups/${id}`), this.headers);
        let response: APIResponse = await request.delete();
        return response;
    }

    public async addUsersToGroup(groupId: number, requestData: AddUsersToGroupRequest): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/user-groups/${groupId}/add-users`), this.headers);
        let response: APIResponse = await request.post({ data: requestData });
        return response;
    }

    public async removeUserFromGroup(groupId: number, userId: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/user-groups/${groupId}/remove-user/${userId}`), this.headers);
        let response: APIResponse = await request.delete();
        return response;
    }
}
