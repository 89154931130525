import { Component, PropsWithChildren, ReactNode } from "react";
import { Campus } from "../../../../api/models/Campus";
import { CampusService, CampusUser } from "../../../../services/CampusService";
import { UserService } from "../../../../services/UserService";
import { Navigate } from "react-router-dom";
import { Page } from "../../../../Page";

export interface CampusAdminRouteProps extends PropsWithChildren
{
    campus: Campus;
    redirect?: string;
}

interface CampusAdminRouteState
{
    campusUser: CampusUser;
    loading: boolean;
}

export class CampusAdminRoute extends Component<CampusAdminRouteProps, CampusAdminRouteState>
{
    private campusService: CampusService;

    constructor(props: CampusAdminRouteProps)
    {
        super(props);

        this.campusService = new CampusService();

        this.state = {
            campusUser: null,
            loading: true
        };
    }

    public componentDidMount(): void
    {
        this.getCampusUser();
    }

    public componentDidUpdate(prevProps: Readonly<CampusAdminRouteProps>, prevState: Readonly<CampusAdminRouteState>, snapshot?: any): void
    {

    }

    private async getCampusUser(): Promise<void>
    {
        try
        {
            let user: CampusUser = await this.campusService.getCampusUser(this.props.campus.id, UserService.user.id);
            this.setState({
                campusUser: user,
                loading: false
            });
        }
        catch (err)
        {
            console.error(err);
            this.setState({
                loading: false
            });
        }
    }

    private failedAuthentication(): ReactNode
    {
        return <Navigate to={this.props.redirect ?? Page.Campuses} />;
    }

    public render(): ReactNode
    {
        if (this.state.loading) return <></>;
        if (this.state.campusUser == null) return this.failedAuthentication();
        if (!this.state.campusUser.isAdmin) return this.failedAuthentication();
        return <>{this.props.children}</>;
    }
}
