import { Campus } from "./Campus";
import { FileFormat } from "./FileObject";
import { User } from "./User";

export class Asset 
{
    constructor(
        private _id: number,
        private _name: string,
        private _description: string,
        private _keywords: string,
        private _file: FileFormat,
        private _file_windows: FileFormat,
        private _thumbnail: FileFormat,
        private _campuses: Array<Campus>,
        private _owner: User,
        private _isPublic: boolean,
        private _isPurchased: boolean,
        private _physical_width_mm: number,
        private _physical_height_mm: number,
    )
    {
    }

    public static fromJson(json: any): Asset
    {
        return new Asset(
            json.id,
            json.name,
            json.description,
            json.keywords,
            json.file,
            json.file_windows,
            json.thumbnail,
            json.campuses,
            json.owner,
            json.is_public,
            json.is_purchased,
            json.physical_width_mm,
            json.physical_height_mm,
        );
    }

    public get id(): number { return this._id; }
    public get name(): string { return this._name; }
    public get description(): string { return this._description; }
    public get keywords(): string { return this._keywords; }
    public get file(): FileFormat { return this._file; }
    public get file_windows(): FileFormat { return this._file_windows; }
    public get thumbnail(): FileFormat { return this._thumbnail; }
    public get campuses(): Array<Campus> { return this._campuses; }
    public get owner(): User { return this._owner; }
    public get isPublic(): boolean { return this._isPublic; }
    public get isPurchased(): boolean { return this._isPurchased; }
    public get physical_width_mm(): number { return this._physical_width_mm; }
    public get physical_height_mm(): number { return this._physical_height_mm; }

    public hasPermissionToEdit(user: User): boolean 
    {
        if (this.owner)
        {
            // @ts-ignore - wacky data object from strapi 
            if (this.owner.data)
            {
                // @ts-ignore
                return user.id === this.owner.data.id;
            }

            // Normal flat response
            return user.id === this.owner.id;
        }

        return true;
    }
}
