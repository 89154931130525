import React, { Component } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { App } from "../../../App";
import Header from "../../components/Header";
import { RouterProps } from "../../RouterProps";
import { Campus } from "../../../api/models/Campus";
import { CampusService, CampusUser } from "../../../services/CampusService";
import { withRouter } from "../../withRouter";
import { PageErrorMessage } from "../../components/PageErrorMessage";
import { BaseBox } from "../../components/BaseBox";
import { User } from "../../../api/models/User";
import { Breadcrumbs, Button, Link } from "@mui/material";
import { UserService } from "../../../services/UserService";
import { CampusAvatar } from "./components/CampusAvatar";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import BasicModal, { ModalSize } from "../../components/Modal";
import { EditCampusUserForm } from "./components/EditCampusUserForm";
import { CampusAdminRoute } from "./components/CampusAdminRoute";


export interface CampusUserManagerPageProps extends RouterProps
{
}

interface CampusUserManagerPageState 
{
    campus: Campus;
    campusUsers: Array<CampusUser>;
    showEditUserModal: boolean;
    currentUser: CampusUser;
    loading: boolean;
    pageError: Error;
}

class CampusUserManagerPage extends Component<CampusUserManagerPageProps, CampusUserManagerPageState>
{
    private campusService: CampusService;
    private userService: UserService;

    constructor(props: CampusUserManagerPageProps)
    {
        super(props);

        this.campusService = new CampusService();
        this.userService = new UserService();

        this.state = {
            campusUsers: [],
            campus: null,
            currentUser: null,
            showEditUserModal: false,
            pageError: null,
            loading: true
        };

        App.I.setPageTitle("Campus User Manager");
    }

    public componentDidMount(): void
    {
        this.getCampus();
    }

    private async getCampus(): Promise<void> 
    {
        try
        {
            const campusId: number = this.props.router.params.campusId;
            const campus: Campus = await this.campusService.getCampusById(campusId);
            const campusUsers: Array<CampusUser> = await this.campusService.getCampusUsers(campusId);

            App.I.setPageTitle(campus.name);

            this.setState({
                campus,
                campusUsers: campusUsers,
                loading: false
            });
        }
        catch (error)
        {
            this.setState({
                pageError: error,
                loading: false
            });
        }
    }

    private onUsersUpdated(): void 
    {
        App.I.sendNotification("Users updated");
        this.getCampus();
    }

    private onCloseEditUserModal(): void 
    {
        this.setState({
            currentUser: null,
            showEditUserModal: false
        });
    }

    private onUserUpdated(): void 
    {
        this.setState({
            showEditUserModal: false
        });

        this.onUsersUpdated();
    }

    private onEditUserClicked(user: CampusUser): void 
    {
        this.setState({
            showEditUserModal: true,
            currentUser: user
        });
    }

    private onRemoveUserClicked(user: User): void 
    {
        this.removeUser(user);
    }

    private async removeUser(user: User): Promise<void>
    {
        await this.campusService.removeCampusUser({ campusId: this.state.campus.id, userId: user.id });
        App.I.sendNotification(user.email + " removed from campus");
        this.getCampus();
    }

    private onUserGroupsClicked(): void 
    {
        document.location.href = `/campuses/${this.state.campus.id}/user-groups`;
    }

    private onInvitedUsersClicked(): void
    {
        document.location.href = `/campuses/${this.state.campus.id}/invited-users`;
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Header />

                <Container component="main" maxWidth="xl">
                    {this.state.loading === false ?
                        <CampusAdminRoute campus={this.state.campus}>
                            {this.state.pageError != null ?
                                <PageErrorMessage error={this.state.pageError} />
                                :
                                <Box sx={{ mt: 4 }}>

                                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4, mb: 4 }}>
                                        <Link color="inherit" href="/">
                                            Dashboard
                                        </Link>
                                        <Link color="inherit" href="/campuses">
                                            Campuses
                                        </Link>
                                        <Link color="inherit" href={`/campuses/${this.state.campus?.id}`}>
                                            {this.state.campus.name}
                                        </Link>
                                        <Typography color="text.primary">User Manager</Typography>
                                    </Breadcrumbs>

                                    <BaseBox style={{ marginBottom: "1rem" }}>
                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid xs={12} md={2}>
                                                    <CampusAvatar file={this.state.campus.icon} />
                                                </Grid>

                                                <Grid xs={12} md={6}>
                                                    <Box style={{ marginTop: "100px" }}>
                                                        <Typography variant="h3" component="h2" fontWeight={600}>
                                                            Campus User Manager
                                                        </Typography>
                                                        <Typography sx={{ mt: 1 }} component="p">
                                                            {this.state.campus.name}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </BaseBox>

                                    <Grid xs={12} lg={12} sx={{ mb: 2 }}>
                                        <Button variant="text" onClick={null}
                                            sx={{ pt: 1, borderBottom: "4px solid #77ccbe", borderRadius: 0 }}>
                                            Users
                                        </Button>
                                        <Button variant="text" onClick={this.onUserGroupsClicked.bind(this)}
                                            sx={{ ml: 1 }}>
                                            User groups
                                        </Button>
                                        <Button variant="text" onClick={this.onInvitedUsersClicked.bind(this)}
                                            sx={{ ml: 1 }}>
                                            Invited users
                                        </Button>
                                    </Grid>

                                    {
                                        this.state.campusUsers.length > 0 ?
                                            <TableContainer component={Paper} sx={{ px: 3, py: 4 }}>
                                                <Table sx={{ minWidth: 650 }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Name</TableCell>
                                                            <TableCell>Email</TableCell>
                                                            <TableCell>Permission</TableCell>
                                                            <TableCell>User Groups</TableCell>
                                                            <TableCell>XP</TableCell>
                                                            <TableCell>Manage</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            this.state.campusUsers.map((user: CampusUser) =>
                                                                <TableRow key={user.id}>
                                                                    <TableCell>{user.name ?? user.email}</TableCell>
                                                                    <TableCell>{user.email}</TableCell>
                                                                    <TableCell>{user.isAdmin ? <span style={{ color: "#F90" }}>Admin</span> : "Standard"}</TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            user.user_groups.map(ug => ug.name).join(", ")
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>{user.xp}</TableCell>
                                                                    <TableCell>
                                                                        <Button
                                                                            disabled={user.id == UserService.user.id}
                                                                            variant="outlined"
                                                                            onClick={this.onEditUserClicked.bind(this, user)}>
                                                                            Manage
                                                                        </Button>

                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            :
                                            <Grid component={Paper}>
                                                <Typography component="p" sx={{ p: 2 }}>
                                                    No users
                                                </Typography>
                                            </Grid>
                                    }
                                </Box>
                            }
                        </CampusAdminRoute>
                        : null
                    }

                    {this.state.showEditUserModal ?
                        <BasicModal onClose={this.onCloseEditUserModal.bind(this)} size={ModalSize.Large}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column"
                                }}
                            >
                                <Typography component="h5" variant="h5">
                                    Edit user
                                </Typography>

                                <EditCampusUserForm
                                    user={this.state.currentUser}
                                    onUserUpdated={this.onUserUpdated.bind(this)}
                                    campus={this.state.campus}
                                />
                            </Box>
                        </BasicModal>
                        : null
                    }
                </Container>
            </>
        );
    }
}

export default withRouter<CampusUserManagerPageProps>(CampusUserManagerPage);