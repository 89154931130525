import { AppAPI } from "../api/AppAPI";
import { APIResponse } from "../api/rest/APIResponse";
import { APIErrorResponseHandler } from "../api/APIErrorResponseHandler";
import { StrapiAPIResponse } from "../api/response/StrapiAPIResponse";
import { Campus } from "../api/models/Campus";
import { AddCampusUsersRequest, InviteEmailsToCampusRequest, RemoveCampusUserRequest } from "../api/CampusAPI";
import { Asset } from "../api/models/Asset";
import { UserGroup } from "../api/models/UserGroup";
import { AssetCollection } from "../api/models/AssetCollection";
import { CampusInvite } from "../api/models/CampusInvite";

export interface CampusInviteResponse
{
    userName: string;
    campusName: string;
    email: string;
}

export interface SetCampusUserStatusRequest
{
    userId: number;
    campusId: number;
    isAdmin: boolean;
}

export interface CampusUserStatusResponse
{
    isAdmin: boolean;
}

export interface CampusUser
{
    id: number;
    username: string;
    email: string;
    name: string;
    xp: number;
    user_groups: Array<UserGroup>;
    isAdmin: boolean;
}

export class CampusService
{
    public async getCampusById(campusId: number): Promise<Campus> 
    {
        const response: APIResponse = await AppAPI.I.campuses.getCampusById(campusId);
        APIErrorResponseHandler.validate(response);

        let json = response.json;
        let campus: Campus = Campus.fromJson(json);

        return campus;
    }

    public async getCampusUser(campusId: number, userId: number): Promise<CampusUser>
    {
        const response: APIResponse = await AppAPI.I.campuses.getCampusUser(campusId, userId);
        APIErrorResponseHandler.validate(response);
        return response.json;
    }

    public async getCampusUsers(campusId: number): Promise<Array<CampusUser>>
    {
        const response: APIResponse = await AppAPI.I.campuses.getCampusUsers(campusId);
        APIErrorResponseHandler.validate(response);

        const users: Array<CampusUser> = response.json;
        return users;
    }

    public async getCampusUserStatus(campusId: number, userId: number): Promise<CampusUserStatusResponse>
    {
        const response: APIResponse = await AppAPI.I.campuses.getCampusUserStatus(campusId, userId);
        APIErrorResponseHandler.validate(response);
        return response.json;
    }

    public async setCampusUserStatus(data: SetCampusUserStatusRequest): Promise<CampusUserStatusResponse>
    {
        const response: APIResponse = await AppAPI.I.campuses.setCampusUserStatus(data);
        APIErrorResponseHandler.validate(response);
        return response.json;
    }

    public async createCampus(formData: FormData): Promise<Campus>
    {
        const response: APIResponse = await AppAPI.I.campuses.createCampus(formData);
        APIErrorResponseHandler.validate(response);

        const jsonResponse: StrapiAPIResponse<any> = response.json.data;
        const campus: Campus = new Campus(
            jsonResponse.id,
            jsonResponse.attributes.name,
            jsonResponse.attributes.description,
            jsonResponse.attributes.file,
            jsonResponse.attributes.icon,
            jsonResponse.attributes.primary_color,
            jsonResponse.attributes.secondary_color,
            jsonResponse.attributes.opening_environment,
            jsonResponse.attributes.environments
        );

        return campus;
    }

    public async updateCampus(campusId: number, formData: FormData): Promise<Campus>
    {
        const response: APIResponse = await AppAPI.I.campuses.updateCampus(campusId, formData);
        APIErrorResponseHandler.validate(response);

        const jsonResponse: StrapiAPIResponse<any> = response.json.data;
        const campus: Campus = new Campus(
            jsonResponse.id,
            jsonResponse.attributes.name,
            jsonResponse.attributes.description,
            jsonResponse.attributes.file,
            jsonResponse.attributes.icon,
            jsonResponse.attributes.primary_color,
            jsonResponse.attributes.secondary_color,
            jsonResponse.attributes.opening_environment,
            jsonResponse.attributes.environments
        );

        return campus;
    }

    public async inviteUsersToCampus(request: InviteEmailsToCampusRequest): Promise<void>
    {
        const response: APIResponse = await AppAPI.I.campuses.inviteUsers(request);
        APIErrorResponseHandler.validate(response);
    }

    public async addCampusUsers(request: AddCampusUsersRequest): Promise<void>
    {
        const response: APIResponse = await AppAPI.I.campuses.addCampusUsers(request);
        APIErrorResponseHandler.validate(response);
    }

    public async removeCampusUser(request: RemoveCampusUserRequest): Promise<void>
    {
        const response: APIResponse = await AppAPI.I.campuses.removeCampusUser(request);
        APIErrorResponseHandler.validate(response);
    }

    public async getCampuses(): Promise<Array<Campus>>
    {
        const response: APIResponse = await AppAPI.I.campuses.getCampuses();
        APIErrorResponseHandler.validate(response);

        const jsonArr: Array<StrapiAPIResponse<Campus>> = response.json.data;

        let campuses: Array<Campus> = [];
        for (let i = 0; i < jsonArr.length; i++)
        {
            let json: any = jsonArr[i];
            campuses.push(Campus.fromJson(json));
        }

        return campuses;
    }

    public async getCampusInvite(inviteUUID: string): Promise<CampusInviteResponse> 
    {
        const response: APIResponse = await AppAPI.I.campuses.getCampusInvite(inviteUUID);
        APIErrorResponseHandler.validate(response);
        return response.json;
    }

    public async getCampusInvites(campusId: number): Promise<Array<CampusInvite>>
    {
        const response: APIResponse = await AppAPI.I.campuses.getCampusInvites(campusId);
        APIErrorResponseHandler.validate(response);
        return response.json;
    }

    public async acceptCampusInvite(inviteUUID: string, userEmail: string): Promise<void> 
    {
        const response: APIResponse = await AppAPI.I.campuses.acceptCampusInvite(inviteUUID, userEmail);
        APIErrorResponseHandler.validate(response);
    }

    public async resendInvite(inviteUUID: string): Promise<void>
    {
        const response: APIResponse = await AppAPI.I.campuses.resendInvite(inviteUUID);
        APIErrorResponseHandler.validate(response);
    }

    public async resendInvites(uuids: Array<string>): Promise<void>
    {
        const response: APIResponse = await AppAPI.I.campuses.resendInvites(uuids);
        APIErrorResponseHandler.validate(response);
    }

    public async removeCampusAsset(asset: Asset, campus: Campus): Promise<void> 
    {
        const response: APIResponse = await AppAPI.I.campuses.removeCampusAsset(asset.id, campus.id);
        APIErrorResponseHandler.validate(response);
        return response.json;
    }

    public async addCampusAsset(assetToAdd: Asset, campus: Campus): Promise<Asset> 
    {
        const response: APIResponse = await AppAPI.I.campuses.addCampusAsset(assetToAdd.id, campus.id);
        APIErrorResponseHandler.validate(response);

        let responseJson = response.json;

        let asset: Asset = Asset.fromJson(responseJson);

        return asset;
    }

    public async getCampusAssets(campusId: number): Promise<Array<Asset>>
    {
        const response: APIResponse = await AppAPI.I.campuses.getCampusAssets(campusId);
        const responseAssets: Array<Asset> = response.json;

        let assets: Array<Asset> = [];
        for (let i = 0; i < responseAssets.length; i++)
        {
            let asset: Asset = Asset.fromJson(responseAssets[i]);
            assets.push(asset);
        }

        return assets;
    }

    public async getCampusAssetCollections(campusId: number): Promise<Array<AssetCollection>>
    {
        const response: APIResponse = await AppAPI.I.campuses.getCampusAssetCollections(campusId);
        const responseGroups = response.json;

        let groups: Array<AssetCollection> = [];
        for (let i = 0; i < responseGroups.length; i++)
        {
            let jsonResponse: any = responseGroups[i];
            let group: AssetCollection = new AssetCollection(
                jsonResponse.id,
                jsonResponse.name,
                jsonResponse.description,
                jsonResponse.assets,
                jsonResponse.campuses,
                jsonResponse.is_public,
                jsonResponse.is_purchased,
                jsonResponse.parent_asset_groups,
                jsonResponse.file,
                jsonResponse.owner
            );
            groups.push(group);
        }

        return groups;
    }
}

