import React, { Component } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { App } from "../../../App";
import { HeaderNoLinks } from "../../components/HeaderNoLinks";
import { Shape, ShapeType } from "../../components/Shape";
import { Page } from "../../../Page";

export interface PostRegisterPageProps
{
}

interface PostRegisterPageState 
{
    selectedValue: string;
}

export class PostRegisterPage extends Component<PostRegisterPageProps, PostRegisterPageState>
{
    constructor(props: PostRegisterPageProps)
    {
        super(props);

        this.state = {
            selectedValue: "profile"
        };

        App.I.setPageTitle("Next steps");
    }

    private onRadioOptionChanged(e): void
    {
        this.setState({
            selectedValue: e.currentTarget.value
        });
    }

    private onContinueClicked(): void
    {
        let nextPage: Page = Page.Dashboard;
        switch (this.state.selectedValue)
        {
            case "profile":
                nextPage = Page.User;
                break;
            case "campus":
                nextPage = Page.CampusCreateRoute;
                break;
        }
        App.I.changePage(nextPage);
    }

    public render(): JSX.Element
    {
        return (
            <>
                <HeaderNoLinks />

                <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", position: "relative", zIndex: 1 }}>

                    <Grid xs={12} sm={12} md={12}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "#343759",
                                paddingTop: 4,
                                paddingBottom: 2,
                                paddingLeft: 6,
                                paddingRight: 6,
                                borderRadius: 8,
                                width: "650px",
                                maxWidth: "100%",
                            }}
                        >
                            <Grid container flexDirection={"column"} marginBottom={3}>
                                <Typography component="h1" variant="h1" sx={{ pt: 2 }}>
                                    Next steps
                                </Typography>

                                <Typography component="h2" variant="h5" sx={{ mt: 2, mb: 2 }}>
                                    What would you like to do next?
                                </Typography>
                            </Grid>

                            <Box sx={{ mt: 1 }}>
                                <Grid container spacing={2} flexDirection={"column"} marginBottom={5}>
                                    <RadioGroup
                                        name="radio-buttons-group"
                                        sx={{ width: "100%" }}
                                        defaultValue={"profile"}
                                        onChange={this.onRadioOptionChanged.bind(this)}
                                    >
                                        <FormControlLabel sx={{ backgroundColor: "#1E2146", padding: "8px 32px 8px 8px", borderRadius: 4, marginBottom: 1 }} value="profile" control={<Radio />} label="Complete your profile / create your avatar" />
                                        <FormControlLabel sx={{ backgroundColor: "#1E2146", padding: "8px 32px 8px 8px", borderRadius: 4 }} value="campus" control={<Radio />} label="Create a campus" />
                                    </RadioGroup>
                                </Grid>

                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    {/*
                                    <div>
                                        <Link href={Page.Login} variant="body2">
                                            {"Already have an account? Login"}
                                        </Link>
                                    </div>
                                    */}
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2, marginLeft: "auto", display: "flex" }}
                                        onClick={this.onContinueClicked.bind(this)}
                                    >
                                        Get started
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Box>
                <Shape shape={ShapeType.Shape1} width={150} style={{ left: "-3%", top: "35%" }} />
                <Shape shape={ShapeType.Shape2} width={120} style={{ right: "25%", bottom: "-1%" }} />
                <Shape shape={ShapeType.Shape3} width={150} style={{ right: "10%", top: "1%" }} />
            </>
        );
    }
}