import React, { Component } from "react";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { Campus } from "../../../../api/models/Campus";
import { CampusService } from "../../../../services/CampusService";
import { InviteCampusUser } from "../../../../api/CampusAPI";
import { Sheet, WorkBook, read, utils } from "xlsx";

export interface ImportCampusUsersFormProps
{
    campus: Campus;
    onSuccess: () => void;
}

interface ImportCampusUsersFormState 
{
    errorMessage: string;
    emailsInvited: boolean;
    confirmedList: Array<JSX.Element>;
    showConfirmList: boolean;
}

export class ImportCampusUsersForm extends Component<ImportCampusUsersFormProps, ImportCampusUsersFormState>
{
    private users: Array<InviteCampusUser> = [];

    constructor(props: ImportCampusUsersFormProps)
    {
        super(props);

        this.state = {
            errorMessage: null,
            confirmedList: null,
            emailsInvited: false,
            showConfirmList: false
        };
    }

    private buildConfirmList(users: Array<InviteCampusUser>): Array<JSX.Element>
    {
        let items = [];
        for (let i = 0; i < users.length; i++)
        {
            let user: InviteCampusUser = users[i];

            // Quick valid email check
            if (user.email == null || !user.email.indexOf("@"))
            {
                throw new Error(`Invalid email detected: ${user.email} on row ${i}`);
            }

            items.push(
                <TableRow
                    key={user.email}
                >
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.usergroups}</TableCell>
                </TableRow>
            );
        }
        return items;
    }

    private async submit(): Promise<void>
    {
        const campusService: CampusService = new CampusService();

        try
        {
            await campusService.inviteUsersToCampus({
                campusId: this.props.campus.id,
                users: this.users
            });

            this.users = [];

            this.setState({
                confirmedList: null,
                showConfirmList: false,
                emailsInvited: true
            });

            this.props.onSuccess();
        }
        catch (error)
        {
            this.setState({
                errorMessage: error.message
            });
        }
    }

    private onFileSelected(e): void
    {
        if (e.target.files.length > 0)
        {
            let file: File = e.target.files[0];
            this.parseFile(file);
        }
    }

    private async parseFile(file: File): Promise<void>
    {
        try
        {
            let ext: string = file.name.substring(file.name.lastIndexOf('.') + 1);

            // Accept these files for now
            if (!(["xls", "xlsx", "csv"].includes(ext)))
            {
                throw new Error(`Invalid file format detected, please provide an Excel compatible document`);
            }

            let data: ArrayBuffer = await file.arrayBuffer();
            let workbook: WorkBook = read(data);
            let sheet: Sheet = workbook.Sheets[workbook.SheetNames[0]];

            this.users = utils.sheet_to_json<InviteCampusUser>(sheet, { header: ["email", "name", "usergroups"] });

            this.setState({
                confirmedList: this.buildConfirmList(this.users),
                errorMessage: null,
                showConfirmList: true
            });
        }
        catch (error)
        {
            this.setState({
                confirmedList: null,
                errorMessage: error.message
            });
        }
    }

    public render(): JSX.Element
    {
        return (
            <Grid container component="main">
                <Grid xs={12}>
                    <Box
                        sx={{
                            mx: 4,
                            my: 1,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            variant="contained"
                            component="label"
                        >
                            Upload File
                            <input
                                type="file"
                                onChange={this.onFileSelected.bind(this)}
                                hidden
                            />
                        </Button>
                        {
                            this.state.errorMessage ?
                                <Box mt={2}>
                                    <ErrorMessage message={this.state.errorMessage} />
                                </Box>
                                : null
                        }
                        {this.state.showConfirmList ?
                            <>
                                <TableContainer component={Paper}>
                                    <Table sx={{ mt: 2 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>User group(s)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.confirmedList}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Typography component={"p"} color="primary" mt={2}>
                                    <strong>
                                        Please check the table above is correct before sending the invites. You can re-upload the file if you need to make changes.
                                    </strong>
                                    <br />
                                </Typography>
                                <Box width={"100%"} mt={2} display={"flex"} justifyContent={"space-between"}>
                                    <div></div>
                                    <Button
                                        onClick={this.submit.bind(this)}
                                        variant="contained"
                                        sx={{ ml: 3 }}
                                    >
                                        Confirm and send
                                    </Button>
                                </Box>
                            </>
                            : null
                        }
                    </Box>
                </Grid>
            </Grid>
        );
    }
}