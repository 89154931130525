import { DIEvent } from "../../DIEvent";

export class ReadyPlayMeModelCreatedEvent extends DIEvent
{
    public static MODEL_CREATED: string = "readyPlayMeModelCreatedEvent";

    constructor(type: string, public glbUrl: string, public userId: number)
    {
        super(type);
    }
}