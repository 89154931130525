export class Config
{
    private _apiUrl: string = null;
    private _baseUrl: string = null;

    public async load(): Promise<void>
    {
        let { apiUrl } = await fetch("/settings.json").then((response) => response.json());
        this._baseUrl = apiUrl;
        this._apiUrl = this._baseUrl + "/api";
    }

    public get apiUrl(): string { return this._apiUrl; }
    public get baseUrl(): string { return this._baseUrl; }
}