import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import { Box, Button, ListItem, ListItemIcon, ListItemText, MenuItem, MenuList, Tooltip } from "@mui/material";
import DropdownMenu from "../../../components/DropdownMenu";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PublicIcon from '@mui/icons-material/Public';
import { AssetCollection } from "../../../../api/models/AssetCollection";
import { GroupThumbnail } from "./GroupThumbnail";
import FolderIcon from '@mui/icons-material/Folder';
import LaunchIcon from '@mui/icons-material/Launch';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

export interface GroupItemProps
{
    group: AssetCollection;
    canEditGroup: boolean;
    onViewGroup?: () => void;
    onEditGroup?: () => void;
    onDeleteGroup?: () => void;
    onRemoveGroup?: () => void;
    canEditParent: boolean;
}

interface GroupItemState 
{
    dropdownAvailable: boolean;
}

export class GroupItem extends Component<GroupItemProps, GroupItemState>
{
    constructor(props: GroupItemProps)
    {
        super(props);

        this.state = {
            dropdownAvailable: true
        };
    }

    private onDropdownItemClicked(): void 
    {
        this.setState({
            dropdownAvailable: false,
        },
            () => this.setState({
                dropdownAvailable: true
            })
        );
    }

    private onViewClicked(): void 
    {
        this.onDropdownItemClicked();
        this.props.onViewGroup();
    }

    private onEditClicked(): void 
    {
        this.onDropdownItemClicked();
        this.props.onEditGroup();
    }

    private onDeleteClicked(): void 
    {
        this.onDropdownItemClicked();
        this.props.onDeleteGroup();
    }

    private onRemoveClicked(): void 
    {
        this.onDropdownItemClicked();
        this.props.onRemoveGroup();
    }

    public render(): JSX.Element
    {
        return (
            <ListItem style={{ display: "flex", flexGrow: 1, height: "100%" }} sx={{ p: 1 }}>
                <Box
                    style={{
                        flexDirection: "column",
                        background: "#666286",
                        borderRadius: "10px",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "space-around",
                        cursor: "pointer",
                        transition: "ease all 250ms",
                        opacity: 0.85
                    }}
                    sx={{
                        padding: 0,
                        "&:hover": {
                            boxShadow: "0px 3px 5px 0px #333550",
                            opacity: "1!important"
                        }
                    }}
                    title={this.props.group.name}
                >
                    <Box
                        onClick={this.props.onViewGroup.bind(this)}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            width: "100%",
                            height: "100%"
                        }}>
                        <GroupThumbnail assetGroup={this.props.group} />
                    </Box>

                    <Box sx={{ position: "absolute", top: 20, right: 20 }}>
                        {this.state.dropdownAvailable ?

                            <DropdownMenu>
                                {this.props.onViewGroup ?
                                    <MenuItem onClick={this.onViewClicked.bind(this)}>
                                        <ListItemIcon>
                                            <LaunchIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body2">
                                                View
                                            </Typography>
                                        </ListItemText>
                                    </MenuItem>
                                    : null
                                }

                                {this.props.canEditGroup && this.props.onEditGroup ?
                                    <MenuItem onClick={this.onEditClicked.bind(this)}>
                                        <ListItemIcon>
                                            <EditIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body2">
                                                Edit
                                            </Typography>
                                        </ListItemText>
                                    </MenuItem>
                                    : null
                                }

                                {this.props.group.parentAssetGroups.length !== 0 && this.props.canEditParent && this.props.onRemoveGroup ?
                                    <MenuItem onClick={this.onRemoveClicked.bind(this)}>
                                        <ListItemIcon>
                                                <CloseIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body2">
                                                Remove
                                            </Typography>
                                        </ListItemText>
                                    </MenuItem>
                                    : null
                                }

                                {this.props.group.parentAssetGroups.length === 0 && this.props.canEditGroup && this.props.onDeleteGroup ?
                                    <MenuItem onClick={this.onDeleteClicked.bind(this)}>
                                        <ListItemIcon>
                                            <DeleteIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body2">
                                                Delete
                                            </Typography>
                                        </ListItemText>
                                    </MenuItem>
                                    : null
                                }
                            </DropdownMenu>
                            : null
                        }
                    </Box>

                    <Box
                        onClick={this.props.onViewGroup.bind(this)}
                        sx={{
                            backgroundColor: "#1e2146",
                            margin: 0,
                            padding: 0,
                            marginTop: "auto",
                            width: "100%",
                            borderRadius: "0  0 10px 10px",
                            position: "relative"
                        }}>
                        <Typography variant="body2" style={{
                            position: "absolute",
                            right: 5,
                            top: 5,
                            fontSize: "9px",
                            color: "#a8a9bf"
                        }}>
                            Asset Collection
                        </Typography>

                        <Typography variant="h6" component="h2" sx={{ padding: 2, wordBreak: "break-word" }}>
                            {this.props.group.name}
                        </Typography>

                        <Typography component="p" sx={{ pl: 2, pr: 2, fontSize: "0.8rem", wordBreak: "break-word" }}>
                            {this.props.group.description}
                        </Typography>

                        <Box sx={{ pb: 2 }}></Box>

                        <Box sx={{ display: "flex", justifyContent: "space-between", fontSize: "0.8rem" }}>
                            <Box sx={{ display: "flex", alignItems: "center", color: "silver", fontWeight: "bold", p: 2 }}>
                                {
                                    this.props.group.isPurchased ?
                                        <Tooltip title="Purchased: This collection was purchased via the asset store">
                                            <Box style={{ display: "flex", alignItems: "center", color: "#77ccbe", margin: 0 }}>
                                                <VisibilityOffIcon sx={{ fontSize: "14px", mr: 0.5 }} /> Purchased
                                            </Box>
                                        </Tooltip>
                                        : this.props.group.isPublic ?
                                            <Tooltip title="Public: This collection is visible to everyone in the campus">
                                                <Box style={{ display: "flex", alignItems: "center", color: "#77ccbe", margin: 0 }}>
                                                    <PublicIcon sx={{ fontSize: "14px", mr: 0.5 }} /> Public
                                                </Box>
                                            </Tooltip>
                                            :
                                            <Tooltip title="Private: This collection is only visible to you in the campus">
                                                <Box style={{ display: "flex", alignItems: "center", color: "rgb(193 0 169)", margin: 0 }}>
                                                    <VisibilityOffIcon sx={{ fontSize: "14px", mr: 0.5 }} /> Private
                                                </Box>
                                            </Tooltip>
                                }
                            </Box>
                            <Box sx={{ p: 2 }}>
                                <FolderIcon sx={{ fontSize: "14px", fill: "#77CCBE" }} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </ListItem>
        );
    }
}
