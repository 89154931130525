import { Environment } from "./Environment";
import { FileFormat } from "./FileObject";

export class Campus 
{
    constructor(
        private _id: number,
        private _name: string,
        private _description: string,
        private _thumbnail: FileFormat,
        private _icon: FileFormat,
        private _primaryColor: string,
        private _secondaryColor: string,
        private _openingEnvironment: Environment,
        private _environments: Array<Environment>
    )
    {
    }

    public static fromJson(json: any): Campus
    {
        let environments = [];
        for (let i = 0; i < json.environments.length; i++)
        {
            environments.push(Environment.fromJson(json.environments[i]));
        }
        return new Campus(
            json.id,
            json.name,
            json.description,
            json.file,
            json.icon,
            json.primary_color,
            json.secondary_color,
            json.opening_environment,
            environments
        );
    }

    public get id(): number { return this._id; }
    public get name(): string { return this._name; }
    public get description(): string { return this._description; }
    public get thumbnail(): FileFormat { return this._thumbnail; }
    public get icon(): FileFormat { return this._icon; }
    public get primaryColor(): string { return this._primaryColor; }
    public get secondaryColor(): string { return this._secondaryColor; }
    public get openingEnvironment(): Environment { return this._openingEnvironment; }
    public get environments(): Array<Environment> { return this._environments; }
}