import React, { Component } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from '@mui/material/Select';
import { Asset } from "../../../../api/models/Asset";
import { AssetService } from "../../../../services/AssetService";
import { CreateAssetRequest } from "../../../../api/AssetAPI";
import { AssetThumbnail } from "./AssetThumbnail";
import { FormControl, InputLabel, Link, MenuItem, Typography } from "@mui/material";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { Campus } from "../../../../api/models/Campus";
import EditIcon from '@mui/icons-material/Edit';
import { FileFormat } from "../../../../api/models/FileObject";
import { UserService } from "../../../../services/UserService";

export interface AssetFormProps
{
    onAssetDeleted?: () => void;
    onAssetCreated?: (asset: Asset) => void;
    onAssetUpdated?: (asset: Asset) => void;
    asset?: Asset;
    campus: Campus;
    campuses?: Array<Campus>;
    canEdit?: boolean;
}

interface AssetFormState 
{
    name: string;
    description: string;
    keywords: string;
    file: FileFormat;
    file_windows: FileFormat;
    attachedFileName: string;
    attachedFileWindowsName: string;
    thumbnail: FileFormat;
    attachedThumbnailFileName: string;
    isPublic: boolean;
    campusList: Array<CampusList>;
    selectedCampusList: Array<CampusList>;
    errorMessage: string;
    saving: boolean;
    physical_width_mm: number;
    physical_height_mm: number;
}

interface CampusList 
{
    campusId: number;
    label: string;
}

export class AssetForm extends Component<AssetFormProps, AssetFormState>
{
    private form: React.RefObject<HTMLFormElement>;
    private fileRef: React.RefObject<HTMLInputElement>;
    private fileWindowsRef: React.RefObject<HTMLInputElement>;
    private thumbnailRef: React.RefObject<HTMLInputElement>;

    constructor(props: AssetFormProps)
    {
        super(props);

        this.form = React.createRef();
        this.fileRef = React.createRef();
        this.fileWindowsRef = React.createRef();
        this.thumbnailRef = React.createRef();

        this.state = {
            name: this.props.asset?.name,
            description: this.props.asset?.description,
            keywords: this.props.asset?.keywords,
            file: this.props.asset?.file,
            file_windows: this.props.asset?.file_windows,
            attachedFileName: null,
            attachedFileWindowsName: null,
            thumbnail: this.props.asset?.thumbnail,
            attachedThumbnailFileName: null,
            isPublic: this.props.asset?.isPublic,
            campusList: [],
            selectedCampusList: [],
            errorMessage: null,
            saving: false,
            physical_width_mm: this.props.asset?.physical_width_mm ?? 0,
            physical_height_mm: this.props.asset?.physical_height_mm ?? 0,
        };
    }

    public componentDidMount(): void
    {
        let selectedCampusIds = this.props.asset?.campuses?.map((campus) => campus.id) ?? [];

        if (this.props.campuses)
        {
            this.buildCampusList(
                this.props.campuses,
                selectedCampusIds
            );
        }
    }

    private buildCampusList(campuses: Array<Campus>, selectedCampusIds: Array<number>): void
    {
        let campusListItems: Array<CampusList> = [];
        let defaultCampusListItems: Array<CampusList> = [];

        for (let i = 0; i < campuses.length; i++)
        {
            const campus: Campus = campuses[i];

            let userList: CampusList = {
                label: campus.name,
                campusId: campus.id
            };

            if (selectedCampusIds.indexOf(campus.id) > -1)
            {
                defaultCampusListItems.push(userList);
            }

            campusListItems.push(userList);
        }

        this.setState({
            campusList: campusListItems,
            selectedCampusList: defaultCampusListItems
        });
    }

    private async onSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> 
    {
        // prevent submit if user is not the owner of the asset
        if (this.props.asset && this.props?.canEdit === false) {
            return;
        }
        e.preventDefault();

        this.setState({
            saving: true
        });

        let formData = new FormData();
        let currentFormData: FormData = new FormData(this.form.current);
        let createAssetRequest: CreateAssetRequest = {
            name: (currentFormData.get("name") as string),
            campuses: [this.props.campus.id],
            description: (currentFormData.get("description") as string),
            keywords: (currentFormData.get("keywords") as string),
            is_public: currentFormData.get("visibility") as any,
            owner: UserService.user.id,
            physical_width_mm: parseInt(currentFormData.get("physical_width_mm").toString()) ?? 0,
            physical_height_mm: parseInt(currentFormData.get("physical_height_mm").toString()) ?? 0,
        };
        formData.append("data", JSON.stringify(createAssetRequest));

        // If file has changed, update
        if (this.fileRef.current.files.length > 0)
        {
            formData.append("files.file", currentFormData.get("file"));
        }

        if (this.fileWindowsRef.current.files.length > 0)
        {
            formData.append("files.file_windows", currentFormData.get("file_windows"));
        }

        if (this.thumbnailRef.current.files.length > 0)
        {
            formData.append("files.thumbnail", currentFormData.get("thumbnail"));
        }

        if (this.props.asset == null)
        {
            await this.onCreate(formData);
        }
        else 
        {
            await this.onEdit(formData);
        }

        this.setState({
            saving: false
        });
    }

    private async onCreate(formData: FormData): Promise<void>
    {
        const assetService: AssetService = new AssetService();

        try
        {
            const asset: Asset = await assetService.createAsset(formData);
            this.form.current.reset();
            this.props.onAssetCreated(asset);
        }
        catch (error)
        {
            this.setState({
                errorMessage: error.message,
                saving: false
            });
        }
    }

    private async onEdit(formData: FormData): Promise<void>
    {
        const assetService: AssetService = new AssetService();

        try
        {
            const asset: Asset = await assetService.updateAsset(this.props.asset.id, formData);
            this.props.onAssetUpdated(asset);
        }
        catch (error)
        {
            this.setState({
                errorMessage: error.message,
                saving: false
            });
        }
    }

    private onConfirmAssetDeletion(): void 
    {
        const confirmed = window.confirm("Are you sure you want delete this asset? This cannot be reversed.");
        if (confirmed)
        {
            this.onDeleteClicked();
        }
    }

    private async onDeleteClicked(): Promise<void>
    {
        const assetService: AssetService = new AssetService();

        try
        {
            await assetService.deleteAssetById(this.props.asset.id);
            this.props.onAssetDeleted();
        }
        catch (error)
        {
            this.setState({
                errorMessage: error.message,
                saving: false
            });
        }
    }

    private onThumbnailFileChanged(): void 
    {
        let thumbnail = this.thumbnailRef.current?.files[0];
        if (thumbnail)
        {
            let fileName: string = thumbnail.name;
            this.setState({
                attachedThumbnailFileName: fileName
            });
        }
    }

    private onFileChanged(): void 
    {
        let file = this.fileRef.current?.files[0];
        if (file)
        {
            let fileName: string = file.name;
            this.setState({
                attachedFileName: fileName
            });
        }
    }

    private onFileWindowsChanged(): void 
    {
        let file = this.fileWindowsRef.current?.files[0];
        if (file)
        {
            let fileName: string = file.name;
            this.setState({
                attachedFileWindowsName: fileName
            });
        }
    }

    public render(): JSX.Element
    {
        return (
            <Grid container component="main">
                <Grid item xs={12} >
                    <Box
                        sx={{
                            my: 4,
                            mx: 2,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Box component="form" noValidate sx={{ mt: 1, maxHeight: "50vh", overflow: "auto", pr: 2, pl: 2 }} onSubmit={this.onSubmit.bind(this)} ref={this.form}>

                            <Box sx={{
                                display: "flex", flexDirection: "row",
                                position: "relative",
                                width: "200px",
                                minHeight: "180px",
                                backgroundColor: "#252740",
                                height: "100%",
                                margin: "auto"
                            }}>
                                {this.props.asset ?
                                    <div style={{ width: "200px", height: "100%", maxWidth: "100%", }}>
                                        <AssetThumbnail asset={this.props.asset} />
                                    </div>
                                    : null
                                }

                                {this.props?.canEdit !== false ? <Box sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    width: "100%",
                                    height: "100%",
                                    position: "absolute",
                                    background: "rgb(0 0 0 / 30%)",
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                }}>
                                    <input
                                        id="asset-thumbnail"
                                        name="thumbnail"
                                        type="file"
                                        ref={this.thumbnailRef}
                                        title="Edit thumbnail"
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute", width: "100%", height: "100%", top: 0, bottom: 0, left: 0, right: 0, opacity: 0
                                        }}
                                        onChange={this.onThumbnailFileChanged.bind(this)}
                                    />
                                    <div>
                                        <EditIcon fontSize="large" />
                                    </div>
                                    {!this.props.asset?.thumbnail == null
                                        ?
                                        <>
                                            <div>Add a thumbnail</div>
                                        </>
                                        :
                                        <div>Edit thumbnail</div>
                                    }
                                </Box> : null}
                            </Box>

                            <Box sx={{ textAlign: "center", pt: 1 }}>
                                {this.state.attachedThumbnailFileName}
                            </Box>

                            <TextField
                                disabled={this.props?.canEdit === false}
                                margin="normal"
                                required
                                fullWidth
                                id="asset-title"
                                label="Name"
                                name="name"
                                autoComplete="asset-title"
                                defaultValue={this.state.name}
                            />

                            <TextField
                                disabled={this.props?.canEdit === false}
                                margin="normal"
                                required
                                fullWidth
                                id="asset-description"
                                label="Description"
                                name="description"
                                autoComplete="asset-description"
                                defaultValue={this.state.description}
                            />

                            <TextField
                                disabled={this.props?.canEdit === false}
                                margin="normal"
                                required
                                fullWidth
                                id="asset-keywords"
                                label="Keywords"
                                name="keywords"
                                autoComplete="asset-keywords"
                                defaultValue={this.state.keywords}
                            />

                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel id="visibility">Visibility</InputLabel>
                                <Select
                                    disabled={this.props?.canEdit === false}
                                    labelId="visibility"
                                    id="visibility"
                                    label="visibility"
                                    name="visibility"
                                    defaultValue={this.state.isPublic == true ? 1 : 0}
                                >
                                    <MenuItem value={0}>Can only be viewed by me</MenuItem>
                                    <MenuItem value={1}>Can be viewed by anyone</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                disabled={this.props?.canEdit === false}
                                margin="normal"
                                required
                                fullWidth
                                id="asset-file"
                                label="Asset file"
                                name="file"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                autoComplete="file"
                                type="file"
                                inputRef={this.fileRef}
                                onChange={this.onFileChanged.bind(this)}
                            />

                            <Typography>
                                {this.props.asset?.file ?
                                    <>
                                        <strong>Name:</strong> {this.props.asset?.file?.name} <br />
                                        <strong>Type:</strong> {this.props.asset?.file?.ext} <br />
                                    </>
                                    :
                                    this.state.attachedFileName ?
                                        <>
                                            <strong>Name:</strong> {this.state.attachedFileName} <br />
                                        </>
                                        : null
                                }
                            </Typography>

                            {/* Windows file, needed for separating asset bundles by platform */}
                            <TextField
                                disabled={this.props?.canEdit === false}
                                margin="normal"
                                fullWidth
                                id="asset-file-windows"
                                label="Asset file Windows (asset bundles only)"
                                name="file_windows"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                autoComplete="file (Windows)"
                                type="file"
                                inputRef={this.fileWindowsRef}
                                onChange={this.onFileWindowsChanged.bind(this)}
                            />

                            <Typography>
                                {this.props.asset?.file ?
                                    <>
                                        <strong>Name:</strong> {this.props.asset?.file_windows?.name} <br />
                                        <strong>Type:</strong> {this.props.asset?.file_windows?.ext} <br />
                                    </>
                                    :
                                    this.state.attachedFileWindowsName ?
                                        <>
                                            <strong>Name:</strong> {this.state.attachedFileWindowsName} <br />
                                        </>
                                        : null
                                }
                            </Typography>

                            <TextField
                                disabled={this.props?.canEdit === false}
                                margin="normal"
                                type="number"
                                fullWidth
                                id="physical_width_mm"
                                label="Physical width (mm)"
                                name="physical_width_mm"
                                autoComplete="physical_width_mm"
                                defaultValue={this.state.physical_width_mm}
                            />

                            <TextField
                                disabled={this.props?.canEdit === false}
                                margin="normal"
                                type="number"
                                fullWidth
                                id="physical_height_mm"
                                label="Physical height (mm)"
                                name="physical_height_mm"
                                autoComplete="physical_height_mm"
                                defaultValue={this.state.physical_height_mm}
                            />

                            {this.state.errorMessage ?
                                <ErrorMessage message={this.state.errorMessage} />
                                : null
                            }
                            {this.props?.canEdit !== false ? 
                                <Button
                                    fullWidth
                                    type="submit"
                                    disabled={this.state.saving}
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Save
                                </Button>
                                : null
                            }

                            {this.props.asset && this.props?.canEdit ?
                                <Button
                                    fullWidth
                                    disabled={this.state.saving}
                                    variant="outlined"
                                    color="error"
                                    onClick={this.onConfirmAssetDeletion.bind(this)}>
                                    <DeleteIcon /> Delete asset permanently </Button>
                                : null
                            }
                        </Box>

                        {this.props.asset && this.props?.canEdit ?
                            <Typography sx={{ pt: 2, fontSize: 13 }} color="primary">
                                Note: Any updates to this asset will be applied everywhere, regardless of the collection.
                            </Typography>
                            : null
                        }
                    </Box>
                </Grid>
            </Grid>
        );
    }
}
