export enum Page 
{
    Dashboard = "/",
    User = "/user",

    // Assets
    Assets = "/assets",
    AssetEdit = "/assets/edit/",
    AssetEditRoute = "/assets/edit/:assetId",

    // Asset Packages
    AssetGroups = "/asset-groups",
    AssetGroupEdit = "/asset-groups/edit/",
    AssetGroupEditRoute = "/asset-groups/edit/:assetGroupId",

    // Campus
    Campuses = "/campuses",
    CampusDetail = "/campuses/",
    CampusDetailRoute = "/campuses/:campusId",
    CampusCreateRoute = "/campuses/create",

    // Sessions
    CampusSessions = "/campuses/:campusId/sessions",
    CampusSessionCreate = "/campuses/:campusId/session/create",
    CampusSessionDetailRoute = "/campuses/:campusId/sessions/:sessionId",

    CampusEdit = "/campuses/edit/",
    CampusEditRoute = "/campuses/edit/:campusId",

    CampusEditUsersRoute = "/campuses/:campusId/users",
    CampusImportUsersRoute = "/campuses/:campusId/users/import",
    CampusEditUserGroupsRoute = "/campuses/:campusId/user-groups",
    CampusInvitedUsersRoute = "/campuses/:campusId/invited-users",

    CampusUserGroupEdit = "/campuses/:campusId/user-groups/:groupId",

    // Campus Assets
    CampusAssetsRoute = "/campuses/:campusId/assets",
    CampusAssetEditRoute = "/campuses/:campusId/assets/edit",
    CampusAssetEditUpdateRoute = "/campuses/:campusId/assets/edit/:assetId",
    CampusAssetGroupsRoute = "/campuses/:campusId/asset-groups",
    CampusAssetGroupsEditRoute = "/campuses/:campusId/asset-groups/edit/:assetGroupId",

    // Auth
    Login = "/login",
    Register = "/register",
    ForgotPassword = "/forgot",
    CampusInvite = "/campus-invite",
    ResetPassword = "/reset",
    PostRegister = "/register-complete",
}