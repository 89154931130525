import React, { Component } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Box, Paper, Typography } from "@mui/material";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { Campus } from "../../../../api/models/Campus";
import { CampusService } from "../../../../services/CampusService";
import { CreateCampusRequest, UpdateCampusRequest } from "../../../../api/CampusAPI";
import { FileFormat } from "../../../../api/models/FileObject";

export interface CampusFormProps
{
    onCampusCreated?: (campus: Campus) => void;
    onCampusUpdated?: (campus: Campus) => void;
    campus?: Campus;
}

interface CampusFormState 
{
    title: string;
    description: string;
    errorMessage: string;
    file: FileFormat;
    icon: FileFormat;
    primaryColor: string;
    secondaryColor: string;
}

export class CampusForm extends Component<CampusFormProps, CampusFormState>
{
    private form: React.RefObject<HTMLFormElement>;
    private fileRef: React.RefObject<HTMLInputElement>;
    private iconFileRef: React.RefObject<HTMLInputElement>;
    private campusService: CampusService;

    constructor(props: CampusFormProps)
    {
        super(props);

        this.form = React.createRef();
        this.fileRef = React.createRef();
        this.iconFileRef = React.createRef();

        this.campusService = new CampusService();

        this.state = {
            title: this.props.campus?.name ?? "",
            description: this.props?.campus?.description ?? "",
            file: this.props.campus?.thumbnail,
            icon: this.props.campus?.icon,
            primaryColor: this.props.campus?.primaryColor ?? "#FFFFFF",
            secondaryColor: this.props.campus?.secondaryColor ?? "#000000",
            errorMessage: null,
        };
    }

    public componentDidMount(): void
    {

    }

    private onSubmit(e: React.FormEvent<HTMLFormElement>): void
    {
        e.preventDefault();
        if (this.props.campus == null)
        {
            // Create
            this.onCreate();
        }
        else
        {
            // Edit
            this.onEdit();
        }
    }

    private async onCreate(): Promise<void>
    {
        try
        {
            let currentFormData: FormData = new FormData(this.form.current);

            let createCampusRequest: CreateCampusRequest = {
                name: this.state.title,
                description: this.state.description,
                primary_color: currentFormData.get("primary_color").toString(),
                secondary_color: currentFormData.get("secondary_color").toString(),
            };

            let formDataRequest = new FormData();
            formDataRequest.append("data", JSON.stringify(createCampusRequest));

            // If file has changed, update
            if (this.fileRef.current.files?.length > 0)
            {
                formDataRequest.append("files.file", currentFormData.get("file"));
            }

            if (this.iconFileRef.current.files?.length > 0)
            {
                formDataRequest.append("files.icon", currentFormData.get("icon"));
            }

            let campus: Campus = await this.campusService.createCampus(formDataRequest);
            this.props.onCampusCreated(campus);
        }
        catch (error)
        {
            console.log(error);
            this.setState({
                errorMessage: error.message
            });
        }
    }

    private async onEdit(): Promise<void>
    {
        try
        {
            let currentFormData: FormData = new FormData(this.form.current);

            let updateCampusJsonReq: UpdateCampusRequest = {
                id: this.props.campus.id,
                name: this.state.title,
                description: this.state.description,
                primary_color: currentFormData.get("primary_color").toString(),
                secondary_color: currentFormData.get("secondary_color").toString(),
            };

            let formDataRequest = new FormData();
            formDataRequest.append("data", JSON.stringify(updateCampusJsonReq));

            // If file has changed, update
            if (this.fileRef.current.files?.length > 0)
            {
                formDataRequest.append("files.file", currentFormData.get("file"));
            }

            if (this.iconFileRef.current.files?.length > 0)
            {
                formDataRequest.append("files.icon", currentFormData.get("icon"));
            }

            const campus: Campus = await this.campusService.updateCampus(this.props.campus.id, formDataRequest);

            this.props.onCampusUpdated(campus);
        }
        catch (error)
        {
            console.log(error);
            this.setState({
                errorMessage: error.message
            });
        }
    }

    private onTitleChanged(e: React.FormEvent<HTMLInputElement>): void
    {
        this.setState({
            title: e.currentTarget.value
        });
    }

    private onDescriptionChanged(e: React.FormEvent<HTMLInputElement>): void
    {
        this.setState({
            description: e.currentTarget.value
        });
    }

    public render(): JSX.Element
    {
        return (
            <Grid container component="main" width={"100%"}>
                <Grid xs={12}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Box width={"100%"} component="form" noValidate onSubmit={this.onSubmit.bind(this)} ref={this.form}>
                            <Grid container spacing={2}>
                                <Grid xs={4} md={4} py={0}>
                                    <Grid component={Paper} sx={{
                                        py: 4,
                                        px: 4,
                                        mb: 1
                                    }}>
                                        <Typography variant="h6">Campus icon</Typography>
                                        {this.state.icon ?
                                            <>
                                                File icon
                                                <div style={{ width: "200px", maxWidth: "100%", marginBottom: "1rem" }}>
                                                    <img src={this.state.icon?.url} style={{ maxWidth: "100%" }} />
                                                </div>
                                            </>
                                            : null
                                        }

                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="asset-icon"
                                            label=""
                                            name="icon"
                                            autoComplete="file"
                                            type="file"
                                            inputRef={this.iconFileRef}
                                        />
                                    </Grid>
                                    <Grid component={Paper} sx={{
                                        py: 4,
                                        px: 4
                                    }}>
                                        <Typography variant="h6">Campus logo</Typography>
                                        {this.state.file ?
                                            <>
                                                File image
                                                <div style={{ width: "200px", maxWidth: "100%", marginBottom: "1rem" }}>
                                                    <img src={this.state.file.url} style={{ maxWidth: "100%" }} />
                                                </div>
                                            </>
                                            : null
                                        }

                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="asset-file"
                                            label=""
                                            name="file"
                                            autoComplete="file"
                                            type="file"
                                            inputRef={this.fileRef}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid xs={8} md={8} component={Paper} sx={{
                                    py: 4,
                                    px: 4
                                }}>
                                    <Typography sx={{ mb: 1 }} variant="h6">Campus details</Typography>

                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="session-title"
                                        label="College name"
                                        name="title"
                                        autoComplete="title"
                                        autoFocus
                                        onChange={this.onTitleChanged.bind(this)}
                                        value={this.state.title}
                                    />

                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="session-description"
                                        label="Description"
                                        name="description"
                                        autoComplete="description"
                                        onChange={this.onDescriptionChanged.bind(this)}
                                        value={this.state.description}
                                    />

                                    <Typography sx={{ mb: 2, mt: 2 }} variant="h6">Campus branding</Typography>

                                    <Grid container flexDirection={"column"}>
                                        <Grid sx={{ mb: 1, mr: 2 }}>
                                            <input
                                                type="color"
                                                name="primary_color"
                                                defaultValue={this.state.primaryColor}
                                            />
                                            <span style={{ paddingLeft: "12px" }}>
                                                Primary Color
                                            </span>
                                        </Grid>

                                        <Grid>
                                            <input
                                                type="color"
                                                name="secondary_color"
                                                defaultValue={this.state.secondaryColor}
                                            />
                                            <span style={{ paddingLeft: "12px" }}>
                                                Secondary Color
                                            </span>
                                        </Grid>
                                    </Grid>
                                    {this.state.errorMessage ?
                                        <ErrorMessage message={this.state.errorMessage} />
                                        : null
                                    }

                                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{ mt: 3 }}
                                        >
                                            {this.props.campus == null ? "Create" : "Save"}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}