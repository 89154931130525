import React, { Component } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { UserGroup } from "../../../../api/models/UserGroup";
import { UserGroupService } from "../../../../services/UserGroupService";
import { Campus } from "../../../../api/models/Campus";
import { ErrorMessage } from "../../../components/ErrorMessage";
import Autocomplete from "@mui/material/Autocomplete";

export interface UserGroupFormProps
{
    userGroup?: UserGroup;
    campus: Campus;
    onUpdated: () => void;
}

interface UserGroupFormState 
{
    name: string;
    errorMessage: string;
    saving: boolean;
}

export class UserGroupForm extends Component<UserGroupFormProps, UserGroupFormState>
{
    private form: React.RefObject<HTMLFormElement>;

    private name: string = "";

    constructor(props: UserGroupFormProps)
    {
        super(props);

        this.state = {
            name: this.props.userGroup?.name,
            errorMessage: null,
            saving: false,
        };
    }

    public componentDidMount(): void
    {
    }

    private async onSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> 
    {
        e.preventDefault();

        this.setState({
            saving: true
        });

        let userGroupService = new UserGroupService();

        if (this.props.userGroup)
        {
            await userGroupService.updateUserGroup({
                id: this.props.userGroup?.id,
                name: this.name,
                campusId: this.props.campus.id,
            });
        }
        else 
        {
            await userGroupService.createUserGroup({
                name: this.name,
                campus: this.props.campus.id,
            });
        }

        this.setState({
            saving: false,
        });

        this.props.onUpdated();
    }

    private onGroupNameChanged(e: React.KeyboardEvent<HTMLInputElement>): void 
    {
        this.name = e.currentTarget.value;
    }

    public render(): JSX.Element
    {
        return (
            <Grid container component="main">
                <Grid item xs={12} >
                    <Box
                        sx={{
                            my: 1,
                            mx: 2,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Box
                            noValidate
                            component="form"
                            sx={{ mt: 1, maxHeight: "50vh", overflow: "auto", pr: 2, pl: 2 }}
                            onSubmit={this.onSubmit.bind(this)}
                            ref={this.form}
                        >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                name="name"
                                autoComplete="name"
                                onChange={this.onGroupNameChanged.bind(this)}
                                defaultValue={this.props.userGroup?.name}
                            />

                            {/* <Autocomplete
                                disablePortal
                                options={[{
                                    label: "test", id: 1
                                }]}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Movie" />}
                            /> */}

                            {this.state.errorMessage ?
                                <ErrorMessage message={this.state.errorMessage} />
                                : null
                            }

                            <Button
                                fullWidth
                                type="submit"
                                disabled={this.state.saving}
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}