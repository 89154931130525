import React, { Component, ReactEventHandler } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Typography, FormControl, InputLabel, MenuItem, InputAdornment, IconButton } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

export interface FilterBarProps
{
    defaultSearchTerm: string;
    onClearSearchButtonClicked: () => void;
    onSearchTermChanged: (searchTerm: string) => void;
    onVisibilityChanged?: (visiblity: FilterVisibleState) => void;
}

interface FilterBarState 
{
}

export enum FilterVisibleState 
{
    PublicOnly,
    PrivateOnly,
    Both
}

export class FilterBar extends Component<FilterBarProps, FilterBarState>
{
    constructor(props: FilterBarProps)
    {
        super(props);

        this.state = {
        };
    }

    private onSearch(e: React.KeyboardEvent<HTMLInputElement>): void 
    {
        this.props.onSearchTermChanged(e.currentTarget.value);
    }

    private onFilterChange(e: SelectChangeEvent<number>): void 
    {
        this.props.onVisibilityChanged(e.target.value as FilterVisibleState);
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Box sx={{ mr: 2 }}>
                    <Typography variant="h6">
                        Filter assets by
                    </Typography>
                </Box>
                <Box sx={{ mr: 2 }}>
                    <TextField
                        variant="outlined"
                        placeholder="Search..."
                        defaultValue={this.props.defaultSearchTerm}
                        onChange={this.onSearch.bind(this)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    onClick={this.props.onClearSearchButtonClicked.bind(this)}
                                    edge="end"
                                >
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                </Box>
                <Box sx={{ width: "300px", maxWidth: "100%" }}>
                    <FormControl fullWidth>
                        <InputLabel id="file-privacy">Privacy Specification</InputLabel>
                        <Select
                            labelId="file-privacy"
                            id="file-privacy"
                            label="Privacy Specification"
                            onChange={this.onFilterChange.bind(this)}
                            defaultValue={FilterVisibleState.Both}
                        >
                            <MenuItem value={FilterVisibleState.Both}>Both</MenuItem>
                            <MenuItem value={FilterVisibleState.PrivateOnly}>Private</MenuItem>
                            <MenuItem value={FilterVisibleState.PublicOnly}>Public</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </>
        );
    }
}