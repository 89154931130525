import { UserAPI } from "./UserAPI";
import { SessionAPI } from "./SessionAPI";
import { AssetAPI } from "./AssetAPI";
import { CampusAPI } from "./CampusAPI";
import { AssetGroupAPI } from "./AssetGroupAPI";
import { EnvironmentAPI } from "./EnvironmentAPI";
import { UserGroupAPI } from "./UserGroupAPI";

export class AppAPI
{
    private static _instance: AppAPI;

    private _baseUrl: string;
    private _users: UserAPI;
    private _userGroups: UserGroupAPI;
    private _sessions: SessionAPI;
    private _assets: AssetAPI;
    private _campuses: CampusAPI;
    private _assetGroups: AssetGroupAPI;
    private _environments: EnvironmentAPI;

    constructor(baseUrl: string)
    {
        if (AppAPI._instance != null)
        {
            throw new Error("AppAPI ** Only one instance allowed");
        }

        AppAPI._instance = this;
        this._baseUrl = baseUrl;

        let sharedHeaders: { [name: string]: string } =
        {
            "Content-Type": "application/json"
        };

        this._users = new UserAPI(this._baseUrl, sharedHeaders);
        this._userGroups = new UserGroupAPI(this._baseUrl, sharedHeaders);
        this._sessions = new SessionAPI(this._baseUrl, sharedHeaders);
        this._assets = new AssetAPI(this._baseUrl, sharedHeaders);
        this._campuses = new CampusAPI(this._baseUrl, sharedHeaders);
        this._assetGroups = new AssetGroupAPI(this._baseUrl, sharedHeaders);
        this._environments = new EnvironmentAPI(this._baseUrl, sharedHeaders);
    }

    public setAuthHeader(token: string): void
    {
        let headerKey: string = "Authorization";
        let headerValue: string = "Bearer " + token;

        this._users.setHeader(headerKey, headerValue);
        this._userGroups.setHeader(headerKey, headerValue);
        this._sessions.setHeader(headerKey, headerValue);
        this._assets.setHeader(headerKey, headerValue);
        this._campuses.setHeader(headerKey, headerValue);
        this._assetGroups.setHeader(headerKey, headerValue);
        this._environments.setHeader(headerKey, headerValue);
        // TODO Reminder to set auth headers on all APIs
    }

    public static get I(): AppAPI { return AppAPI._instance; }
    public get baseUrl(): string { return this._baseUrl; }
    public get users(): UserAPI { return this._users; }
    public get userGroups(): UserGroupAPI { return this._userGroups; }
    public get sessions(): SessionAPI { return this._sessions; }
    public get assets(): AssetAPI { return this._assets; }
    public get environments(): EnvironmentAPI { return this._environments; }
    public get campuses(): CampusAPI { return this._campuses; }
    public get assetGroups(): AssetGroupAPI { return this._assetGroups; }
}
