import React, { Component } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { App } from "../../../App";
import { Page } from "../../../Page";
import { PageErrorMessage } from "../../components/PageErrorMessage";
import Header from "../../components/Header";
import { Campus } from "../../../api/models/Campus";
import { CampusList } from "./components/CampusList";
import { BaseBox } from "../../components/BaseBox";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { UserService } from "../../../services/UserService";
import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

export interface CampusPageProps
{
}

interface CampusPageState 
{
    campuses: Array<Campus>;
    loading: boolean;
    pageError: Error;
    validationError: Error;
}

export class CampusPage extends Component<CampusPageProps, CampusPageState>
{
    private userService: UserService;

    constructor(props: CampusPageProps)
    {
        super(props);

        this.userService = new UserService();

        this.state = {
            campuses: [],
            loading: true,
            pageError: null,
            validationError: null
        };

        App.I.setPageTitle("Campuses");
    }

    public componentDidMount(): void
    {
        this.getCampuses();
    }

    private async getCampuses(): Promise<void> 
    {
        try
        {
            const campuses: Array<Campus> = await this.userService.getUserCampuses();
            this.setState({
                campuses: campuses,
                loading: false
            });
        }
        catch (error: any)
        {
            this.setState({
                pageError: error,
                loading: false
            });
        }
    }

    private onCampusClicked(campus: Campus): void 
    {
        App.I.changePage(Page.CampusDetail, campus.id.toString());
    }

    private onCreateCampusClicked(e): void
    {
        document.location = "/campuses/create";
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Header />

                <Container component="main" maxWidth="xl">

                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4, mb: 4 }}>
                        <Link color="inherit" href="/">
                            Dashboard
                        </Link>
                        <Typography color="text.primary">Campuses</Typography>
                    </Breadcrumbs>

                    {this.state.loading === false ?
                        <>
                            {this.state.pageError != null ?
                                <PageErrorMessage error={this.state.pageError} />
                                :
                                <>
                                    <BaseBox>
                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid xs={8}>
                                                    <Box style={{ marginTop: "100px" }}>
                                                        <Typography variant="h3" component="h2" fontWeight={600}>
                                                            Campus Manager
                                                        </Typography>
                                                        <Typography component="p" sx={{ mt: 1 }}>
                                                            Below are the Campuses you can manage.
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid xs={4} container justifyContent="flex-end" alignItems="flex-end">
                                                    <Box style={{ marginTop: "100px" }}>
                                                        {/*
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<AddIcon />}
                                                            onClick={this.onCreateCampusClicked.bind(this)}
                                                            sx={{ mt: 0, mb: 2, mr: 2 }}
                                                        >
                                                            Create campus
                                                        </Button>
                                                        */}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </BaseBox>
                                    <CampusList
                                        campuses={this.state.campuses}
                                        onCampusClicked={this.onCampusClicked.bind(this)}
                                    />
                                </>
                            }
                        </>
                        : null
                    }
                </Container>
            </>
        );
    }
}
