import { Box, SxProps, Typography } from "@mui/material";
import React, { Component } from "react";
import icon from "../../assets/images/campus-profile.svg";
import BusinessIcon from '@mui/icons-material/Business';

export interface CampusProfileIconProps
{
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

interface CampusProfileIconState
{
}

export class CampusProfileIcon extends Component<CampusProfileIconProps, CampusProfileIconState>
{
    constructor(props: CampusProfileIconProps)
    {
        super(props);

        this.state = {
        };
    }

    public render()
    {
        return (
            // <div>
            //     <img src={icon} alt="Campus icon" style={{ maxWidth: "100%", ...this.props.style }} />
            // </div>
            <div>
                <BusinessIcon style={{ ...this.props.style }} />
            </div>
        );
    }
}