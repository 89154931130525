import React, { Component } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Session } from "../../../api/models/Session";
import { App } from "../../../App";
import { Page } from "../../../Page";
import { SessionService } from "../../../services/SessionService";
import { PageErrorMessage } from "../../components/PageErrorMessage";
import Header from "../../components/Header";
import { SessionForm } from "./components/SessionForm";
import { RouterProps } from "../../RouterProps";
import { withRouter } from "../../withRouter";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Campus } from "../../../api/models/Campus";
import { CampusService } from "../../../services/CampusService";

export interface SessionDetailPageProps extends RouterProps
{
}

interface SessionDetailPageState 
{
    session: Session;
    sessions: Array<Session>;
    loading: boolean;
    pageError: Error;
    campus: Campus;
}

class SessionDetailPage extends Component<SessionDetailPageProps, SessionDetailPageState>
{
    private campusService: CampusService;
    private sessionService: SessionService;

    constructor(props: SessionDetailPageProps)
    {
        super(props);

        this.campusService = new CampusService();
        this.sessionService = new SessionService();

        this.state = {
            campus: null,
            sessions: [],
            session: null,
            pageError: null,
            loading: true
        };

        App.I.setPageTitle("Sessions");
    }

    public componentDidMount(): void
    {
        this.fetchData();
    }

    private async fetchData(): Promise<void> 
    {
        await this.getCampus();
        await this.getSession();

        this.setState({
            loading: false
        });
    }

    private async getCampus(): Promise<void> 
    {
        try
        {
            const campusId: number = this.props.router.params.campusId;
            const campus: Campus = await this.campusService.getCampusById(campusId);

            this.setState({
                campus,
            });
        }
        catch (error)
        {
            this.setState({
                pageError: error,
                loading: false
            });
        }
    }

    private async getSession(): Promise<void> 
    {
        try
        {
            const sessionId: number = this.props.router.params.sessionId;
            const session: Session = await this.sessionService.getSessionById(sessionId);

            App.I.setPageTitle(session.name);

            this.setState({
                session,
            });

        }
        catch (error)
        {
            this.setState({
                pageError: error,
                loading: false
            });
        }
    }

    private async onSessionUpdated(session: Session): Promise<void>
    {
        App.I.sendNotification(`Session  ${session.name} updated succesfully`);
    }

    private async onSessionDeleted(): Promise<void>
    {
        document.location.href = `/campuses/${this.state.campus.id}/sessions`;
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Header currentCampus={this.state.campus} />

                <Container component="main" maxWidth="xl">
                    {this.state.loading === false ?
                        <>
                            {this.state.pageError != null ?
                                <PageErrorMessage error={this.state.pageError} />
                                :
                                <>
                                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4, mb: 4 }}>
                                        <Link color="inherit" href="/">
                                            Dashboard
                                        </Link>
                                        <Link color="inherit" href={`/campuses`}>
                                            Campuses
                                        </Link>
                                        <Link color="inherit" href={`/campuses/${this.state.campus.id}`}>
                                            {this.state.campus.name}
                                        </Link>
                                        <Link color="inherit" href={`/campuses/${this.state.campus.id}/sessions`}>
                                            Sessions
                                        </Link>
                                        <Typography color="text.primary">Editing {this.state.session?.name}</Typography>
                                    </Breadcrumbs>

                                    <Grid container spacing={2}>
                                        <Grid xs={12} md={12}>
                                            <Box
                                                sx={{
                                                    marginTop: 8,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                }}
                                                component={Paper}
                                            >
                                                <Typography component="h2" variant="h3" sx={{ mt: 4 }}>
                                                    Edit {this.state.session?.name}
                                                </Typography>

                                                {this.state.session ?
                                                    <SessionForm
                                                        onSessionUpdated={this.onSessionUpdated.bind(this)}
                                                        onSessionDeleted={this.onSessionDeleted.bind(this)}
                                                        session={this.state.session}
                                                        campus={this.state.campus}
                                                    />
                                                    : null
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </>
                        : null
                    }
                </Container>
            </>
        );
    }
}

export default withRouter<SessionDetailPageProps>(SessionDetailPage);