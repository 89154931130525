import { Logger } from "../Logger";
import { EventDispatcher } from "../DIEvent";
import { ReadyPlayMeModelCreatedEvent } from "./events/ReadyPlayMeModelCreatedEvent";
import { ReadyPlayMeUserCreatedEvent } from "./events/ReadyPlayMeUserCreatedEvent";

export class ReadyPlayMe extends EventDispatcher
{
    private iframe!: HTMLIFrameElement;

    constructor()
    {
        super();

        window.addEventListener("message", this.subscribe.bind(this));
        document.addEventListener("message", this.subscribe.bind(this));
    }

    public initIframe(iframe: HTMLIFrameElement)
    {
        const subdomain: string = "dixtend";
        this.iframe = iframe;
        this.iframe.src = `https://${subdomain}.readyplayer.me/avatar?frameApi&clearCache&bodyType=halfbody`;
    }

    private subscribe(event: any)
    {
        let json = null;
        try
        {
            json = JSON.parse(event.data);
        }
        catch (error)
        {
            return null;
        }

        if (json?.source !== "readyplayerme")
        {
            return;
        }

        // Susbribe to all events sent from Ready Player Me once frame is ready
        if (json.eventName === "v1.frame.ready")
        {
            this.iframe.contentWindow.postMessage(
                JSON.stringify({
                    target: "readyplayerme",
                    type: "subscribe",
                    eventName: "v1.**"
                }),
                "*"
            );
        }

        Logger.log("EventReceived **", json);

        // Get avatar GLB URL
        if (json.eventName === "v1.avatar.exported")
        {
            let avatarURL: string = json.data.url;
            let userId: number = parseInt(json.data.userId);
            this.dispatchEvent(new ReadyPlayMeModelCreatedEvent(ReadyPlayMeModelCreatedEvent.MODEL_CREATED, avatarURL, userId));
        }

        // Get user id
        if (json.eventName === "v1.user.set")
        {
            let readyPlayMeUserId: number = parseInt(json.data.id);
            this.dispatchEvent(new ReadyPlayMeUserCreatedEvent(ReadyPlayMeUserCreatedEvent.USER_CREATED, readyPlayMeUserId));
        }
    }
}