import { createTheme } from "@mui/material";

export function BaseTheme()
{
    return createTheme({
        palette: {
            mode: "dark",
            background: {
                default: "#1E2146",
                paper: "#343759"
            },
            primary: {
                main: "#77CCBE"
            },
            secondary: {
                main: "#FFFFFF"
            },
            warning: {
                main: "#CB3A3A"
            }
        },
        components: {
            MuiMenu: {
            },
            MuiButton: {
                variants: [
                    {
                        props: { variant: "contained", color: "primary" },
                        style: {
                            borderRadius: 20,
                            fontWeight: 800,
                            color: "#222133"
                        },
                    },
                    {
                        props: { variant: "contained", color: "secondary" },
                        style: {
                            borderRadius: 20,
                            fontWeight: 800,
                        },
                    },
                    {
                        props: { variant: "outlined" },
                        style: {
                            borderRadius: 20,
                            fontWeight: 800,
                        },
                    },
                    {
                        props: { variant: "text" },
                        style: {
                            color: "#ffffff",
                            borderRadius: 20,
                            fontWeight: 800,
                        },
                    },
                    {
                        props: { disabled: true },
                        style: {
                            opacity: 0.45
                        }
                    }
                ],
            },
            MuiLink: {
                variants: [
                    {
                        props: { variant: "body2" },
                        style: {
                            color: "#fff",
                        }
                    }
                ]
            },
            MuiTable: { styleOverrides: { root: { backgroundColor: "#1E2146" } } },
            MuiTableHead: { styleOverrides: { root: { backgroundColor: "#4C4F72" } } },
            MuiTableCell: { styleOverrides: { root: { border: " 1px solid #4C4F72" } } },
            MuiInputBase: {
                styleOverrides: {
                    root:
                    {
                        backgroundColor: "#1E2146",
                        border: "none",
                        borderRadius: "16px"
                    }
                }
            },
            MuiInput: {
                styleOverrides: {
                    root:
                    {
                        backgroundColor: "#1E2146",
                        border: "none",
                        borderRadius: "16px"
                    }
                }
            },

        },
        typography: {
            fontFamily: "Space Grotesk, sans-serif",
            fontWeightLight: 300,
            fontWeightRegular: 300,
            fontWeightBold: 600,
            h1: {
                fontSize: "1.8rem",
                fontWeight: 700,
            },
            h2: {
                fontSize: "1.7rem",
                fontWeight: 700,
            },
            h3: {
                fontSize: "1.5rem",
                fontWeight: 700,
            },
            h4: {
                fontSize: "1.4rem",
                fontWeight: 700,
            },
            h5: {
                fontSize: "1.2rem",
                fontWeight: 700,
            },
            h6: {
                fontSize: "1rem",
                fontWeight: 700,
            },
            button: {
                textTransform: "none",
                fontSize: "1rem",
            },
        }
    });
}