import { APIHelper } from "./APIHelper";
import { BaseAPI } from "./BaseAPI";
import { StrapiAPIPostRequest } from "./response/StrapiAPIPostRequest";
import { APIResponse } from "./rest/APIResponse";
import { RestRequest } from "./rest/RestRequest";

export interface GetAssetGroupRequest 
{
    page: number;
    campusId: number;
    parentGroupId?: number;
}

export interface CreateAssetGroupRequest
{
    name: string;
    description: string;
    assets: Array<number>;
    campuses: Array<number>;
    parent_asset_groups: Array<number>;
    owner: number;
}

export interface UpdateAssetGroupRequest
{
    id: number;
    name: string;
    description: string;
}

export class AssetGroupAPI extends BaseAPI
{
    constructor(
        baseUrl: string,
        headers: { [name: string]: string } = {}
    )
    {
        super(baseUrl, headers);
    }

    public async getCampusAssetGroups(requestParams: GetAssetGroupRequest): Promise<APIResponse>
    {
        let url: string = "/asset-groups/get-campus-asset-groups?campusId=" + requestParams.campusId

        if (requestParams)
        {
            let { page, parentGroupId } = requestParams;
            if (page)
            {
                url += `&page=${page}`;
            }

            if (parentGroupId)
            {
                url += `&groupId=${parentGroupId}`;
            }
        }

        let request: RestRequest = new RestRequest(this.url(url), this.headers);
        let response: APIResponse = await request.get();

        return response;
    }

    public async getAllCampusAssetGroups(campusId: number): Promise<APIResponse>
    {
        let url: string = "/asset-groups/get-all-campus-asset-groups?campusId=" + campusId

        let request: RestRequest = new RestRequest(this.url(url), this.headers);
        let response: APIResponse = await request.get();

        return response;
    }

    public async findById(id: number, campusId: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/asset-groups/${id}?campusId=${campusId}`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async create(requestData: FormData): Promise<APIResponse>
    {
        let modifiedHeaders = APIHelper.removeHeader("Content-Type", this.headers);
        let request: RestRequest = new RestRequest(this.url("/asset-groups"), modifiedHeaders);
        let response: APIResponse = await request.post(requestData, false);
        return response;
    }

    public async delete(id: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/asset-groups/${id}`), this.headers);
        let response: APIResponse = await request.delete();
        return response;
    }

    public async update(id: number, requestData: FormData): Promise<APIResponse>
    {
        let modifiedHeaders = APIHelper.removeHeader("Content-Type", this.headers);
        let request: RestRequest = new RestRequest(this.url(`/asset-groups/${id}`), modifiedHeaders);
        let response: APIResponse = await request.put(requestData, false);
        return response;
    }

    public async addAssetsToAssetGroup(id: number, assetIds: Array<number>): Promise<APIResponse>
    {
        let jsonRequest: StrapiAPIPostRequest = { data: { assetIds: assetIds } };
        let request: RestRequest = new RestRequest(this.url(`/asset-groups/${id}/assets`), this.headers);
        let response: APIResponse = await request.post(jsonRequest);
        return response;
    }

    public async addAssetGroupsToAssetGroup(id: number, groupIds: Array<number>): Promise<APIResponse>
    {
        let jsonRequest: StrapiAPIPostRequest = { data: { groupIds: groupIds } };
        let request: RestRequest = new RestRequest(this.url(`/asset-groups/${id}/groups`), this.headers);
        let response: APIResponse = await request.post(jsonRequest);
        return response;
    }

    public async removeAssetFromGroup(id: number, assetId: number): Promise<APIResponse>
    {
        let jsonRequest: StrapiAPIPostRequest = { data: { assetId: assetId } };
        let request: RestRequest = new RestRequest(this.url(`/asset-groups/${id}/groups/assets/remove`), this.headers);
        let response: APIResponse = await request.post(jsonRequest);
        return response;
    }

    public async removeAssetGroupFromGroup(id: number, groupId: number): Promise<APIResponse>
    {
        let jsonRequest: StrapiAPIPostRequest = { data: { groupId: groupId } };
        let request: RestRequest = new RestRequest(this.url(`/asset-groups/${id}/groups/remove`), this.headers);
        let response: APIResponse = await request.post(jsonRequest);
        return response;
    }
}