import React, { Component } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { App } from "../../../App";
import { Logger } from "../../../diverse/Logger";
import { Page } from "../../../Page";
import { HeaderNoLinks } from "../../components/HeaderNoLinks";
import { Shape, ShapeType } from "../../components/Shape";
import { ErrorMessage } from "../../components/ErrorMessage";

export interface LoginPageProps
{
}

interface LoginPageState 
{
    email: string;
    password: string;
    errorMessage: string;
}

export class LoginPage extends Component<LoginPageProps, LoginPageState>
{
    constructor(props: LoginPageProps)
    {
        super(props);

        this.state = {
            email: "",
            password: "",
            errorMessage: null
        };

        App.I.setPageTitle("Login");
    }

    private async onLogin(e: React.FormEvent<HTMLFormElement>): Promise<void> 
    {
        e.preventDefault();

        try
        {
            await App.I.login(this.state.email, this.state.password);
        }
        catch (error)
        {
            Logger.error(error.message);
            this.setState({
                errorMessage: error.message
            });
        }
    }

    private onEmailChanged(e: React.FormEvent<HTMLInputElement>): void
    {
        this.setState({
            email: e.currentTarget.value
        });
    }

    private onPasswordChanged(e: React.FormEvent<HTMLInputElement>): void
    {
        this.setState({
            password: e.currentTarget.value
        });
    }

    public render(): JSX.Element
    {
        return (
            <>
                <HeaderNoLinks />

                <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", position: "relative", zIndex: 1 }}>
                    <CssBaseline />

                    <Grid xs={12} sm={12} md={12}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "#343759",
                                paddingTop: 4,
                                paddingBottom: 2,
                                paddingLeft: 6,
                                paddingRight: 6,
                                borderRadius: 8,
                                width: "650px",
                                maxWidth: "100%",
                            }}
                        >
                            <Typography component="h1" variant="h1" sx={{ pt: 2 }}>
                                Sign in
                            </Typography>

                            <Typography component="h2" variant="h5" sx={{ mt: 2, mb: 2 }}>
                                Please enter your details to get started.
                            </Typography>

                            <Box component="form" noValidate onSubmit={this.onLogin.bind(this)} sx={{ mt: 1 }}>

                                <Grid container spacing={4}>
                                    <Grid xs={12} md={6}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                            autoFocus
                                            onChange={this.onEmailChanged.bind(this)}
                                            value={this.state.email}
                                        />
                                    </Grid>

                                    <Grid xs={12} md={6}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="current-password"
                                            onChange={this.onPasswordChanged.bind(this)}
                                            value={this.state.password}
                                        />
                                        <Link href={Page.ForgotPassword} variant="body2">
                                            Forgot password?
                                        </Link>
                                    </Grid>
                                </Grid>

                                {this.state.errorMessage ?
                                    <ErrorMessage message={this.state.errorMessage} />
                                    : null
                                }

                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <div>
                                        {/*
                                        <Link href={Page.Register} variant="body2">
                                            {"Don't have an account? Register"}
                                        </Link>
                                        */}
                                    </div>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2, marginLeft: "auto", display: "flex" }}
                                    >
                                        Sign In
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Box>
                <Shape shape={ShapeType.Shape1} width={150} style={{ left: "-3%", top: "35%" }} />
                <Shape shape={ShapeType.Shape2} width={120} style={{ right: "25%", bottom: "-1%" }} />
                <Shape shape={ShapeType.Shape3} width={150} style={{ right: "10%", top: "1%" }} />
            </>
        );
    }
}
