export class BaseAPI
{
    private _baseUrl: string;
    private _headers: { [name: string]: string };

    constructor(baseUrl: string, headers: { [name: string]: string } = {})
    {
        this._baseUrl = baseUrl;
        this._headers = headers;
    }

    protected url(endpoint: string): string
    {
        return this._baseUrl + endpoint;
    }

    public setHeader(name: string, value: string): void
    {
        this._headers[name] = value;
    }

    public get baseUrl(): string { return this._baseUrl; }
    public get headers(): { [name: string]: string } { return this._headers; }
}