import { Campus } from "./Campus";

export class UserGroup
{
    constructor(
        private _id: number,
        private _name: string,
        private _campuses: Array<Campus>,
    )
    {
    }

    public static fromJson(json: any): UserGroup
    {
        let user = new UserGroup(
            json.id,
            json.name,
            json.campus,
        );

        return user;
    }

    public get id(): number { return this._id; }
    public get name(): string { return this._name; }
    public get campuses(): Array<Campus> { return this._campuses; }
}