import { Asset } from "../api/models/Asset";
import { AssetCollection } from "../api/models/AssetCollection";
import { AssetOrGroupSearchResult, SearchResultType } from "../search/AssetOrGroupResult";
import { FuseSearch } from "../search/FuseSearch";

export class SearchService
{
    private fuseSearch: FuseSearch<AssetOrGroupSearchResult>;

    public constructor()
    {
    }

    public createAssetSearch(assets: Array<Asset>)
    {
        let searchData: Array<AssetOrGroupSearchResult> = [];

        assets.map(a => searchData.push({
            id: a.id,
            name: a.name,
            type: SearchResultType.Asset,
            description: a.description,
            keywords: a.keywords
        }));

        this.fuseSearch = new FuseSearch();
        this.fuseSearch.setData(searchData, [
            {
                name: "name",
                weight: 1
            },
            {
                name: "keywords",
                weight: 0.1,
            },
            {
                name: "description",
                weight: 0.1
            }
        ]);
    }

    public createAssetOrGroupSearch(assets: Array<Asset>, groups: Array<AssetCollection>)
    {
        let searchData: Array<AssetOrGroupSearchResult> = [];

        groups.map(g => searchData.push({
            id: g.id,
            name: g.name,
            type: SearchResultType.AssetGroup,
            description: g.description,
            keywords: null
        }));

        assets.map(a => searchData.push({
            id: a.id,
            name: a.name,
            type: SearchResultType.Asset,
            description: a.description,
            keywords: a.keywords
        }));

        this.fuseSearch = new FuseSearch();
        this.fuseSearch.setData(searchData, [
            {
                name: "name",
                weight: 1
            },
            {
                name: "keywords",
                weight: 0.1,
            },
            {
                name: "description",
                weight: 0.1
            }
        ]);

        if (this.searchTerm.length > 0)
        {
            this.search(this.searchTerm);
        }
    }

    public reset(): void 
    {
        this.fuseSearch.resetSearchTerm();
        document.location.reload();
    }

    public search(searchTerm: string): void 
    {
        if (this.fuseSearch == null)
        {
            console.error("Create a search first");
            return;
        }

        this.fuseSearch.search(searchTerm);
    }

    public get searchActive(): boolean { return this.searchTerm?.length > 0; }
    public get searchTerm(): string { return this.fuseSearch.searchTerm; }
    public get results(): Array<AssetOrGroupSearchResult> { return this.fuseSearch.results; }
}