import { Campus } from "./Campus";
import { FileFormat } from "./FileObject";
import { UserGroup } from "./UserGroup";

export class User 
{
    private _userCampuses: Array<Campus> = [];
    private _userGroups: Array<UserGroup> = [];

    constructor(
        private _id: number,
        private _email: string,
        private _username: string,
        private _readyplaymeUserId: number,
        private _readyplaymeGLBUrl: string,
        private _name: string,
        private _xp: number,
        private _avatar: FileFormat
    )
    {
    }

    public static fromJson(json: any): User
    {
        let user = new User(
            json.id,
            json.email,
            json.username,
            json.readyplayme_user_id,
            json.readyplayme_glb_url,
            json.name,
            json.xp,
            json.avatar
        );

        if (json.user_campuses)
        {
            for (let i = 0; i < json.user_campuses.length; i++)
            {
                let c: any = json.user_campuses[i];
                let campus = new Campus(
                    c.id,
                    c.name,
                    c.description,
                    c.file,
                    c.icon,
                    c.primary_color,
                    c.secondary_color,
                    c.opening_environment,
                    c.environments
                );
                user._userCampuses.push(campus);
            }
        }

        if (json.user_groups)
        {
            for (let i = 0; i < json.user_groups.length; i++)
            {
                let g = UserGroup.fromJson(json.user_groups[i]);
                user._userGroups.push(g);
            }
        }

        return user;
    }

    public get id(): number { return this._id; }
    public get email(): string { return this._email; }
    public get name(): string { return this._name; }
    public get username(): string { return this._username; }
    public get readyPlayMeUserId(): number { return this._readyplaymeUserId; }
    public get readyPlayMeGLBUrl(): string { return this._readyplaymeGLBUrl; }
    public get userCampuses(): Array<Campus> { return this._userCampuses; }
    public get userGroups(): Array<UserGroup> { return this._userGroups; }
    public get xp(): number { return this._xp; }
    public get avatar(): FileFormat { return this._avatar; }
}
