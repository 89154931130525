import { Logger } from "../../diverse/Logger";
import { APIResponse } from "./APIResponse";

export class RequestHeader
{
    public name: string;
    public value: string;

    constructor(name: string, value: string)
    {
        this.name = name;
        this.value = value;
    }
}

export class RestRequest
{
    private _url: string;
    private _headers: { [name: string]: string };
    private _data: any;
    private _response!: APIResponse;

    constructor(url: string, headers: { [name: string]: string } = {})
    {
        this._url = url;
        this._headers = headers;
    }

    public async get(): Promise<APIResponse>
    {
        Logger.log("RestRequest ** GET", this._url);
        try
        {
            let response: Response = await fetch(this._url, {
                method: "GET",
                headers: this._headers
            });

            let responseText: string = await response.text();
            this._response = new APIResponse(this, response.status, responseText);
            Logger.log("RestRequest ** response", this._response);
        }
        catch (e)
        {
            this._response = new APIResponse(this, 500, (e as any).text);
        }
        return this._response;
    }

    public async post(data: any, jsonRequest: boolean = true): Promise<APIResponse>
    {
        this._data = data;
        try
        {
            let response: Response = await fetch(this._url, {
                method: "POST",
                headers: this._headers,
                body: jsonRequest ? JSON.stringify(data) : data
            });

            let responseText: string = await response.text();
            this._response = new APIResponse(this, response.status, responseText);
            Logger.log("RestRequest ** response", this._response);
        }
        catch (e)
        {
            Logger.log("RestRequest ** Error with request '" + this._url + "': " + e);
            this._response = new APIResponse(this, 500, (e as any).text);
        }

        return this._response;
    }

    public async put(data: any, jsonRequest: boolean = true): Promise<APIResponse>
    {
        this._data = data;
        Logger.log("RestRequest ** PUT", JSON.stringify(data));
        try
        {
            let response: Response = await fetch(this._url, {
                method: "PUT",
                headers: this._headers,
                body: jsonRequest ? JSON.stringify(data) : data
            });

            let responseText: string = await response.text();
            this._response = new APIResponse(this, response.status, responseText);
            Logger.log("RestRequest ** response", this._response);
        }
        catch (e)
        {
            Logger.log("RestRequest ** Error with request '" + this._url + "': " + e);
            this._response = new APIResponse(this, 500, (e as any).text);
        }

        return this._response;
    }

    public async delete(): Promise<APIResponse>
    {
        Logger.log("RestRequest ** DELETE", this._url);
        try
        {
            let response: Response = await fetch(this._url, {
                method: "DELETE",
                headers: this._headers
            });

            let responseText: string = await response.text();
            this._response = new APIResponse(this, response.status, responseText);
            Logger.log("RestRequest ** response", this._response);
        }
        catch (e)
        {
            Logger.log("RestRequest ** Error with request '" + this._url + "': " + e);
            this._response = new APIResponse(this, 500, (e as any).text);
        }

        return this._response;
    }

    public url(): string { return this._url; }
    public data(): any { return this._data; }
    public response(): APIResponse { return this._response; }
}