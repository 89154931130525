import { AppAPI } from "../api/AppAPI";
import { APIResponse } from "../api/rest/APIResponse";
import { CreateSessionRequest, UpdateSessionRequest } from "../api/SessionAPI";
import { Session } from "../api/models/Session";
import { APIErrorResponseHandler } from "../api/APIErrorResponseHandler";
import { StrapiAPIResponse } from "../api/response/StrapiAPIResponse";
import { Environment } from "../api/models/Environment";
import { AssetCollection } from "../api/models/AssetCollection";

export interface SessionAttendees
{
    attendees: Array<number>;
    presenters: Array<number>;
}

export class SessionService 
{
    public async createSession(request: CreateSessionRequest): Promise<Session>
    {
        const response: APIResponse = await AppAPI.I.sessions.createSession(request);
        APIErrorResponseHandler.validate(response);

        const jsonResponse: StrapiAPIResponse<Session> = response.json.data;
        const session: Session = new Session(
            jsonResponse.id,
            jsonResponse.attributes.name,
            jsonResponse.attributes.description,
            jsonResponse.attributes.start,
            jsonResponse.attributes.end,
            jsonResponse.attributes.campus,
            jsonResponse.attributes.environment,
            jsonResponse.attributes.asset_collections,
        );

        return session;
    }

    /**
     * 
     * @param sessionId 
     * @returns array of user ids by attendees/presenters
     */
    public async getSessionAttendeesById(sessionId: number): Promise<SessionAttendees> 
    {
        const response: APIResponse = await AppAPI.I.sessions.getSessionAttendeesById(sessionId);
        APIErrorResponseHandler.validate(response);
        return response.json;
    }

    public async getSessionById(sessionId: number): Promise<Session> 
    {
        const response: APIResponse = await AppAPI.I.sessions.getSessionById(sessionId);
        APIErrorResponseHandler.validate(response);

        let { id, attributes }: StrapiAPIResponse<Session> = response.json.data;

        let environment = null;
        if (attributes.environment)
        {
            // @ts-ignore
            let envJson: any = attributes.environment.data;
            if (envJson)
            {
                environment = new Environment(
                    envJson.id,
                    envJson.attributes.name,
                    envJson.attributes.assetBundle,
                    envJson.attributes.thumbnail,
                    envJson.attributes.environment_type
                );
            }
        }

        let collections: Array<AssetCollection> = [];
        if (attributes.asset_collections)
        {
            let collectionData = (<any>attributes).asset_collections.data;
            for (let cData of collectionData)
            {
                collections.push(
                    new AssetCollection(
                        cData.id,
                        cData.attributes.name,
                        cData.attributes.description,
                        [],
                        [],
                        cData.attributes.is_public,
                        cData.attributes.is_purchased,
                        [],
                        null,
                        cData.attributes.owner
                    )
                );
            }
        }

        const session = new Session(
            id,
            attributes.name,
            attributes.description,
            attributes.start,
            attributes.end,
            attributes.campus,
            environment,
            collections
        );
        return session;
    }

    public async updateSession(request: UpdateSessionRequest): Promise<Session>
    {
        const response: APIResponse = await AppAPI.I.sessions.updateSession(request);
        APIErrorResponseHandler.validate(response);

        const jsonResponse: StrapiAPIResponse<Session> = response.json.data;
        const session: Session = new Session(
            jsonResponse.id,
            jsonResponse.attributes.name,
            jsonResponse.attributes.description,
            jsonResponse.attributes.start,
            jsonResponse.attributes.end,
            jsonResponse.attributes.campus,
            jsonResponse.attributes.environment,
            jsonResponse.attributes.asset_collections
        );

        return session;
    }

    public async deleteSessionById(id: number): Promise<boolean>
    {
        const response: APIResponse = await AppAPI.I.sessions.deleteSessionById(id);
        APIErrorResponseHandler.validate(response);

        return true;
    }

    public async getSessions(): Promise<Array<Session>>
    {
        const response: APIResponse = await AppAPI.I.sessions.getSessions();
        APIErrorResponseHandler.validate(response);

        const jsonArr: Array<StrapiAPIResponse<Session>> = response.json.data;

        let sessions: Array<Session> = [];
        for (let i = 0; i < jsonArr.length; i++)
        {
            let { id, attributes }: StrapiAPIResponse<Session> = jsonArr[i];

            let session: Session = new Session(
                id, attributes.name,
                attributes.description,
                attributes.start,
                attributes.end,
                attributes.campus,
                attributes.environment,
                attributes.asset_collections
            );

            sessions.push(session);
        }

        return sessions;
    }

    public async getSessionsByCampus(campusId: number): Promise<Array<Session>>
    {
        const response: APIResponse = await AppAPI.I.sessions.getSessionsByCampus(campusId);
        APIErrorResponseHandler.validate(response);

        const jsonResponse = response.json;

        let sessions: Array<Session> = [];
        jsonResponse.map(json => sessions.push(Session.fromJson(json)));

        return sessions;
    }

    public async getAllSessions(): Promise<Array<Session>>
    {
        const response: APIResponse = await AppAPI.I.sessions.getSessions();

        APIErrorResponseHandler.validate(response);

        const jsonArr: Array<Session> = response.json;

        let sessions: Array<Session> = [];
        for (let i = 0; i < jsonArr.length; i++)
        {
            sessions.push(Session.fromJson(jsonArr[i]));
        }

        return sessions;
    }

    public async getSessionsByUser(): Promise<Array<Session>>
    {
        const response: APIResponse = await AppAPI.I.sessions.getSessionsByUser();

        APIErrorResponseHandler.validate(response);

        const jsonArr: Array<Session> = response.json;

        let sessions: Array<Session> = [];
        for (let i = 0; i < jsonArr.length; i++)
        {
            sessions.push(Session.fromJson(jsonArr[i]));
        }

        return sessions;
    }
}
