import React, { Component } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

export interface HeaderNoLinksProps 
{
}

interface HeaderNoLinksState 
{
}

export class HeaderNoLinks extends Component<HeaderNoLinksProps, HeaderNoLinksState>
{
    constructor(props: HeaderNoLinksProps)
    {
        super(props);

        this.state = {
        };
    }

    public render()
    {
        return (
            <Container maxWidth="xl" style={{ zIndex: 2, position: "relative" }}>
                <Box style={{ padding: "20px 0 20px 0", display: "flex", flexDirection: "row", position: "absolute" }}>
                    <Typography
                        variant="h2"
                        noWrap
                        component="a"
                        href="/"
                        title="VR Campus"
                        sx={{
                            mr: 2,
                            color: "inherit",
                            textDecoration: "none",
                            fontWeight: 600,
                        }}
                    >
                        VR Campus
                    </Typography>
                </Box>
            </Container>
        );
    }
}