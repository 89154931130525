import { AppAPI } from "../api/AppAPI";
import { APIResponse } from "../api/rest/APIResponse";
import { APIErrorResponseHandler } from "../api/APIErrorResponseHandler";
import { Asset } from "../api/models/Asset";
import { GetAssetRequest } from "../api/AssetAPI";

export interface GetAssetsResponse 
{
    assets: Array<Asset>;
}

export class AssetService 
{
    public async getAssets(getAssetRequest?: GetAssetRequest): Promise<Array<Asset>>
    {
        const response: APIResponse = await AppAPI.I.assets.getAssets(getAssetRequest);
        const jsonResponse: Array<Asset> = response.json.data;

        let assets: Array<Asset> = [];
        for (let i = 0; i < jsonResponse.length; i++)
        {
            assets.push(Asset.fromJson(jsonResponse[i]));
        }

        return assets;
    }

    public async getAssetById(assetId: number): Promise<Asset> 
    {
        const response: APIResponse = await AppAPI.I.assets.getAssetById(assetId);
        APIErrorResponseHandler.validate(response);

        return Asset.fromJson(response.json);
    }

    public async createAsset(requestData: FormData): Promise<Asset>
    {
        const response: APIResponse = await AppAPI.I.assets.createAsset(requestData);
        APIErrorResponseHandler.validate(response);

        return Asset.fromJson(response.json.data);
    }

    public async updateAsset(id: number, request: FormData): Promise<Asset>
    {
        const response: APIResponse = await AppAPI.I.assets.updateAsset(id, request);
        APIErrorResponseHandler.validate(response);

        return Asset.fromJson(response.json.data);
    }

    public async deleteAssetById(id: number): Promise<boolean>
    {
        const response: APIResponse = await AppAPI.I.assets.deleteAssetById(id);
        APIErrorResponseHandler.validate(response);

        return true;
    }

    public async getUserAssets(): Promise<Array<Asset>>
    {
        const response: APIResponse = await AppAPI.I.assets.getUserAssets();
        APIErrorResponseHandler.validate(response);

        const jsonResponse: Array<any> = response.json;

        let assets: Array<Asset> = [];
        for (let i = 0; i < jsonResponse.length; i++)
        {
            assets.push(Asset.fromJson(jsonResponse[i]));
        }

        return assets;
    }
}