import React, { Component } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { App } from "../../../App";
import Header from "../../components/Header";
import { RouterProps } from "../../RouterProps";
import { Campus } from "../../../api/models/Campus";
import { CampusService } from "../../../services/CampusService";
import { withRouter } from "../../withRouter";
import { PageErrorMessage } from "../../components/PageErrorMessage";
import { BaseBox } from "../../components/BaseBox";
import { BaseAvatar } from "../../components/BaseAvatar";
import hatSVG from "../../assets/images/hat-green.svg";
import { CampusProfileIcon } from "../../components/icons/CampusProfileIcon";
import { Button } from "@mui/material";
import { Page } from "../../../Page";
import { CampusSubscriptionIcon } from "../../components/icons/CampusSubscriptionIcon";
import { CampusUserIcon } from "../../components/icons/CampusUserIcon";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { CampusAvatar } from "./components/CampusAvatar";
import { AssetIcon } from "../../components/icons/AssetIcon";
import { CalendarIcon } from "../../components/icons/CalendarIcon";
import { UserService } from "../../../services/UserService";

export interface CampusDetailPageProps extends RouterProps
{
}

interface CampusDetailPageState 
{
    isAdmin: boolean;
    campus: Campus;
    campuses: Array<Campus>;
    loading: boolean;
    pageError: Error;
}

class CampusDetailPage extends Component<CampusDetailPageProps, CampusDetailPageState>
{
    private campusService: CampusService;

    constructor(props: CampusDetailPageProps)
    {
        super(props);

        this.campusService = new CampusService();

        this.state = {
            isAdmin: false,
            campuses: [],
            campus: null,
            pageError: null,
            loading: true
        };

        App.I.setPageTitle("Campus");
    }

    public componentDidMount(): void
    {
        this.getCampus();
    }

    private async getCampus(): Promise<void> 
    {
        try
        {
            const campusId: number = this.props.router.params.campusId;
            const campus: Campus = await this.campusService.getCampusById(campusId);

            const { isAdmin } = await this.campusService.getCampusUserStatus(campusId, UserService.user.id);

            App.I.setPageTitle(campus.name);

            this.setState({
                campus,
                isAdmin: isAdmin,
                loading: false
            });
        }
        catch (error)
        {
            this.setState({
                pageError: error,
                loading: false
            });
        }
    }

    private onEditCampusClicked(): void
    {
        App.I.changePage(Page.CampusEdit, this.state.campus.id.toString());
    }

    private onManageSubscriptionClicked(): void 
    {
    }

    private onUserManagerClicked(): void 
    {
        App.I.changePage((Page.Campuses + "/" + this.state.campus.id + "/users" as Page));
    }

    private onAssetLibraryClicked(): void 
    {
        App.I.changePage((Page.Campuses + "/" + this.state.campus.id + "/assets" as Page));
    }

    private onSessionsClicked(): void 
    {
        App.I.changePage((Page.Campuses + "/" + this.state.campus.id + "/sessions" as Page));
    }

    private onImportUsersClicked(): void 
    {
        App.I.changePage((Page.Campuses + "/" + this.state.campus.id + "/users/import" as Page));
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Header currentCampus={this.state.campus} />

                <Container component="main" maxWidth="xl">
                    {this.state.loading === false ?
                        <>
                            {this.state.pageError != null ?
                                <PageErrorMessage error={this.state.pageError} />
                                :
                                <Box>
                                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4, mb: 4 }}>
                                        <Link color="inherit" href="/">
                                            Dashboard
                                        </Link>
                                        <Link color="inherit" href="/campuses">
                                            Campuses
                                        </Link>
                                        <Typography color="text.primary">{this.state.campus?.name}</Typography>
                                    </Breadcrumbs>

                                    <BaseBox>
                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid xs={12} md={2}>
                                                    <CampusAvatar file={this.state.campus?.icon} />
                                                </Grid>

                                                <Grid xs={12} md={6}>
                                                    <Box style={{ marginTop: "100px" }}>
                                                        <Typography variant="h3" component="h2" fontWeight={600}>
                                                            {this.state.campus.name}
                                                        </Typography>
                                                        <Typography component="p">
                                                            {this.state.campus.description}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </BaseBox>

                                    <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                                        <Grid xs={12} md={4}>
                                            <Button
                                                disabled={!this.state.isAdmin}
                                                onClick={this.state.isAdmin ? this.onEditCampusClicked.bind(this) : null}
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    padding: 6,
                                                }}
                                                component={Paper}
                                            >
                                                <CampusProfileIcon style={{ fontSize: "4rem", color: "#77CCBE" }} />
                                                <Typography component="h6" variant="h6" align="center" sx={{ mt: 2 }}>
                                                    Edit profile
                                                </Typography>
                                            </Button>
                                        </Grid>

                                        <Grid xs={12} md={4}>
                                            <Button
                                                disabled={/*!this.state.isAdmin*/ true /* disabled until subscription section built */}
                                                onClick={this.state.isAdmin ? this.onManageSubscriptionClicked.bind(this) : null}
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    padding: 6,
                                                }}
                                                component={Paper}
                                            >
                                                <CampusSubscriptionIcon style={{ fontSize: "4rem", color: "#77CCBE" }} />

                                                <Typography component="h6" variant="h6" align="center" sx={{ mt: 2 }}>
                                                    Manage subscription
                                                </Typography>
                                            </Button>
                                        </Grid>

                                        <Grid xs={12} md={4}>
                                            <Button
                                                disabled={!this.state.isAdmin}
                                                onClick={this.state.isAdmin ? this.onUserManagerClicked.bind(this) : null}
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    padding: 6,
                                                }}
                                                component={Paper}
                                            >
                                                <CampusUserIcon style={{ fontSize: "4rem", color: "#77CCBE" }} />
                                                <Typography component="h6" variant="h6" align="center" sx={{ mt: 2 }}>
                                                    User manager
                                                </Typography>
                                            </Button>
                                        </Grid>

                                        <Grid xs={12} md={4}>
                                            <Button
                                                /* All users can edit assets */
                                                onClick={this.onAssetLibraryClicked.bind(this)}
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    padding: 6,
                                                }}
                                                component={Paper}
                                            >
                                                <AssetIcon style={{ fontSize: "4rem", color: "#77CCBE" }} />

                                                <Typography component="h6" variant="h6" align="center" sx={{ mt: 2 }}>
                                                    Asset Library
                                                </Typography>
                                            </Button>
                                        </Grid>


                                        <Grid xs={12} md={4}>
                                            <Button
                                                disabled={!this.state.isAdmin}
                                                onClick={this.state.isAdmin ? this.onSessionsClicked.bind(this) : null}
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    padding: 6,
                                                }}
                                                component={Paper}
                                            >
                                                <CalendarIcon style={{ fontSize: "4rem", color: "#77CCBE" }} />

                                                <Typography component="h6" variant="h6" align="center" sx={{ mt: 2 }}>
                                                    Sessions
                                                </Typography>
                                            </Button>
                                        </Grid>

                                        <Grid xs={12} md={4}>
                                            <Button
                                                disabled={!this.state.isAdmin}
                                                onClick={this.state.isAdmin ? this.onImportUsersClicked.bind(this) : null}
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    padding: 6,
                                                }}
                                                component={Paper}
                                            >
                                                <CampusUserIcon style={{ fontSize: "4rem", color: "#77CCBE" }} />
                                                <Typography component="h6" variant="h6" align="center" sx={{ mt: 2 }}>
                                                    Import Users
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                        </>
                        : null
                    }
                </Container>
            </>
        );
    }
}

export default withRouter<CampusDetailPageProps>(CampusDetailPage);