import { AppAPI } from "../api/AppAPI";
import { User } from "../api/models/User";
import { APIResponse } from "../api/rest/APIResponse";
import { APIErrorResponseHandler } from "../api/APIErrorResponseHandler";
import { Campus } from "../api/models/Campus";
import { UserGroup } from "../api/models/UserGroup";
import { AddUsersToGroupRequest, CreateUserGroupRequest, UpdateUserGroupRequest } from "../api/UserGroupAPI";

export class UserGroupService 
{
    public static user: User;
    public static userCampuses: Array<Campus> = [];

    constructor()
    {
    }

    public async getUserGroup(id: number): Promise<UserGroup>
    {
        const response: APIResponse = await AppAPI.I.userGroups.getUserGroupById(id);
        APIErrorResponseHandler.validate(response);
        const userGroup: UserGroup = UserGroup.fromJson(response.json);
        return userGroup;
    }

    public async getUserGroupsByCampus(campusId: number): Promise<Array<UserGroup>>
    {
        const response: APIResponse = await AppAPI.I.userGroups.getUserGroupsByCampus(campusId);
        APIErrorResponseHandler.validate(response);

        let userGroups: Array<UserGroup> = [];
        for (let i = 0; i < response.json.length; i++)
        {
            userGroups.push(UserGroup.fromJson(response.json[i]));
        }
        return userGroups;
    }

    public async updateUserGroup(request: UpdateUserGroupRequest): Promise<UserGroup>
    {
        const response: APIResponse = await AppAPI.I.userGroups.updateUserGroup(request);
        APIErrorResponseHandler.validate(response);

        const userGroup: UserGroup = UserGroup.fromJson(response.json);
        return userGroup;
    }

    public async createUserGroup(request: CreateUserGroupRequest): Promise<UserGroup>
    {
        const response: APIResponse = await AppAPI.I.userGroups.createUserGroup(request);
        APIErrorResponseHandler.validate(response);

        const userGroup: UserGroup = UserGroup.fromJson(response.json);
        return userGroup;
    }

    public async deleteUserGroup(groupId: number): Promise<void>
    {
        const response: APIResponse = await AppAPI.I.userGroups.deleteUserGroupById(groupId);
        APIErrorResponseHandler.validate(response);
    }

    public async getUsersByGroup(id: number): Promise<Array<User>>
    {
        const response: APIResponse = await AppAPI.I.userGroups.getUsersByGroup(id);
        APIErrorResponseHandler.validate(response);
        const users: Array<User> = response.json;
        return users;
    }

    public async addUsersToGroup(requestData: AddUsersToGroupRequest): Promise<void>
    {
        const response: APIResponse = await AppAPI.I.userGroups.addUsersToGroup(requestData.groupId, requestData);
        APIErrorResponseHandler.validate(response);
    }

    public async removeUserFromGroup(groupId: number, userId: number): Promise<void>
    {
        const response: APIResponse = await AppAPI.I.userGroups.removeUserFromGroup(groupId, userId);
        APIErrorResponseHandler.validate(response);
    }
}