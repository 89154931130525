import React from "react";
import { createRoot, Root } from "react-dom/client";
import { AppAPI } from "./api/AppAPI";
import { MainUI } from "./ui/MainUI";
import { EventDispatcher } from "./diverse/DIEvent";
import { UserService } from "./services/UserService";
import { Page } from "./Page";
import { NotificationEvent } from "./events/NotificationEvent";
import { UserEvent } from "./events/UserEvent";
import { RegisterRequest } from "./api/UserAPI";
import { Config } from "./Config";
import { ShapeAnimation } from "./ShapeAnimation";
import { CampusService } from "./services/CampusService";
import { User } from "./api/models/User";

export class App extends EventDispatcher
{
    private static instance: App;

    private userService: UserService;
    private config: Config;
    private _shapeAnimation: ShapeAnimation;

    private authRoutes: Array<Page> = [
        Page.Login,
        Page.Register,
        Page.ForgotPassword,
        Page.CampusInvite,
        Page.ResetPassword
    ];

    constructor()
    {
        super();

        this.render();

        this._shapeAnimation = new ShapeAnimation(document.getElementById("canvas"));

        App.instance = this;
    }

    public async onUIReady(): Promise<void>
    {
        this.config = new Config();
        await this.config.load();

        new AppAPI(this.config.apiUrl);
        this.userService = new UserService();

        await this.initializeApp();
    }

    private async initializeApp(): Promise<void>
    {
        const isLoggedIn: boolean = await this.userService.checkAuthenticated();

        if (isLoggedIn === true)
        {
            this.dispatchEvent(new UserEvent(UserEvent.LOGGED_IN));
        }
        else 
        {
            let path: string = document.location.pathname;
            if (this.authRoutes.indexOf(path as Page) === -1)
            {
                this.changePage(Page.Login);
            }

            this.dispatchEvent(new UserEvent(UserEvent.LOGGED_OUT));
            App.I.shapeAnimation.start();
        }
    }

    public async login(email: string, password: string): Promise<void>
    {
        await this.userService.login({ identifier: email, password: password });
        this.changePage(Page.Dashboard);
        this.dispatchEvent(new UserEvent(UserEvent.LOGGED_IN));
    }

    public async register(request: RegisterRequest): Promise<void>
    {
        await this.userService.register(request);
        this.changePage(Page.PostRegister);
        this.dispatchEvent(new UserEvent(UserEvent.LOGGED_IN));
    }

    public async registerWithInvite(request: RegisterRequest, inviteUUID: string): Promise<void>
    {
        const user: User = await this.userService.register(request);

        const campusService = new CampusService();
        await campusService.acceptCampusInvite(inviteUUID, user.email);

        this.changePage(Page.Dashboard);
        this.dispatchEvent(new UserEvent(UserEvent.LOGGED_IN));
    }

    public async logout(): Promise<void>
    {
        await this.userService.logout();
        this.changePage(Page.Login);
        this.dispatchEvent(new UserEvent(UserEvent.LOGGED_OUT));
    }

    public changePage(pageRoute: Page, param: string = null): void
    {
        if (document.location.pathname === pageRoute)
        {
            return;
        }

        if (param != null)
        {
            document.location.href = pageRoute + param;
        }
        else 
        {
            document.location.href = pageRoute;
        }
    }

    public setPageTitle(title: string): void
    {
        document.title = `${title} - VR Campus`;
    }

    public sendNotification(message: string): void
    {
        this.dispatchEvent(new NotificationEvent(NotificationEvent.RECEIVED, message));
    }

    private render(): void
    {
        const root: Root = createRoot(document.getElementById("root") || document.createElement("div"));
        root.render(React.createElement(MainUI, { onUIReadyCB: this.onUIReady.bind(this) }, null));
    }

    public static get I(): App { return App.instance; }
    public get shapeAnimation(): ShapeAnimation { return this._shapeAnimation; }
}
