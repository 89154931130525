import { BaseAPI } from "./BaseAPI";
import { Environment, EnvironmentType } from "./models/Environment";
import { APIResponse } from "./rest/APIResponse";
import { RestRequest } from "./rest/RestRequest";

export class EnvironmentAPI extends BaseAPI
{
    constructor(
        baseUrl: string,
        headers: { [name: string]: string } = {}
    )
    {
        super(baseUrl, headers);
    }

    public async getCampusEnvironments(campusId: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/campuses/${campusId}/environments`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }
}
