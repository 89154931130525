import { Box, Grid, Typography } from "@mui/material";
import React, { Component } from "react";
import { User } from "../../api/models/User";
import Button from "@mui/material/Button";
import { BaseBox } from "./BaseBox";
import { ProfileImage } from "./ProfileImage";

export interface ProfileBoxProps
{
    user: User;
    onEditProfileClicked: () => void;
}

interface ProfileBoxState 
{
}

export class ProfileBox extends Component<ProfileBoxProps, ProfileBoxState>
{
    constructor(props: ProfileBoxProps)
    {
        super(props);

        this.state = {
        };
    }

    public render()
    {
        return (
            <BaseBox>
                <ProfileImage user={this.props.user} width={128} height={128} />
                <Typography component="h1" variant="h4">
                    Hello {this.props.user.name ?? this.props.user.email}
                </Typography>

                <Typography component="p">
                    {this.props.user.email}
                </Typography>

                {/*
                <Typography component="h1" variant="h6" sx={{ mt: 2, mb: 0 }}>
                    XP: {this.props.user.xp ?? 0}
                </Typography>
                */}

                <Grid container justifyContent={"space-between"}>
                    <div></div>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={this.props.onEditProfileClicked.bind(this)}
                        sx={{ mt: 3, mb: 2 }}>
                        Edit Profile
                    </Button>
                </Grid>
            </BaseBox>
        );
    }
}