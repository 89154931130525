import { RestRequest } from "./RestRequest";

export class APIResponse
{
    private _request: RestRequest;
    private _status: number;
    private _text: string;

    constructor(request: RestRequest, status: number, text: string)
    {
        this._request = request;
        this._status = status;
        this._text = text;
    }

    public get request(): RestRequest { return this._request; }
    public get success(): boolean { return this._status == 200; }
    public get status(): number { return this._status; }
    public get text(): string { return this._text; }
    public get json(): any { return JSON.parse(this._text); }
}