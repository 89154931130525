import React, { Component } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import { AssetGroupService } from "../../../../services/AssetGroupService";
import { AssetCollection } from "../../../../api/models/AssetCollection";
import { Typography } from "@mui/material";

export interface DeleteAssetGroupFormProps
{
    onDeleted: (assetGroup: AssetCollection) => void;
    assetGroup: AssetCollection;
}

interface DeleteAssetGroupFormState 
{
    errorMessage: string;
    saving: boolean;
}

export class DeleteAssetGroupForm extends Component<DeleteAssetGroupFormProps, DeleteAssetGroupFormState>
{
    constructor(props: DeleteAssetGroupFormProps)
    {
        super(props);

        this.state = {
            errorMessage: null,
            saving: false,
        };
    }

    private async onDeleteConfirmed(): Promise<void>
    {
        const groupService: AssetGroupService = new AssetGroupService();

        try
        {
            const group: AssetCollection = await groupService.delete(this.props.assetGroup.id);
            this.props.onDeleted(group);
        }
        catch (error)
        {
            console.warn("Error", error);

            this.setState({
                errorMessage: error.message,
                saving: false
            });
        }
    }

    public render(): JSX.Element
    {
        return (
            <Grid container component="main">
                <Grid item xs={12} >
                    <Box
                        sx={{
                            my: 4,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Typography sx={{ textAlign: "center", mb: 2 }}>
                            Collection Name: {this.props.assetGroup?.name}
                            <br />
                            Are you sure you want to delete this collection?
                        </Typography>

                        <Button
                            fullWidth
                            disabled={this.state.saving}
                            variant="outlined"
                            color="error"
                            onClick={this.onDeleteConfirmed.bind(this)}
                            sx={{ mt: 2 }}
                        >
                            <DeleteIcon /> Delete asset collection
                        </Button>

                        <Typography sx={{ pt: 2, opacity: 1, fontSize: 13 }} color="error">
                            Note: This will delete the asset collection permanently.<br /> However it <u>will not</u> delete assets contained in this collection.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}