import { Asset } from "./Asset";
import { AssetFile } from "./AssetFile";
import { Campus } from "./Campus";
import { User } from "./User";

export class AssetCollection 
{
    constructor(
        private _id: number,
        private _name: string,
        private _description: string,
        private _assets: Array<Asset>,
        private _campuses: Array<Campus>,
        private _isPublic: boolean,
        private _isPurchased: boolean,
        private _parentAssetGroups: Array<AssetCollection>,
        private _file: AssetFile,
        private _owner: User
    )
    {
    }

    public get id(): number { return this._id; }
    public get name(): string { return this._name; }
    public get description(): string { return this._description; }
    public get isPublic(): boolean { return this._isPublic; }
    public get isPurchased(): boolean { return this._isPurchased; }
    public get assets(): Array<Asset> { return this._assets; }
    public get campuses(): Array<Campus> { return this._campuses; }
    public get parentAssetGroups(): Array<AssetCollection> { return this._parentAssetGroups; }
    public get file(): AssetFile { return this._file; }
    public get owner(): User { return this._owner; }

    public hasPermissionToEdit(user: User): boolean 
    {
        if (this.owner)
        {
            // @ts-ignore - wacky data object from strapi 
            if (this.owner.data)
            {
                // @ts-ignore
                return user.id === this.owner.data.id;
            }

            // Normal flat response
            return user.id === this.owner.id;
        }

        return true;
    }
}
