import React, { Component } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Asset } from "../../../../api/models/Asset";
import { Campus } from "../../../../api/models/Campus";
import { AssetGroupService } from "../../../../services/AssetGroupService";
import { AssetCollection } from "../../../../api/models/AssetCollection";
import CloseIcon from '@mui/icons-material/Close';
import { Spacer } from "../../../components/Spacer";
import { Typography } from "@mui/material";

export interface RemoveAssetFromGroupFormProps
{
    onRemoved: () => void;
    asset: Asset;
    campus: Campus;
    group: AssetCollection;
}

interface RemoveAssetFromGroupFormState
{
    errorMessage: string;
    saving: boolean;
}

export class RemoveAssetFromGroupForm extends Component<RemoveAssetFromGroupFormProps, RemoveAssetFromGroupFormState>
{
    constructor(props: RemoveAssetFromGroupFormProps)
    {
        super(props);

        this.state = {
            errorMessage: null,
            saving: false,
        };
    }

    private async onRemoveConfirmed(): Promise<void>
    {
        const assetGroupService: AssetGroupService = new AssetGroupService();

        try
        {
            await assetGroupService.removeAssetFromGroup(this.props.group.id, this.props.asset.id);
            this.props.onRemoved();
        }
        catch (error)
        {
            console.warn("Error", error);

            this.setState({
                errorMessage: error.message,
                saving: false
            });
        }
    }

    public render(): JSX.Element
    {
        return (
            <Grid container component="main">
                <Grid item xs={12} >
                    <Box
                        sx={{
                            my: 4,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center"
                        }}
                    >
                        <span>Asset Name: <span style={{ fontWeight: "800" }}>{this.props.asset?.name}</span></span>
                        <span>Asset Collection: <span style={{ fontWeight: "800" }}>{this.props.group?.name}</span></span>
                        <Spacer height={10} />

                        Are you sure you want to remove this asset from this collection?

                        <Button
                            fullWidth
                            disabled={this.state.saving}
                            variant="outlined"
                            onClick={this.onRemoveConfirmed.bind(this)}
                            sx={{ mt: 2 }}
                        >
                            <CloseIcon /> Remove asset
                        </Button>
                        <Typography sx={{ pt: 2, fontSize: 13 }} color="error">
                            Note: This will not delete the asset. It will only remove the asset from this collection.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}