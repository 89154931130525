import React, { Component, ReactNode } from "react";
import hatSVG from "../../../assets/images/hat-green.svg";
import { BaseAvatar } from "../../../components/BaseAvatar";
import { FileAttributes, FileFormat } from "../../../../api/models/FileObject";

export interface CampusAvatarProps
{
    file: FileFormat;
    size?: CampusAvatarSize;
}

interface CampusAvatarState 
{
}

export enum CampusAvatarSize 
{
    Small = 50,
    Medium = 80,
    Large = 100
}

export class CampusAvatar extends Component<CampusAvatarProps, CampusAvatarState>
{
    constructor(props: CampusAvatarProps)
    {
        super(props);

        this.state = {
        };
    }

    public render(): JSX.Element
    {
        let thumbnailUrl: string = this.props.file?.url;

        return (
            <BaseAvatar style={{
                marginRight: "auto",
                width: this.props.size ?? 110,
                height: this.props.size ?? 110
            }}>
                <img style={{ width: "100%", height: "100%" }} src={thumbnailUrl ?? hatSVG} />
            </BaseAvatar>
        );
    }
}
