export class Logger 
{
    private static enabled: boolean = false;

    public static log(...optionalParams: any[]): void 
    {
        if (this.enabled)
        {
            console.log(...optionalParams);
        }
    }

    public static warn(...optionalParams: any[]): void 
    {
        if (this.enabled)
        {
            console.warn(...optionalParams);
        }
    }

    public static error(...optionalParams: any[]): void 
    {
        if (this.enabled)
        {
            console.error(...optionalParams);
        }
    }
}