import { SetCampusUserStatusRequest } from "../services/CampusService";
import { APIHelper } from "./APIHelper";
import { BaseAPI } from "./BaseAPI";
import { StrapiAPIPostRequest } from "./response/StrapiAPIPostRequest";
import { APIResponse } from "./rest/APIResponse";
import { RestRequest } from "./rest/RestRequest";

export interface CreateCampusRequest
{
    name: string;
    description: string;
    primary_color: string;
    secondary_color: string;
}

export interface UpdateCampusRequest
{
    id: number;
    name: string;
    description: string;
    primary_color: string;
    secondary_color: string;
    /** user ids */
    admins?: Array<number>;
    users?: Array<number>;
}

export interface RemoveCampusUserRequest 
{
    campusId: number;
    userId: number;
}

export interface AddCampusUsersRequest 
{
    campusId: number;
    userIds: Array<number>;
}

export interface InviteEmailsToCampusRequest 
{
    campusId: number;
    users: Array<InviteCampusUser>;
}

export interface InviteCampusUser
{
    email: string;
    name?: string;
    usergroups?: string; // Comma separated
}

export class CampusAPI extends BaseAPI
{
    constructor(
        baseUrl: string,
        headers: { [name: string]: string } = {}
    )
    {
        super(baseUrl, headers);
    }

    public async getCampuses(): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url("/campuses?populate=file"), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async getCampusUsers(campusId: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/campuses/${campusId}/users`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async getCampusUser(campusId: number, userId: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/campuses/${campusId}/users/${userId}`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async getCampusUserStatus(campusId: number, userId): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/campuses/${campusId}/user-status/${userId}`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async setCampusUserStatus(requestData: SetCampusUserStatusRequest): Promise<APIResponse>
    {
        const jsonRequest: StrapiAPIPostRequest = { data: requestData };
        let request: RestRequest = new RestRequest(
            this.url(`/campuses/${requestData.campusId}/user-status/${requestData.userId}`),
            this.headers
        );
        let response: APIResponse = await request.put(jsonRequest);
        return response;
    }

    public async addCampusUsers(requestData: AddCampusUsersRequest): Promise<APIResponse>
    {
        const jsonRequest: StrapiAPIPostRequest = { data: requestData };
        let request: RestRequest = new RestRequest(this.url(`/campuses/users/add`), this.headers);
        let response: APIResponse = await request.post(jsonRequest);
        return response;
    }

    public async removeCampusUser(requestData: RemoveCampusUserRequest): Promise<APIResponse>
    {
        const jsonRequest: StrapiAPIPostRequest = { data: requestData };
        let request: RestRequest = new RestRequest(this.url(`/campuses/users/remove`), this.headers);
        let response: APIResponse = await request.post(jsonRequest);
        return response;
    }

    public async getCampusById(id: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/campuses/${id}?populate=file`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async createCampus(requestData: FormData): Promise<APIResponse>
    {
        let modifiedHeaders = APIHelper.removeHeader("Content-Type", this.headers)
        let request: RestRequest = new RestRequest(this.url(`/campuses`), modifiedHeaders);
        let response: APIResponse = await request.post(requestData, false);
        return response;
    }

    public async updateCampus(id: number, requestData: FormData): Promise<APIResponse>
    {
        let modifiedHeaders = APIHelper.removeHeader("Content-Type", this.headers)
        let request: RestRequest = new RestRequest(this.url(`/campuses/${id}`), modifiedHeaders);
        let response: APIResponse = await request.put(requestData, false);
        return response;
    }

    public async inviteUsers(requestData: InviteEmailsToCampusRequest): Promise<APIResponse>
    {
        const jsonRequest: StrapiAPIPostRequest = { data: requestData };

        let request: RestRequest = new RestRequest(this.url(`/campus-invite/invite-users`), this.headers);
        let response: APIResponse = await request.post(jsonRequest);
        return response;
    }

    public async getCampusInvite(inviteUUID: string): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/campus-invite/invite/${inviteUUID}`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async getCampusInvites(campusId: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/campuses/${campusId}/invites`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async acceptCampusInvite(inviteUUID: string, userEmail: string): Promise<APIResponse>
    {
        const jsonRequest: StrapiAPIPostRequest = {
            data: {
                hash: inviteUUID,
                userEmail: userEmail
            }
        };

        let request: RestRequest = new RestRequest(this.url(`/campus-invite/invite`), this.headers);
        let response: APIResponse = await request.post(jsonRequest);
        return response;
    }

    public async resendInvite(inviteUUID: string): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/campus-invite/resend-invite`), this.headers);
        let response: APIResponse = await request.post({ hash: inviteUUID });
        return response;
    }

    public async resendInvites(uuids: Array<string>): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/campus-invite/resend-invites`), this.headers);
        let response: APIResponse = await request.post({ hashes: uuids });
        return response;
    }

    public async removeCampusAsset(assetId: number, campusId: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/campuses/assets/remove`), this.headers);
        let response: APIResponse = await request.post({ assetId, campusId }, true);
        return response;
    }

    public async addCampusAsset(assetId: number, campusId: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/campuses/assets/add`), this.headers);
        let response: APIResponse = await request.post({ assetId, campusId }, true);
        return response;
    }

    public async getCampusAssets(campusId: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/campuses/${campusId}/assets`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async getCampusAssetCollections(campusId: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/campuses/${campusId}/asset-collections`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }
}
