import { Component } from "react";
import LaunchIcon from '@mui/icons-material/Launch';
import {ListItemIcon, ListItemText, Typography} from "@mui/material";

export class ViewAction extends Component
{
    public render(): JSX.Element
    {
        return (
          <>
            <ListItemIcon>
              <LaunchIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2">
                  View
              </Typography>
            </ListItemText>
          </>
        );
    }
}