import { ErrorForbidden } from "./errors/ErrorForbidden";
import { ErrorFromServer } from "./errors/ErrorFromServer";
import { ErrorUnauthorized } from "./errors/ErrorUnauthorized";
import { APIResponse } from "./rest/APIResponse";

export class APIErrorResponseHandler
{
    public static validate(response: APIResponse)
    {
        if (response.status === 500)
        {
            throw new Error("Unable to contact server");
        }

        if (response.status === 401)
        {
            throw new ErrorUnauthorized(response);
        }

        if (response.status === 403)
        {
            throw new ErrorForbidden(response);
        }

        if (response.success === false)
        {
            throw new ErrorFromServer(response);
        }
    }
}