import React, { Component } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { App } from "../../../App";
import { Logger } from "../../../diverse/Logger";
import { HeaderNoLinks } from "../../components/HeaderNoLinks";
import { Shape, ShapeType } from "../../components/Shape";
import { ErrorMessage } from "../../components/ErrorMessage";
import { CampusInviteResponse, CampusService } from "../../../services/CampusService";
import { withRouter } from "../../withRouter";
import { RouterProps } from "../../RouterProps";
import { PageErrorMessage } from "../../components/PageErrorMessage";
import { UserService } from "../../../services/UserService";
import { Page } from "../../../Page";

export interface InviteUserPageProps
{
}

interface InviteUserPageState 
{
    email: string;
    errorMessage: string;
    campusName: string;
    inviteUuid: string;
    pageError: Error;
}

export class InviteUserPage extends Component<InviteUserPageProps, InviteUserPageState>
{
    constructor(props: InviteUserPageProps)
    {
        super(props);

        this.state = {
            email: UserService.user.email,
            errorMessage: null,
            campusName: null,
            inviteUuid: null,
            pageError: null,
        };

        App.I.setPageTitle("Invite");
    }

    public componentDidMount(): void
    {
        this.getCampusInvite();
    }

    private async getCampusInvite(): Promise<void>
    {
        try
        {
            const campusService = new CampusService();
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            const response: CampusInviteResponse = await campusService.getCampusInvite(params.id);

            if (UserService.user.email.toLowerCase() != response.email.toLowerCase())
            {
                throw new Error("Invalid invitation");
            }

            this.setState({
                campusName: response.campusName,
                inviteUuid: params.id
            });
        }
        catch (error)
        {
            this.setState({
                pageError: error,
            });
        }
    }

    private async onAcceptClicked(e: React.FormEvent<HTMLFormElement>): Promise<void> 
    {
        e.preventDefault();

        try
        {
            const campusService = new CampusService();
            await campusService.acceptCampusInvite(this.state.inviteUuid, this.state.email);
            App.I.changePage(Page.Dashboard);
        }
        catch (error)
        {
            Logger.error(error.message);
            this.setState({
                errorMessage: error.message
            });
        }
    }

    private onReturnClicked(e): void
    {
        App.I.changePage(Page.Dashboard);
    }

    public render(): JSX.Element
    {
        return (
            <>
                <HeaderNoLinks />

                <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", position: "relative", zIndex: 1 }}>
                    <CssBaseline />

                    <Grid xs={12} sm={12} md={12}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "#343759",
                                paddingTop: 4,
                                paddingBottom: 2,
                                paddingLeft: 6,
                                paddingRight: 6,
                                borderRadius: 8,
                                width: "650px",
                                maxWidth: "100%",
                            }}
                        >

                            {this.state.pageError != null ?
                                <>
                                    <PageErrorMessage error={this.state.pageError} />
                                    <Typography component="p" sx={{ mt: 2, mb: 2 }}>
                                        This invite may have already been accepted, or expired.
                                    </Typography>
                                    <Button
                                        onClick={this.onReturnClicked.bind(this)}
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2, marginLeft: "auto", display: "flex" }}
                                    >
                                        Return
                                    </Button>
                                </>
                                :
                                <>
                                    <Typography component="h1" variant="h1" sx={{ pt: 2 }}>
                                        You've been invited to the {this.state.campusName} campus
                                    </Typography>

                                    <Typography component="p" sx={{ mt: 2, mb: 2 }}>
                                        You may accept the invite below
                                    </Typography>

                                    <Box component="form" noValidate onSubmit={this.onAcceptClicked.bind(this)} sx={{ mt: 1 }}>

                                        {this.state.errorMessage ?
                                            <ErrorMessage message={this.state.errorMessage} />
                                            : null
                                        }

                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <div></div>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2, marginLeft: "auto", display: "flex" }}
                                            >
                                                Accept invite
                                            </Button>
                                        </Box>
                                    </Box>
                                </>
                            }
                        </Box>
                    </Grid>
                </Box>
                <Shape shape={ShapeType.Shape1} width={150} style={{ left: "-3%", top: "35%" }} />
                <Shape shape={ShapeType.Shape2} width={120} style={{ right: "25%", bottom: "-1%" }} />
                <Shape shape={ShapeType.Shape3} width={150} style={{ right: "10%", top: "1%" }} />

            </>
        );
    }
}

export default withRouter<InviteUserPageProps>(InviteUserPage);
