import React, { Component } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Paper } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Asset } from "../../../api/models/Asset";
import { App } from "../../../App";
import { AssetService } from "../../../services/AssetService";
import { PageErrorMessage } from "../../components/PageErrorMessage";
import Header from "../../components/Header";
import { AssetForm } from "./components/AssetForm";
import { AssetList } from "./components/AssetList";
import { AssetIcon } from "../../components/icons/AssetIcon";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Spacer } from "../../components/Spacer";
import { Campus } from "../../../api/models/Campus";
import { RouterProps } from "../../RouterProps";
import { withRouter } from "../../withRouter";
import BasicModal, { ModalSize } from "../../components/Modal";
import AddIcon from '@mui/icons-material/Add';
import { RemoveAssetForm } from "./components/RemoveAssetForm";
import { CampusService } from "../../../services/CampusService";
import { FilterBar, FilterVisibleState } from "./components/FilterBar";
import { SearchService } from "../../../services/SearchService";
import { AssetOrGroupSearchResult } from "../../../search/AssetOrGroupResult";
import { UserService } from "../../../services/UserService";
import { DeleteAssetForm } from "./components/DeleteAssetForm";

export interface AssetsPageProps extends RouterProps
{
}

interface AssetsPageState 
{
    assets: Array<Asset>;
    campuses: Array<Campus>;
    currentCampus: Campus | null;
    selectedAsset: Asset;

    defaultSearchTerm: string;
    searching: boolean;
    searchResults: Array<AssetOrGroupSearchResult>;
    forceSearchResultRefresh: boolean;

    showAssetCreateModal: boolean;
    showAssetDeleteModal: boolean;
    showAssetAddModal: boolean;
    showAssetEditModal: boolean;
    canEdit: boolean;

    filterByVisibility: FilterVisibleState;
    validationError: Error;
    pageError: Error;
    loading: boolean;
}

class AssetsPage extends Component<AssetsPageProps, AssetsPageState>
{
    private assetService: AssetService;
    private userService: UserService;
    private campusService: CampusService;
    private searchService: SearchService;

    constructor(props: AssetsPageProps)
    {
        super(props);

        this.assetService = new AssetService();
        this.userService = new UserService();
        this.campusService = new CampusService();
        this.searchService = new SearchService();

        this.state = {
            assets: [],
            campuses: [],
            currentCampus: null,
            selectedAsset: null,

            defaultSearchTerm: null,
            searching: false,
            searchResults: null,
            forceSearchResultRefresh: false,

            showAssetCreateModal: false,
            showAssetDeleteModal: false,
            showAssetAddModal: false, // seems to be unused
            showAssetEditModal: false,
            canEdit: false,

            filterByVisibility: FilterVisibleState.Both,
            validationError: null,
            pageError: null,
            loading: false
        };

        App.I.setPageTitle("Assets");
    }

    public componentDidMount(): void
    {
        this.init();
    }

    private async init(): Promise<void> 
    {
        try
        {
            let { assets, currentCampus, campuses } = await this.fetchData();

            this.searchService.createAssetSearch(assets);

            this.setState({
                assets: assets,
                currentCampus: currentCampus,
                campuses: campuses,
                defaultSearchTerm: this.searchService.searchTerm,
                searching: this.searchService.searchActive,
                searchResults: this.searchService.results,
                loading: false
            });
        }
        catch (err)
        {
            this.setState({
                pageError: err,
                loading: false
            });
        }
    }

    private async fetchData(): Promise<{ assets: Array<Asset>, currentCampus: Campus, campuses: Array<Campus> }> 
    {
        let { currentCampus, campuses } = await this.getCurrentCampus();

        if (currentCampus == null)
        {
            new Error("Campus not found");
            return;
        }

        let assets: Array<Asset> = await this.campusService.getCampusAssets(currentCampus.id);

        return { assets, currentCampus, campuses };
    }

    private refresh(): void 
    {
        this.setState({
            assets: null,
            loading: true
        },
            () => this.init()
        );
    }

    private async getCurrentCampus(): Promise<{ currentCampus: Campus, campuses: Array<Campus> }> 
    {
        let campusId: number = this.props.router.params.campusId;
        let campuses: Array<Campus> = await this.userService.getUserCampuses();
        let currentCampus: Campus = null;

        for (let i = 0; i < campuses.length; i++)
        {
            if (campuses[i].id == campusId)
            {
                currentCampus = campuses[i];
                break;
            }
        }

        return { campuses: campuses, currentCampus: currentCampus };
    }

    private async fetchAssets(campusId: number, page: number): Promise<Array<Asset>> 
    {
        try
        {
            return await this.campusService.getCampusAssets(campusId);
        }
        catch (error: any)
        {
            this.setState({
                loading: false,
                pageError: error
            });
        }
    }

    private onToggleEditAssetModal(): void 
    {
        this.setState({
            showAssetEditModal: !this.state.showAssetEditModal
        });
    }

    private onToggleAssetCreateModal(): void 
    {
        this.setState({
            showAssetCreateModal: !this.state.showAssetCreateModal
        });
    }

    private onToggleAssetDeleteModal(): void 
    {
        this.setState({
            showAssetDeleteModal: !this.state.showAssetDeleteModal
        });
    }

    private onAssetCreated(asset: Asset): void
    {
        App.I.sendNotification(`Asset  ${asset.name} created`);

        this.setState({
            showAssetCreateModal: false
        });

        this.refresh();
    }

    private onAssetUpdated(): void 
    {
        App.I.sendNotification("Asset succesfully updated");

        this.setState({
            showAssetEditModal: false,
            selectedAsset: null
        });

        this.refresh();
    }

    private onAssetDeleted(): void 
    {
        App.I.sendNotification(`Asset succesfully deleted`);

        this.setState({
            showAssetEditModal: false,
            showAssetDeleteModal: false,
            selectedAsset: null
        });

        this.refresh();
    }

    private onEditAssetClicked(asset: Asset): void 
    {
        this.setState({
            selectedAsset: asset,
            showAssetEditModal: true,
            canEdit: asset.hasPermissionToEdit(UserService.user)
        });
    }

    private onDeleteAssetFromCampusClicked(asset: Asset): void 
    {
        this.setState({
            selectedAsset: asset,
            showAssetDeleteModal: true
        });
    }

    private onSearchTermChanged(searchTerm: string): void 
    {
        let forceSearchResultRefresh: boolean = searchTerm !== this.searchService.searchTerm;

        this.searchService.search(searchTerm);

        this.setState({
            searchResults: this.searchService.results,
            searching: this.searchService.searchActive,
            forceSearchResultRefresh: forceSearchResultRefresh,
        },
            () => this.setState({
                forceSearchResultRefresh: false
            })
        );
    }

    private onClearSearchButtonClicked(): void 
    {
        this.searchService.reset();
    }

    private onVisibilityChanged(state: FilterVisibleState): void 
    {
        this.setState({
            filterByVisibility: state
        });
    }

    private onAssetLibraryClicked(): void
    {
        document.location = "assets";
    }

    private onAssetCollectionClicked(): void
    {
        document.location = "asset-groups";
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Header currentCampus={this.state.currentCampus} />

                <Container component="main" maxWidth="xl">

                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4, mb: 4 }}>
                        <Link color="inherit" href="/">
                            Dashboard
                        </Link>

                        <Link color="inherit" href={"/campuses"}>
                            Campuses
                        </Link>

                        {this.state.currentCampus ?
                            <Link color="inherit" href={"/campuses/" + this.state.currentCampus.id}>
                                {this.state.currentCampus.name}
                            </Link>
                            : null
                        }

                        {this.state.currentCampus ?
                            <Typography color="text.primary">Asset Management</Typography>
                            : null
                        }
                    </Breadcrumbs>

                    {this.state.loading === false ?
                        <>
                            {this.state.pageError != null ?
                                <PageErrorMessage error={this.state.pageError} />
                                :
                                <>
                                    <Grid container spacing={2}>
                                        <Grid xs={12} lg={10}>
                                            <Button variant="text" onClick={this.onAssetLibraryClicked.bind(this)} sx={{ mr: 2, borderBottom: "4px solid #77ccbe", borderRadius: 0 }}>
                                                Asset Library
                                            </Button>
                                            <Button variant="text" onClick={this.onAssetCollectionClicked.bind(this)}>
                                                Asset Collections
                                            </Button>
                                        </Grid>

                                        <Grid xs={12}>
                                            <Typography sx={{ width: "960px", maxWidth: "100%", fontSize: "1.1rem" }}>
                                                Filter through the asset library for {this.state.currentCampus?.name} to discover all the assets currently available to you and your college.
                                                All assets with a shield are your own personal assets, and cannot be viewed or edited by the college.
                                                Head to the <strong style={{ color: "#77ccbe", textDecoration: "underline" }}>
                                                    asset store
                                                </strong> to find more assets for your colleges collection.
                                            </Typography>
                                        </Grid>

                                        <Grid xs={12}>
                                            <Button
                                                variant="contained"
                                                startIcon={<AddIcon />}
                                                onClick={this.onToggleAssetCreateModal.bind(this)}
                                                sx={{ mt: 0, mb: 2, mr: 2 }}
                                            >
                                                Upload new asset
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                        <Grid xs={12} lg={12}>
                                            <Box
                                                component={Paper}
                                                sx={{ display: "flex", alignItems: "center", mb: 2, p: 2, width: "100%" }}
                                            >
                                                <FilterBar
                                                    onClearSearchButtonClicked={this.onClearSearchButtonClicked.bind(this)}
                                                    onSearchTermChanged={this.onSearchTermChanged.bind(this)}
                                                    onVisibilityChanged={this.onVisibilityChanged.bind(this)}
                                                    defaultSearchTerm={this.state.defaultSearchTerm}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={0}>
                                        <Grid xs={12} spacing={0}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    p: 2
                                                }}
                                                component={Paper}
                                            >
                                                {this.state.searching ? "Filter results" : ""}

                                                {this.state.forceSearchResultRefresh !== true ?
                                                    <AssetList
                                                        assets={this.state.assets}
                                                        onEditAsset={this.onEditAssetClicked.bind(this)}
                                                        onDeleteAsset={this.onDeleteAssetFromCampusClicked.bind(this)}
                                                        searchResults={this.state.searchResults}
                                                        filter={this.state.filterByVisibility}
                                                        searching={this.state.searching}
                                                    />
                                                    : null
                                                }
                                                <Spacer height={15} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </>
                        : null
                    }

                    {this.state.showAssetDeleteModal ?
                        <BasicModal onClose={this.onToggleAssetDeleteModal.bind(this)} size={ModalSize.Medium}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Typography component="h2" variant="h4" align="center">
                                    Confirm Asset Deletion
                                </Typography>

                                <DeleteAssetForm
                                    asset={this.state.selectedAsset}
                                    onDeleted={this.onAssetDeleted.bind(this)}
                                />
                            </Box>
                        </BasicModal>
                        : null
                    }

                    {this.state.showAssetEditModal ?
                        <BasicModal onClose={this.onToggleEditAssetModal.bind(this)} size={ModalSize.Large}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Typography component="h2" variant="h4" align="center">
                                    {this.state.canEdit ? "Edit" : "View"} asset
                                </Typography>

                                <Typography variant="body2">
                                {this.state.canEdit ? "Editing" : "Viewing"} asset: <strong>{this.state.selectedAsset.name}</strong>
                                </Typography>

                                <AssetForm
                                    canEdit={this.state.canEdit}
                                    campus={this.state.currentCampus}
                                    asset={this.state.selectedAsset}
                                    campuses={this.state.campuses}
                                    onAssetUpdated={this.onAssetUpdated.bind(this)}
                                    onAssetDeleted={this.onAssetDeleted.bind(this)}
                                />
                            </Box>
                        </BasicModal>
                        : null
                    }

                    {this.state.showAssetCreateModal ?
                        <BasicModal onClose={this.onToggleAssetCreateModal.bind(this)} size={ModalSize.Large}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Typography component="h2" variant="h4" align="center">
                                    Upload a new asset
                                </Typography>

                                <AssetForm
                                    onAssetCreated={this.onAssetCreated.bind(this)}
                                    campuses={this.state.campuses}
                                    campus={this.state.currentCampus}
                                />
                            </Box>
                        </BasicModal>
                        : null
                    }
                </Container>
            </>
        );
    }
}

export default withRouter(AssetsPage);
