import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import { Box, ListItem, ListItemIcon, ListItemText, MenuItem, Tooltip } from "@mui/material";
import { Asset } from "../../../../api/models/Asset";
import { AssetThumbnail } from "./AssetThumbnail";
import DropdownMenu from "../../../components/DropdownMenu";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PublicIcon from '@mui/icons-material/Public';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { EditAction } from "./actions/EditAction";
import { ViewAction } from "./actions/ViewAction";

export interface AssetItemProps
{
    asset: Asset;
    canEdit: boolean;
    onEditAsset?: () => void;
    onRemoveAsset?: () => void;
    onDeleteAsset?: () => void;
    canEditGroup?: boolean;
}

interface AssetItemState 
{
    dropdownAvailable: boolean;
}

export class AssetItem extends Component<AssetItemProps, AssetItemState>
{
    constructor(props: AssetItemProps)
    {
        super(props);

        this.state = {
            dropdownAvailable: true
        };
    }

    private onCloseDropdown(): void 
    {
        this.setState({
            dropdownAvailable: false,
        }, () =>
            this.setState({
                dropdownAvailable: true
            })
        );
    }

    private onEditAsset(): void 
    {
        this.onCloseDropdown();
        this.props.onEditAsset();
    }

    private onRemoveAsset(): void 
    {
        this.onCloseDropdown();
        this.props.onRemoveAsset();
    }

    private onDeleteAsset(): void 
    {
        this.onCloseDropdown();
        this.props.onDeleteAsset();
    }

    public render(): JSX.Element
    {
        return (
            <ListItem style={{ display: "flex", flexGrow: 1, height: "100%" }} sx={{ p: 1 }}>
                <Box style={{
                    flexDirection: "column",
                    background: "#666286",
                    borderRadius: "10px",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-around",
                    cursor: "pointer",
                    transition: "ease all 250ms",
                    opacity: 0.85
                }}
                    sx={{
                        padding: 0,
                        "&:hover": {
                            boxShadow: "0px 3px 5px 0px #333550",
                            opacity: "1!important"
                        }
                    }}
                >
                    <Box
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center"
                        }}
                        onClick={this.props.onEditAsset.bind(this)}
                    >
                        <AssetThumbnail asset={this.props.asset} />
                    </Box>

                    <Box sx={{ position: "absolute", top: 20, right: 20 }}>

                        {this.state.dropdownAvailable ?
                            <DropdownMenu>
                                {this.props.onEditAsset ?
                                    <MenuItem onClick={this.onEditAsset.bind(this)}>
                                        {this.props.canEdit ? <EditAction /> : <ViewAction />}
                                    </MenuItem>
                                    : null
                                }

                                {this.props.canEditGroup && this.props.onRemoveAsset ?
                                    <MenuItem onClick={this.onRemoveAsset.bind(this)}>
                                        <ListItemIcon>
                                            <CloseIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body2">
                                                Remove
                                            </Typography>
                                        </ListItemText>
                                    </MenuItem>
                                    : null
                                }

                                {this.props.canEdit && this.props.onDeleteAsset ?
                                    <MenuItem onClick={this.onDeleteAsset.bind(this)}>
                                        <ListItemIcon>
                                            <DeleteIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body2">
                                                Delete
                                            </Typography>
                                        </ListItemText>
                                    </MenuItem>
                                    : null
                                }
                            </DropdownMenu>
                            : null
                        }
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: "#1e2146",
                            margin: 0,
                            padding: 0,
                            marginTop: "auto",
                            width: "100%",
                            borderRadius: "0  0 10px 10px",
                            position: "relative"
                        }}
                        onClick={this.props.onEditAsset.bind(this)}>
                        <Typography variant="body2" style={{
                            position: "absolute",
                            right: 5,
                            top: 5,
                            fontSize: "9px",
                            color: "#a8a9bf"
                        }}>
                            Asset
                        </Typography>

                        <Typography variant="h6" component="h2" sx={{ padding: 2, wordBreak: "break-word" }}>
                            {this.props.asset.name}
                        </Typography>

                        <Typography component="p" sx={{ pl: 2, pr: 2, fontSize: "0.8rem", wordBreak: "break-word" }}>
                            {this.props.asset.description}
                        </Typography>

                        <Box sx={{ pb: 2 }}></Box>

                        <Box sx={{ display: "flex", justifyContent: "space-between", fontSize: "0.8rem" }}>
                            <Box sx={{ display: "flex", alignItems: "center", color: "silver", fontWeight: "bold", p: 2, pb: 0 }}>
                                {
                                    this.props.asset.isPurchased ?
                                        <Tooltip title="Purchased: This asset was purchased via the asset store">
                                            <Box style={{ display: "flex", alignItems: "center", color: "#77ccbe", margin: 0 }}>
                                                <VisibilityOffIcon sx={{ fontSize: "14px", mr: 0.5 }} /> Purchased
                                            </Box>
                                        </Tooltip>
                                        : !this.props.asset.isPurchased && this.props.asset.isPublic ?
                                            <Tooltip title="Public: This asset is visible to everyone in the campus">
                                                <Box style={{ display: "flex", alignItems: "center", color: "#77ccbe", margin: 0 }}>
                                                    <PublicIcon sx={{ fontSize: "14px", mr: 0.5 }} /> Public
                                                </Box>
                                            </Tooltip>
                                            :
                                            <Tooltip title="Private: This asset is only visible to you in the campus">
                                                <Box style={{ display: "flex", alignItems: "center", color: "#77ccbe", margin: 0 }}>
                                                    <VisibilityOffIcon sx={{ fontSize: "14px", mr: 0.5 }} /> Private
                                                </Box>
                                            </Tooltip>
                                }
                            </Box>
                            <Box sx={{ p: 2 }}>
                                <InsertDriveFileIcon sx={{ fontSize: "14px", fill: "#77CCBE" }} />
                            </Box>
                        </Box>
                        <Typography sx={{ fontSize: "14px", color: "#77ccbe", pl: 2, pb: 1, overflow: "hidden", textOverflow: "ellipsis" }}>
                            {this.props.asset.owner?.name ?? "Name not provided"}
                        </Typography>
                    </Box>
                </Box>
            </ListItem >
        );
    }
}
