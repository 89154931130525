import { AppAPI } from "../api/AppAPI";
import { APIResponse } from "../api/rest/APIResponse";
import { APIErrorResponseHandler } from "../api/APIErrorResponseHandler";
import { StrapiAPIResponse } from "../api/response/StrapiAPIResponse";
import { StrapiAPIMetaResponse } from "../api/response/StrapiAPIMetaResponse";
import { StrapiAPIPagination } from "../api/response/StrapiAPIPagination";
import { AssetCollection } from "../api/models/AssetCollection";
import { GetAssetGroupRequest } from "../api/AssetGroupAPI";

export interface GetAssetGroupResponse 
{
    groups: Array<AssetCollection>;
    pagination: StrapiAPIPagination;
}

export class AssetGroupService 
{
    public async getCampusAssetGroups(getGroupRequest?: GetAssetGroupRequest): Promise<GetAssetGroupResponse>
    {
        const response: APIResponse = await AppAPI.I.assetGroups.getCampusAssetGroups(getGroupRequest);
        const responseGroups: Array<StrapiAPIResponse<AssetCollection>> = response.json.data;
        const jsonPagination: StrapiAPIMetaResponse = response.json.meta;

        let groups: Array<AssetCollection> = [];
        for (let i = 0; i < responseGroups.length; i++)
        {
            let jsonResponse: any = responseGroups[i];
            let group: AssetCollection = new AssetCollection(
                jsonResponse.id,
                jsonResponse.name,
                jsonResponse.description,
                jsonResponse.assets,
                jsonResponse.campuses,
                jsonResponse.is_public,
                jsonResponse.is_purchased,
                jsonResponse.parent_asset_groups,
                jsonResponse.file,
                jsonResponse.owner
            );
            groups.push(group);
        }

        return {
            groups: groups,
            pagination: jsonPagination.pagination
        };
    }

    public async getAllCampusAssetGroups(campusId: number): Promise<Array<AssetCollection>>
    {
        const response: APIResponse = await AppAPI.I.assetGroups.getAllCampusAssetGroups(campusId);
        const responseGroups: Array<AssetCollection> = response.json;

        let groups: Array<AssetCollection> = [];
        for (let i = 0; i < responseGroups.length; i++)
        {
            let jsonResponse: any = responseGroups[i];
            let group: AssetCollection = new AssetCollection(
                jsonResponse.id,
                jsonResponse.name,
                jsonResponse.description,
                jsonResponse.assets,
                jsonResponse.campuses,
                jsonResponse.is_public,
                jsonResponse.is_purchased,
                jsonResponse.parent_asset_groups,
                jsonResponse.file,
                jsonResponse.owner
            );
            groups.push(group);
        }

        return groups;
    }

    public async create(requestData: FormData): Promise<AssetCollection>
    {
        const response: APIResponse = await AppAPI.I.assetGroups.create(requestData);
        APIErrorResponseHandler.validate(response);

        const jsonResponse: StrapiAPIResponse<any> = response.json.data;
        const group: AssetCollection = new AssetCollection(
            jsonResponse.id,
            jsonResponse.attributes.name,
            jsonResponse.attributes.description,
            jsonResponse.attributes.assets,
            jsonResponse.attributes.campuses,
            jsonResponse.attributes.is_public,
            jsonResponse.attributes.is_purchased,
            jsonResponse.attributes.parent_asset_groups,
            jsonResponse.attributes.file,
            jsonResponse.attributes.owner
        );

        return group;
    }

    public async findById(assetId: number, campusId: number): Promise<AssetCollection> 
    {
        const response: APIResponse = await AppAPI.I.assetGroups.findById(assetId, campusId);
        APIErrorResponseHandler.validate(response);

        const jsonResponse: any = response.json;
        const group: AssetCollection = new AssetCollection(
            jsonResponse.id,
            jsonResponse.name,
            jsonResponse.description,
            jsonResponse.assets,
            jsonResponse.campuses,
            jsonResponse.is_public,
            jsonResponse.is_purchased,
            jsonResponse.parent_asset_groups,
            jsonResponse.file,
            jsonResponse.owner
        );

        return group;
    }

    public async delete(id: number): Promise<AssetCollection>
    {
        const response: APIResponse = await AppAPI.I.assetGroups.delete(id);
        APIErrorResponseHandler.validate(response);

        const jsonResponse: StrapiAPIResponse<any> = response.json.data;
        console.log("Delete Response", jsonResponse);

        const group: AssetCollection = new AssetCollection(
            jsonResponse.id,
            jsonResponse.attributes.name,
            jsonResponse.attributes.description,
            jsonResponse.attributes.assets,
            jsonResponse.attributes.campuses,
            jsonResponse.attributes.is_public,
            jsonResponse.attributes.is_purchased,
            jsonResponse.attributes.parent_asset_groups,
            jsonResponse.attributes.file,
            jsonResponse.attributes.owner
        );

        console.log("Group obj", group);

        return group;
    }

    public async update(id: number, request: FormData): Promise<AssetCollection>
    {
        const response: APIResponse = await AppAPI.I.assetGroups.update(id, request);
        APIErrorResponseHandler.validate(response);

        const jsonResponse: StrapiAPIResponse<any> = response.json.data;
        const group: AssetCollection = new AssetCollection(
            jsonResponse.id,
            jsonResponse.attributes.name,
            jsonResponse.attributes.description,
            jsonResponse.attributes.assets,
            jsonResponse.attributes.campuses,
            jsonResponse.attributes.is_public,
            jsonResponse.attributes.is_purchased,
            jsonResponse.attributes.parent_asset_groups,
            jsonResponse.attributes.file,
            jsonResponse.attributes.owner
        );

        return group;
    }

    public async addAssetsToAssetGroup(id: number, assetIds: Array<number>): Promise<AssetCollection>
    {
        const response: APIResponse = await AppAPI.I.assetGroups.addAssetsToAssetGroup(id, assetIds);
        APIErrorResponseHandler.validate(response);

        const jsonResponse: StrapiAPIResponse<any> = response.json.data;
        const group: AssetCollection = new AssetCollection(
            jsonResponse.id,
            jsonResponse.attributes.name,
            jsonResponse.attributes.description,
            jsonResponse.attributes.assets,
            jsonResponse.attributes.campuses,
            jsonResponse.attributes.is_public,
            jsonResponse.attributes.is_purchased,
            jsonResponse.attributes.parent_asset_groups,
            jsonResponse.attributes.file,
            jsonResponse.attributes.owner
        );

        return group;
    }

    public async addAssetGroupsToAssetGroup(id: number, assetGroupIds: Array<number>): Promise<void>
    {
        const response: APIResponse = await AppAPI.I.assetGroups.addAssetGroupsToAssetGroup(id, assetGroupIds);
        APIErrorResponseHandler.validate(response);
    }

    public async removeAssetFromGroup(id: number, assetId: number): Promise<void>
    {
        const response: APIResponse = await AppAPI.I.assetGroups.removeAssetFromGroup(id, assetId);
        APIErrorResponseHandler.validate(response);
    }

    public async removeAssetGroupFromGroup(id: number, groupId: number): Promise<void>
    {
        console.log("removeAssetGroupFromGroup", id, groupId);
        const response: APIResponse = await AppAPI.I.assetGroups.removeAssetGroupFromGroup(id, groupId);
        APIErrorResponseHandler.validate(response);
    }
}
