import React, { Component } from "react";
import { RouterProps } from "../../RouterProps";
import { Campus } from "../../../api/models/Campus";
import { withRouter } from "../../withRouter";
import { UserService } from "../../../services/UserService";
import { App } from "../../../App";
import Header from "../../components/Header";
import Container from "@mui/material/Container";
import { Breadcrumbs, Button, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { BaseBox } from "../../components/BaseBox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { CampusAvatar } from "./components/CampusAvatar";
import { CampusAdminRoute } from "./components/CampusAdminRoute";
import { CampusInvite } from "../../../api/models/CampusInvite";
import { CampusService } from "../../../services/CampusService";

export interface CampusInvitedUsersPageProps extends RouterProps
{
}

interface CampusInvitedUsersPageState 
{
    currentCampus: Campus;
    campusInvites: Array<CampusInvite>;
    loading: boolean;
    pageError: Error;
}

class CampusInvitedUsersPage extends Component<CampusInvitedUsersPageProps, CampusInvitedUsersPageState>
{
    private userService: UserService;
    private campusService: CampusService;

    constructor(props: CampusInvitedUsersPageProps)
    {
        super(props);

        this.userService = new UserService();
        this.campusService = new CampusService();

        this.state = {
            currentCampus: null,
            campusInvites: [],
            pageError: null,
            loading: true
        };

        App.I.setPageTitle("Campus Invited Users");
    }

    public componentDidMount(): void
    {
        try
        {
            this.fetchData();
        }
        catch (err)
        {
            this.setState({
                pageError: err
            });
        }
    }

    private async fetchData(): Promise<void> 
    {
        try
        {
            let currentCampus = await this.getCurrentCampus();
            if (currentCampus == null)
            {
                new Error("Campus not found");
                return;
            }

            let invites: Array<CampusInvite> = await this.getCampusInvites(currentCampus.id);

            this.setState({
                currentCampus,
                campusInvites: invites,
                loading: false
            });
        }
        catch (err)
        {
            this.setState({
                pageError: err
            });
        }
    }

    private async getCurrentCampus(): Promise<Campus>
    {
        let campusId: number = this.props.router.params.campusId;
        let campuses: Array<Campus> = await this.userService.getUserCampuses();
        let currentCampus: Campus = null;

        for (let i = 0; i < campuses.length; i++)
        {
            if (campuses[i].id == campusId)
            {
                currentCampus = campuses[i];
                break;
            }
        }

        return currentCampus;
    }

    private async getCampusInvites(campusId: number): Promise<Array<CampusInvite>>
    {
        let invites: Array<CampusInvite> = [];
        try
        {
            invites = await this.campusService.getCampusInvites(campusId);
        }
        catch (e)
        {
            console.log("CampusInvitedUsersPage ** Error retrieving invites:", e.message);
        }
        return invites;
    }

    private onUsersClicked(): void
    {
        document.location.href = `/campuses/${this.state.currentCampus.id}/users`;
    }

    private onUserGroupsClicked(): void 
    {
        document.location.href = `/campuses/${this.state.currentCampus.id}/user-groups`;
    }

    private onInviteAllClicked(): void
    {
        this.sendAllInvites();
    }

    private onSendInviteClicked(invite: CampusInvite): void
    {
        this.sendInvite(invite);
    }

    private async sendInvite(invite: CampusInvite): Promise<void>
    {
        await this.campusService.resendInvite(invite.hash);
        document.location.reload();
    }

    private async sendAllInvites(): Promise<void>
    {
        let hashes: Array<string> = this.state.campusInvites.map(i => i.hash);
        await this.campusService.resendInvites(hashes);
        document.location.reload();
    }

    public render(): React.ReactElement
    {
        return (
            <>
                <Header />

                <Container component="main" maxWidth="xl">

                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4, mb: 4 }}>
                        <Link color="inherit" href="/">
                            Dashboard
                        </Link>
                        <Link color="inherit" href="/campuses">
                            Campuses
                        </Link>
                        <Link color="inherit" href={`/campuses/${this.state.currentCampus?.id}`}>
                            {this.state.currentCampus?.name}
                        </Link>
                        <Link color="inherit" href={`/campuses/${this.state.currentCampus?.id}/users`}>
                            User Manager
                        </Link>
                        <Typography color="text.primary">Invited users</Typography>
                    </Breadcrumbs>

                    <BaseBox style={{ marginBottom: "1rem" }}>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid xs={12} md={2}>
                                    <CampusAvatar file={this.state.currentCampus?.icon} />
                                </Grid>

                                <Grid xs={12} md={6}>
                                    <Box style={{ marginTop: "100px" }}>
                                        <Typography variant="h3" component="h2" fontWeight={600}>
                                            Invited Users Manager
                                        </Typography>
                                        <Typography sx={{ mt: 1 }} component="p">
                                            {this.state.currentCampus?.name}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </BaseBox>

                    <Grid xs={12} lg={12} sx={{ mb: 2 }}>
                        <Button variant="text" onClick={this.onUsersClicked.bind(this)}>
                            Users
                        </Button>
                        <Button variant="text" onClick={this.onUserGroupsClicked.bind(this)}
                            sx={{ ml: 1 }}>
                            User groups
                        </Button>
                        <Button variant="text" onClick={null}
                            sx={{ pt: 1, ml: 2, borderBottom: "4px solid #77ccbe", borderRadius: 0 }}>
                            Invited users
                        </Button>
                    </Grid>

                    <Box>
                        <Grid container spacing={2}>
                            {
                                this.state.loading ? null :
                                    <CampusAdminRoute campus={this.state.currentCampus}>
                                        <Grid xs={12} md={12}>
                                            <Box component={Paper}>
                                                <Typography component="h6" variant="h6" sx={{ pl: 3, pt: 4 }}>
                                                    Invited users
                                                </Typography>
                                                {
                                                    this.state.campusInvites?.length > 0 ?

                                                        <TableContainer component={Paper} sx={{ px: 3, py: 4 }}>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Name</TableCell>
                                                                        <TableCell>
                                                                            <Button variant="contained" sx={{ py: 0.25 }} onClick={this.onInviteAllClicked.bind(this)}>
                                                                                Invite all
                                                                            </Button>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {
                                                                        this.state.campusInvites?.map((invite: CampusInvite) =>
                                                                            <TableRow key={invite.hash}>
                                                                                <TableCell>
                                                                                    {invite.user_email}
                                                                                </TableCell>
                                                                                <TableCell sx={{ py: 1, textAlign: "right", width: "1%", whiteSpace: "nowrap" }}>
                                                                                    <Button variant="contained" sx={{ py: 0.25 }} onClick={this.onSendInviteClicked.bind(this, invite)}>
                                                                                        {invite.has_emailed ? "Resend invite" : "Send invite"}
                                                                                    </Button>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        :
                                                        <Typography component="p" sx={{ p: 2 }}>
                                                            No invites
                                                        </Typography>
                                                }
                                            </Box>
                                        </Grid>
                                    </CampusAdminRoute>
                            }
                        </Grid>
                    </Box>
                </Container>
            </>
        );
    }
}

export default withRouter<CampusInvitedUsersPageProps>(CampusInvitedUsersPage);
