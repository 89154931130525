import { FileFormat } from "./FileObject";

export enum EnvironmentType
{
    // As per strapi 
    Home = "Home",
    Session = "Session"
}

export class Environment 
{
    constructor(
        private _id: number,
        private _name: string,
        private _assetBundle: FileFormat,
        private _thumbnail: FileFormat,
        private _type: EnvironmentType
    )
    {
    }

    public static fromJson(json: any): Environment
    {
        return new Environment(
            json.id,
            json.name,
            json.assetBundle,
            json.thumbnail,
            json.environment_type
        );
    }

    public get id(): number { return this._id; }
    public get name(): string { return this._name; }
    public get assetBundle(): FileFormat { return this._assetBundle; }
    public get thumbnail(): FileFormat { return this._thumbnail; }
    public get type(): EnvironmentType { return this._type; }
}