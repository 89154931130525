import React, { Component } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { App } from "../../../App";
import { Logger } from "../../../diverse/Logger";
import { Page } from "../../../Page";
import { HeaderNoLinks } from "../../components/HeaderNoLinks";
import { Shape, ShapeType } from "../../components/Shape";
import { ErrorMessage } from "../../components/ErrorMessage";
import { CampusInviteResponse, CampusService } from "../../../services/CampusService";
import { withRouter } from "../../withRouter";
import { RouterProps } from "../../RouterProps";
import { PageErrorMessage } from "../../components/PageErrorMessage";

export interface InvitePageProps extends RouterProps
{
}

interface InvitePageState 
{
    name: string;
    email: string;
    password: string;
    passwordConfirmed: string;
    errorMessage: string;
    campusName: string;
    inviteUuid: string;
    pageError: Error;
}

export class InvitePage extends Component<InvitePageProps, InvitePageState>
{
    constructor(props: InvitePageProps)
    {
        super(props);

        this.state = {
            name: "",
            email: "",
            password: "",
            passwordConfirmed: "",
            errorMessage: null,
            campusName: null,
            inviteUuid: null,
            pageError: null,
        };

        App.I.setPageTitle("Invite");
    }

    public componentDidMount(): void
    {
        this.getCampusInvite();
    }

    private async getCampusInvite(): Promise<void>
    {
        try
        {
            const campusService = new CampusService();
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            const response: CampusInviteResponse = await campusService.getCampusInvite(params.id);

            this.setState({
                name: response.userName,
                email: response.email,
                campusName: response.campusName,
                inviteUuid: params.id
            });
        }
        catch (error)
        {
            this.setState({
                pageError: error,
            });
        }
    }

    private async onRegister(e: React.FormEvent<HTMLFormElement>): Promise<void> 
    {
        e.preventDefault();

        try
        {
            const registerRequest = {
                name: this.state.name,
                username: this.state.email,
                email: this.state.email,
                password: this.state.password,
                passwordConfirmed: this.state.passwordConfirmed
            };

            await App.I.registerWithInvite(registerRequest, this.state.inviteUuid);
        }
        catch (error)
        {
            Logger.error(error.message);
            this.setState({
                errorMessage: error.message
            });
        }
    }

    private onNameChanged(e: React.FormEvent<HTMLInputElement>): void
    {
        this.setState({
            name: e.currentTarget.value
        });
    }

    private onEmailChanged(e: React.FormEvent<HTMLInputElement>): void
    {
        this.setState({
            email: e.currentTarget.value
        });
    }

    private onPasswordChanged(e: React.FormEvent<HTMLInputElement>): void
    {
        this.setState({
            password: e.currentTarget.value
        });
    }

    private onPasswordConfirmChanged(e: React.FormEvent<HTMLInputElement>): void
    {
        this.setState({
            passwordConfirmed: e.currentTarget.value
        });
    }

    public render(): JSX.Element
    {
        return (
            <>
                <HeaderNoLinks />

                <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", position: "relative", zIndex: 1 }}>
                    <CssBaseline />

                    <Grid xs={12} sm={12} md={12}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "#343759",
                                paddingTop: 4,
                                paddingBottom: 2,
                                paddingLeft: 6,
                                paddingRight: 6,
                                borderRadius: 8,
                                width: "650px",
                                maxWidth: "100%",
                            }}
                        >

                            {this.state.pageError != null ?
                                <PageErrorMessage error={this.state.pageError} />
                                :
                                <>
                                    <Typography component="h1" variant="h1" sx={{ pt: 2 }}>
                                        You've been invited to register at {this.state.campusName}
                                    </Typography>

                                    <Typography component="h2" variant="h5" sx={{ mt: 2, mb: 2 }}>
                                        Please enter your details to get started.
                                    </Typography>

                                    <Box component="form" noValidate onSubmit={this.onRegister.bind(this)} sx={{ mt: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid xs={12} md={6}>
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    id="name"
                                                    label="Name"
                                                    name="name"
                                                    autoComplete="name"
                                                    autoFocus
                                                    onChange={this.onNameChanged.bind(this)}
                                                    value={this.state.name}
                                                />
                                            </Grid>

                                            <Grid xs={12} md={6}>
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    id="email"
                                                    label="Email Address"
                                                    name="email"
                                                    autoComplete="email"
                                                    onChange={this.onEmailChanged.bind(this)}
                                                    value={this.state.email}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid xs={12} md={6}>
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    name="password"
                                                    label="Password"
                                                    type="password"
                                                    id="password"
                                                    autoComplete="current-password"
                                                    onChange={this.onPasswordChanged.bind(this)}
                                                    value={this.state.password}
                                                />
                                            </Grid>

                                            <Grid xs={12} md={6}>
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    name="password_confirmed"
                                                    label="Confirm password"
                                                    type="password"
                                                    id="passwordConfirmed"
                                                    autoComplete="password-confirmed"
                                                    onChange={this.onPasswordConfirmChanged.bind(this)}
                                                    value={this.state.passwordConfirmed}
                                                />
                                            </Grid>
                                        </Grid>

                                        {this.state.errorMessage ?
                                            <ErrorMessage message={this.state.errorMessage} />
                                            : null
                                        }

                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <div>
                                                <Link href={Page.Login} variant="body2">
                                                    {"Already have an account? Login"}
                                                </Link>
                                            </div>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2, marginLeft: "auto", display: "flex" }}
                                            >
                                                Register
                                            </Button>
                                        </Box>
                                    </Box>
                                </>
                            }
                        </Box>
                    </Grid>
                </Box>
                <Shape shape={ShapeType.Shape1} width={150} style={{ left: "-3%", top: "35%" }} />
                <Shape shape={ShapeType.Shape2} width={120} style={{ right: "25%", bottom: "-1%" }} />
                <Shape shape={ShapeType.Shape3} width={150} style={{ right: "10%", top: "1%" }} />

            </>
        );
    }
}

export default withRouter<InvitePageProps>(InvitePage);
