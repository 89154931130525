import React, { Component } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Asset } from "../../../../api/models/Asset";
import { Campus } from "../../../../api/models/Campus";
import { AssetGroupService } from "../../../../services/AssetGroupService";
import { AssetCollection } from "../../../../api/models/AssetCollection";
import CloseIcon from '@mui/icons-material/Close';
import { Spacer } from "../../../components/Spacer";
import { Typography } from "@mui/material";

export interface RemoveAssetGroupFormProps
{
    onRemoved: () => void;
    campus: Campus;
    parentGroup: AssetCollection;
    groupToRemove: AssetCollection;
}

interface RemoveAssetGroupFormState
{
    errorMessage: string;
    saving: boolean;
}

export class RemoveAssetGroupForm extends Component<RemoveAssetGroupFormProps, RemoveAssetGroupFormState>
{
    constructor(props: RemoveAssetGroupFormProps)
    {
        super(props);

        this.state = {
            errorMessage: null,
            saving: false,
        };
    }

    private async onRemoveConfirmed(): Promise<void>
    {
        const assetGroupService: AssetGroupService = new AssetGroupService();

        try
        {
            await assetGroupService.removeAssetGroupFromGroup(
                this.props.parentGroup.id,
                this.props.groupToRemove.id
            );

            this.props.onRemoved();
        }
        catch (error)
        {
            console.warn("Error", error);

            this.setState({
                errorMessage: error.message,
                saving: false
            });
        }
    }

    public render(): JSX.Element
    {
        return (
            <Grid container component="main">
                <Grid item xs={12} >
                    <Box
                        sx={{
                            my: 4,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center"
                        }}
                    >
                        <span>Asset Collection: <span style={{ fontWeight: "800" }}>{this.props.groupToRemove?.name}</span></span>
                        <Spacer height={10} />

                        {this.props.parentGroup == null
                            ?
                            <>
                                Are you sure you want to remove this collection
                            </>
                            :
                            <span>
                                Are you sure you want to remove this collection from its parent collection: {" "}
                                <span style={{ fontWeight: "600" }}>
                                    {this.props.parentGroup.name}?
                                </span>
                            </span>
                        }

                        <Button
                            fullWidth
                            disabled={this.state.saving}
                            variant="outlined"
                            color="error"
                            onClick={this.onRemoveConfirmed.bind(this)}
                            sx={{ mt: 2 }}
                        >
                            <CloseIcon /> Remove collection
                        </Button>

                        <Typography sx={{ pt: 2, fontSize: 13 }} color="error">
                            Note: This will remove the asset collection.<br /> However it <u>will not</u> delete assets contained in this collection.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}