import React, { Component } from "react";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { AssetCollection } from "../../../../api/models/AssetCollection";
import PermMediaIcon from '@mui/icons-material/PermMedia';
import FolderIcon from '@mui/icons-material/Folder';

export interface GroupThumbnailProps
{
    assetGroup: AssetCollection;
}

export class GroupThumbnail extends Component<GroupThumbnailProps, {}>
{
    constructor(props: GroupThumbnailProps)
    {
        super(props);

        this.state = {
        };
    }

    public render(): JSX.Element
    {
        let thumbnailUrl: string = null;
        let hasThumbnail: boolean = (this.props.assetGroup?.file?.data?.attributes?.formats != null);

        if (hasThumbnail)
        {
            let small = this.props.assetGroup.file?.data?.attributes?.formats.small;
            let thumbnail = this.props.assetGroup.file?.data?.attributes.formats.thumbnail;

            if (small)
            {
                thumbnailUrl = small.url;
            }
            else 
            {
                thumbnailUrl = thumbnail.url;
            }
        }
        else 
        {
            try
            {
                // Strapi paginated response, vs flat asset response
                // Theres a lot to change api/ and frontend to fix.
                thumbnailUrl = (this.props.assetGroup?.file as any)?.formats.thumbnail.url;
                hasThumbnail = thumbnailUrl != null;
            }
            catch
            {
            }
        }

        return (
            <div
                className="thumbnail"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#666286",
                    backgroundImage: `url(${thumbnailUrl})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "4px",
                    height: "100%",
                    minHeight: "180px",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "100%",
                }}>
                {!hasThumbnail ?
                    <FolderIcon sx={{ fontSize: 55, fill: "#9692b5" }} />
                    : null
                }
            </div>
        );
    }
}