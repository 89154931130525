import React, { Component } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Asset } from "../../../../api/models/Asset";
import { AssetService } from "../../../../services/AssetService";
import { Campus } from "../../../../api/models/Campus";
import { FormControl, Autocomplete, TextField } from "@mui/material";
import { AssetCollection } from "../../../../api/models/AssetCollection";
import { AssetGroupService } from "../../../../services/AssetGroupService";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';
import { CampusService } from "../../../../services/CampusService";

export interface AddAssetOrGroupFormProps
{
    onUpdated: () => void;
    group: AssetCollection;
    campus: Campus;
    exitingCampusAssets?: Array<Asset>;
    existingGroups?: Array<AssetCollection>;
}

interface AddAssetOrGroupFormState 
{
    list: Array<AssetAndGroupList>;
    selectedList: Array<AssetAndGroupList>;
    errorMessage: string;
    loading: boolean;
}

interface AssetAndGroupList 
{
    id: number;
    label: any;
    type: ListItemType;
}

enum ListItemType 
{
    Asset,
    AssetGroup
}

export class AddAssetOrGroupForm extends Component<AddAssetOrGroupFormProps, AddAssetOrGroupFormState>
{
    private assets: Array<Asset> = [];
    private groups: Array<AssetCollection> = [];

    private campusService: CampusService;
    private assetService: AssetService;
    private assetGroupService: AssetGroupService;

    constructor(props: AddAssetOrGroupFormProps)
    {
        super(props);

        this.campusService = new CampusService();
        this.assetService = new AssetService();
        this.assetGroupService = new AssetGroupService();

        this.state = {
            list: [],
            selectedList: [],
            errorMessage: null,
            loading: false,
        };
    }

    public componentDidMount(): void
    {
        this.fetchData();
    }

    private async fetchData(): Promise<void> 
    {
        this.setState({
            loading: true
        });

        this.assets = await this.campusService.getCampusAssets(this.props.campus.id);
        let list: Array<AssetAndGroupList> = this.buildAssetList(this.assets);

        this.groups = await this.assetGroupService.getAllCampusAssetGroups(this.props.campus.id)
        list = this.buildAssetGroupList(list, this.groups);

        this.setState({
            loading: false,
            list: list
        });
    }

    private buildAssetList(assets: Array<Asset>): Array<AssetAndGroupList> 
    {
        let assetList: Array<AssetAndGroupList> = [];

        for (let i = 0; i < assets.length; i++)
        {
            let a: Asset = assets[i];
            assetList.push({
                id: a.id,
                label: a.name,
                type: ListItemType.Asset
            });
        }
        return assetList;
    }

    private buildAssetGroupList(list: Array<AssetAndGroupList>, groups: Array<AssetCollection>): Array<AssetAndGroupList> 
    {
        for (let i = 0; i < groups.length; i++)
        {
            let g: AssetCollection = groups[i];

            if (g.id === this.props.group.id)
            {
                // Don't show same parent group
                continue;
            }

            list.push({
                id: g.id,
                label: g.name,
                type: ListItemType.AssetGroup
            });
        }
        return list;
    }

    private onSelectAssetChanged(e: Event, list: Array<AssetAndGroupList>): void 
    {
        this.setState({
            selectedList: list
        });
    }

    private async onAddAssetConfirmed(): Promise<void>
    {
        try
        {
            let assetIds: Array<number> = this.state.selectedList.
                filter(listItem => listItem.type === ListItemType.Asset)
                .map(listItem => listItem.id);

            let assetGroupsIds: Array<number> = this.state.selectedList
                .filter(listItem => listItem.type === ListItemType.AssetGroup)
                .map(listItem => listItem.id);

            await this.assetGroupService.addAssetsToAssetGroup(this.props.group.id, assetIds);
            await this.assetGroupService.addAssetGroupsToAssetGroup(this.props.group.id, assetGroupsIds);

            this.props.onUpdated();
        }
        catch (error)
        {
            console.log("Error", error);
            this.setState({
                errorMessage: error.message,
                loading: false
            });
        }
    }

    public render(): JSX.Element
    {
        return (
            <Grid container component="main">
                <Grid item xs={12} >
                    <Box
                        sx={{
                            my: 4,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >

                        {!this.state.loading ?
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                {this.state.list ?
                                    <Autocomplete
                                        disablePortal
                                        filterSelectedOptions
                                        groupBy={(list: AssetAndGroupList) => list.type === ListItemType.Asset
                                            ? "Assets" : "Asset Collections"
                                        }
                                        multiple
                                        onChange={this.onSelectAssetChanged.bind(this)}
                                        renderInput={(params) => <TextField {...params} label="Search for assets or collections" />}
                                        options={this.state.list}
                                    />
                                    : null
                                }
                            </FormControl>
                            : null
                        }

                        <Button
                            fullWidth
                            variant="contained"
                            onClick={this.onAddAssetConfirmed.bind(this)}
                            disabled={this.state.loading || this.state.selectedList?.length == 0}
                            sx={{ mt: 2 }}
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}