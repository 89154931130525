import React, { Component } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { User } from "../../../api/models/User";
import { ErrorMessage } from "../../components/ErrorMessage";
import { UserService } from "../../../services/UserService";
import { Campus } from "../../../api/models/Campus";
import { Typography } from "@mui/material";
import { ProfileImage } from "../../components/ProfileImage";

export interface UserFormProps
{
    user?: User;
    campus?: Campus;
    onUpdated: () => void;
}

interface UserFormState 
{
    name: string;
    email: string;
    errorMessage: string;
    saving: boolean;
}

export class UserForm extends Component<UserFormProps, UserFormState>
{
    private userService: UserService;
    private form: React.RefObject<HTMLFormElement>;
    private profileImageRef: React.RefObject<HTMLInputElement>;

    private email: string = "";
    private name: string = "";

    constructor(props: UserFormProps)
    {
        super(props);

        this.userService = new UserService();
        this.profileImageRef = React.createRef();

        this.state = {
            name: this.props.user?.name,
            email: this.props.user?.email,
            errorMessage: null,
            saving: false
        };
    }

    public componentDidMount(): void
    {
        this.init();
    }

    private async init(): Promise<void>
    {
        this.email = this.props.user.email;
        this.name = this.props.user.name;
    }

    private async onSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> 
    {
        e.preventDefault();

        this.setState({
            saving: true
        });

        await this.userService.updateUser({
            id: this.props.user.id,
            email: this.email,
            name: this.name,
        });

        if (this.profileImageRef.current.files?.length > 0)
        {
            let formData: FormData = new FormData();
            formData.append("ref", "plugin::users-permissions.user");
            formData.append("refId", this.props.user.id.toString());
            formData.append("field", "avatar");
            formData.append("files", this.profileImageRef.current.files[0], this.profileImageRef.current.files[0].name);
            await this.userService.updateUserAvatar(this.props.user.id, formData);
        }

        this.setState({
            saving: false
        });

        this.props.onUpdated();
    }

    private onNameChanged(e: React.KeyboardEvent<HTMLInputElement>): void 
    {
        this.name = e.currentTarget.value;
    }

    private onEmailChanged(e: React.KeyboardEvent<HTMLInputElement>): void 
    {
        this.email = e.currentTarget.value;
    }

    public render(): JSX.Element
    {
        return (
            <Grid container component="main">
                <Grid item xs={12}>
                    <Box>
                        <Box
                            noValidate
                            component="form"
                            sx={{ mt: 1, overflow: "auto", pr: 2, pl: 2 }}
                            onSubmit={this.onSubmit.bind(this)}
                            ref={this.form}
                        >
                            <Typography variant="h6">Details</Typography>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                name="name"
                                autoComplete="name"
                                onChange={this.onNameChanged.bind(this)}
                                defaultValue={this.props.user.name}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                onChange={this.onEmailChanged.bind(this)}
                                defaultValue={this.props.user.email}
                            />

                            <Typography variant="h6" sx={{ mt: 2 }}>Profile image</Typography>
                            <Grid container justifyContent={"space-between"}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth={this.props.user.avatar == null}
                                    id="asset-file"
                                    label=""
                                    name="file"
                                    autoComplete="file"
                                    type="file"
                                    inputRef={this.profileImageRef}
                                />
                                {this.props.user.avatar != null ?
                                    <ProfileImage user={this.props.user} width={160} height={160} />
                                    : null
                                }
                            </Grid>

                            {this.state.errorMessage ?
                                <ErrorMessage message={this.state.errorMessage} />
                                : null
                            }
                            <Grid container justifyContent={"space-between"}>
                                <div></div>
                                <Button
                                    type="submit"
                                    disabled={this.state.saving}
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}