import { DIEvent } from "../diverse/DIEvent";

export class UserEvent extends DIEvent
{
    public static LOGGED_IN: string = "userEventLoggedIn";
    public static LOGGED_OUT: string = "userEventLoggedOut";

    constructor(type: string)
    {
        super(type);
    }
}