import React, { Component } from "react";
import Typography from "@mui/material/Typography";


export interface PageErrorMessageProps
{
    error: Error;
}

interface PageErrorMessageState 
{
}

export class PageErrorMessage extends Component<PageErrorMessageProps, PageErrorMessageState>
{
    constructor(props: PageErrorMessageProps)
    {
        super(props);

        this.state = {
        };
    }

    public render()
    {
        return (
            <Typography
                variant="h4"
                noWrap
            >
                Error - {this.props.error.message}
            </Typography>
        );
    }
}