import React, { Component } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { SessionAttendees, SessionService } from "../../../../services/SessionService";
import { Session } from "../../../../api/models/Session";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Autocomplete, Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { UserService } from "../../../../services/UserService";
import { User } from "../../../../api/models/User";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { Environment, EnvironmentType } from "../../../../api/models/Environment";
import { Campus } from "../../../../api/models/Campus";
import { EnvironmentService } from "../../../../services/EnviromentService";
import BasicModal, { ModalSize } from "../../../components/Modal";
import { UserGroup } from "../../../../api/models/UserGroup";
import { CampusService } from "../../../../services/CampusService";
import { UserGroupService } from "../../../../services/UserGroupService";
import { AssetCollection } from "../../../../api/models/AssetCollection";

export interface SessionFormProps
{
    onSessionDeleted?: () => void;
    onSessionCreated?: (session: Session) => void;
    onSessionUpdated?: (session: Session) => void;
    session?: Session;
    campus?: Campus;
}

interface SessionFormState 
{
    title: string;
    description: string;
    start: Date;
    end: Date;
    errorMessage: string;
    userList: Array<UserListItem>;
    selectedAttendees: Array<UserListItem>;
    selectedPresenters: Array<UserListItem>;
    environmentList: Array<EnvironmentListItem>;
    selectedEnvironment: EnvironmentListItem;
    showAddUsersByGroupModal: boolean;
    addUserGroupTarget: "attendees" | "presenters";
    userGroups: Array<UserGroup>;
    selectedUserGroupId: number;
    campusAssetCollections: Array<AssetCollectionItem>;
    selectedAssetCollections: Array<AssetCollectionItem>;
}

interface UserListItem
{
    userId: number;
    label: string;
}

interface EnvironmentListItem
{
    id: number;
    label: string;
}

interface AssetCollectionItem
{
    id: number;
    label: string;
}

export class SessionForm extends Component<SessionFormProps, SessionFormState>
{
    constructor(props: SessionFormProps)
    {
        super(props);

        this.state = {
            title: this.props.session?.name ?? "",
            description: this.props?.session?.description ?? "",
            start: this.props.session?.start,
            end: this.props.session?.end,
            userList: [],
            environmentList: [],
            selectedEnvironment: null,
            selectedAttendees: [],
            selectedPresenters: [],
            errorMessage: null,
            showAddUsersByGroupModal: false,
            addUserGroupTarget: "attendees",
            userGroups: [],
            selectedUserGroupId: 0,
            campusAssetCollections: [],
            selectedAssetCollections: []
        };
    }

    public componentDidMount(): void
    {
        this.init();
    }

    private async init()
    {
        const users: Array<User> = await this.getUserList();
        const sessionAttendees: SessionAttendees = await this.getAttendees();
        const environments: Array<Environment> = await this.getCampusEnvironments(
            this.props.campus?.id,
        );
        const userGroups: Array<UserGroup> = await this.getUserGroups();
        this.setState({
            selectedUserGroupId: userGroups.length > 0 ? userGroups[0].id : 0,
            userGroups: userGroups
        });

        let collections: Array<AssetCollection> = await this.getAssetCollections();

        this.buildAttendeeList(users, sessionAttendees);
        this.buildEnvironmentList(environments);
        this.buildAssetCollections(collections);

        if (this.props.session == null && this.state.selectedPresenters.length == 0)
        {
            // Add creator as presenter by default
            this.setState({
                selectedPresenters: [{
                    userId: UserService.user.id,
                    label: UserService.user.email,
                }]
            });
        }
    }

    private async getAttendees(): Promise<SessionAttendees> 
    {
        let sessionAttendees: SessionAttendees = null;
        if (this.props.session != null)
        {
            try
            {
                const sessionService = new SessionService();
                sessionAttendees = await sessionService.getSessionAttendeesById(this.props.session.id);
            }
            catch (e)
            {
                console.log("Error retrieving session attendees:", e);
            }
        }
        return sessionAttendees;
    }

    private async getCampusEnvironments(campusId: number): Promise<Array<Environment>>
    {
        try
        {
            const environmentService = new EnvironmentService();
            const environments: Array<Environment> = await environmentService.getCampusEnvironments(campusId);
            return environments;
        }
        catch
        {
            return [];
        }
    }

    private async getUserList(): Promise<Array<User>>
    {
        const userService: UserService = new UserService();
        const users: Array<User> = await userService.getUsersByCampus(this.props.campus?.id);
        return users;
    }

    private async getUserGroups(): Promise<Array<UserGroup>>
    {
        let userGroupService: UserGroupService = new UserGroupService();
        let groups: Array<UserGroup> = await userGroupService.getUserGroupsByCampus(this.props.campus.id);
        return groups;
    }

    private async getAssetCollections(): Promise<Array<AssetCollection>>
    {
        let campusService: CampusService = new CampusService();
        let collections: Array<AssetCollection> = await campusService.getCampusAssetCollections(this.props.campus.id);
        return collections;
    }

    private buildAttendeeList(users: Array<User>, sessionAttendees: SessionAttendees): void
    {
        let userListItems: Array<UserListItem> = [];
        let defaultAttendees: Array<UserListItem> = [];
        let defaultpresenters: Array<UserListItem> = [];

        for (let i = 0; i < users.length; i++)
        {
            const user: User = users[i];

            let userList: UserListItem = {
                label: user.email,
                userId: user.id
            };

            if (sessionAttendees != null)
            {
                if (sessionAttendees.presenters.indexOf(user.id) > -1)
                {
                    defaultpresenters.push(userList);
                }
                else if (sessionAttendees.attendees.indexOf(user.id) > -1)
                {
                    defaultAttendees.push(userList);
                }
            }

            userListItems.push(userList);
        }

        this.setState({
            userList: userListItems,
            selectedAttendees: defaultAttendees,
            selectedPresenters: defaultpresenters
        });
    }

    private buildEnvironmentList(environments: Array<Environment>): void
    {
        let sessionEnvironments: Array<Environment> = environments.filter(e => e.type == EnvironmentType.Session);
        let selectedEnvironment: EnvironmentListItem = null;

        let environmentItems: Array<EnvironmentListItem> = sessionEnvironments.map(e =>
        {
            return {
                id: e.id,
                label: e.name
            }
        });

        if (this.props.session?.environment != null)
        {
            selectedEnvironment = environmentItems.find(e => e.id == this.props.session.environment?.id);
        }
        else
        {
            selectedEnvironment = environmentItems.length > 0 ? environmentItems[0] : null;
        }

        this.setState({
            environmentList: environmentItems,
            selectedEnvironment: selectedEnvironment
        });
    }

    private buildAssetCollections(collections: Array<AssetCollection>): void
    {
        let collectionItems: Array<AssetCollectionItem> = [];
        let selectedCollectionItems: Array<AssetCollectionItem> = [];
        for (let collection of collections)
        {
            if(collection.parentAssetGroups.length > 0)
            {
                // Top level collections only
                continue;
            }

            collectionItems.push({
                id: collection.id,
                label: collection.name
            });

            if (this.props.session?.asset_collections?.find(c => c.id == collection.id))
            {
                selectedCollectionItems.push({
                    id: collection.id,
                    label: collection.name
                });
            }
        }

        this.setState({
            campusAssetCollections: collectionItems,
            selectedAssetCollections: selectedCollectionItems
        });
    }

    private async onSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> 
    {
        e.preventDefault();

        if (this.props.session == null)
        {
            await this.onCreate();
        }
        else 
        {
            await this.onEdit();
        }
    }

    private async onCreate(): Promise<void>
    {
        const sessionService: SessionService = new SessionService();
        try
        {
            let attendees: Array<number> = this.state.selectedAttendees.map((ul: UserListItem) => ul.userId);
            let presenters: Array<number> = this.state.selectedPresenters.map((ul: UserListItem) => ul.userId);
            let collections: Array<number> = this.state.selectedAssetCollections.map(c => c.id);

            const session: Session = await sessionService.createSession({
                name: this.state.title,
                description: this.state.description,
                start: this.state.start ?? new Date(),
                end: this.state.end ?? new Date(),
                host: UserService.user.id,
                campus: this.props.campus?.id,
                environment: this.state.selectedEnvironment?.id ?? null,
                attendees: attendees,
                presenters: presenters,
                asset_collections: collections
            });

            this.props.onSessionCreated(session);
        }
        catch (error)
        {
            this.setState({
                errorMessage: error.message
            });
        }
    }

    private async onEdit(): Promise<void>
    {
        const sessionService: SessionService = new SessionService();

        try
        {
            let attendees: Array<number> = this.state.selectedAttendees.map((ul: UserListItem) => ul.userId);
            let presenters: Array<number> = this.state.selectedPresenters.map((ul: UserListItem) => ul.userId);
            let collections: Array<number> = this.state.selectedAssetCollections.map(c => c.id);

            const session: Session = await sessionService.updateSession({
                id: this.props.session.id,
                name: this.state.title,
                description: this.state.description,
                start: this.state.start,
                end: this.state.end,
                environment: this.state.selectedEnvironment?.id ?? null,
                attendees: attendees,
                presenters: presenters,
                asset_collections: collections
            });

            this.props.onSessionUpdated(session);
        }
        catch (error)
        {
            this.setState({
                errorMessage: error.message
            });
        }
    }

    private async onDeleteClicked(): Promise<void>
    {
        const sessionService: SessionService = new SessionService();

        try
        {
            await sessionService.deleteSessionById(this.props.session.id);
            this.props.onSessionDeleted();
        }
        catch (error)
        {
            this.setState({
                errorMessage: error.message
            });
        }
    }

    private onTitleChanged(e: React.FormEvent<HTMLInputElement>): void
    {
        this.setState({
            title: e.currentTarget.value
        });
    }

    private onDescriptionChanged(e: React.FormEvent<HTMLInputElement>): void
    {
        this.setState({
            description: e.currentTarget.value
        });
    }

    private onStartDateChanged(start: Date): void
    {
        this.setState({
            start: start
        });
    }

    private onEndDateChanged(end: Date): void
    {
        this.setState({
            end: end
        });
    }

    private onEnvironmentChanged(e: React.SyntheticEvent, value: any): void
    {
        this.setState({
            selectedEnvironment: value
        });
    }

    private onAttendeesUpdated(e: Event, values: Array<UserListItem>): void 
    {
        this.setAttendees(values);
    }

    private onPresentersUpdated(e: Event, values: Array<UserListItem>): void 
    {
        this.setPresenters(values);
    }

    private setAttendees(values: Array<UserListItem>): void
    {
        // Remove from presenters if added to attendees
        let presenters: Array<UserListItem> = this.state.selectedPresenters;
        presenters = presenters.filter(p => values.find(v => v.userId == p.userId) == null);

        this.setState({
            selectedAttendees: values,
            selectedPresenters: presenters
        });
    }

    private setPresenters(values: Array<UserListItem>): void
    {
        // Remove from attendees if added to presenters
        let attendees: Array<UserListItem> = this.state.selectedAttendees;
        attendees = attendees.filter(a => values.find(v => v.userId == a.userId) == null);

        this.setState({
            selectedAttendees: attendees,
            selectedPresenters: values
        });
    }

    private onAddGroupToPresentersClicked(e): void
    {
        this.setState({
            addUserGroupTarget: "presenters",
            showAddUsersByGroupModal: true
        });
    }

    private onAddGroupToAttendeesClicked(e): void
    {
        this.setState({
            addUserGroupTarget: "attendees",
            showAddUsersByGroupModal: true
        });
    }

    private onHideUserGroupModal(): void
    {
        this.setState({
            showAddUsersByGroupModal: false
        });
    }

    private onAddByUserGroupChanged(e: Event, item: React.ReactElement): void
    {
        this.setState({
            selectedUserGroupId: item.props.value
        });
    }

    private onAddUsersByGroupClicked(e): void
    {
        this.addUserGroup(this.state.selectedUserGroupId, this.state.addUserGroupTarget);
    }

    private async addUserGroup(groupId: number, target: string): Promise<void>
    {
        let userGroupService: UserGroupService = new UserGroupService();
        let users: Array<User> = await userGroupService.getUsersByGroup(groupId);
        let userListItems: Array<UserListItem> = this.state.userList.filter(ul => users.find(u => u.id == ul.userId));

        switch (this.state.addUserGroupTarget)
        {
            case "attendees":
                let existingAttendees: Array<UserListItem> = this.state.selectedAttendees;
                for (let user of userListItems)
                {
                    if (!existingAttendees.find(u => u.userId == user.userId))
                    {
                        existingAttendees.push(user);
                    }
                }
                this.setAttendees(existingAttendees);
                break;
            case "presenters":
                let existingPresenters: Array<UserListItem> = this.state.selectedPresenters;
                for (let user of userListItems)
                {
                    if (!existingPresenters.find(u => u.userId == user.userId))
                    {
                        existingPresenters.push(user);
                    }
                }
                this.setPresenters(existingPresenters);
                break;
        }

        this.setState({
            showAddUsersByGroupModal: false,
        });
    }

    private onAssetCollectionsUpdated(e, values: Array<AssetCollectionItem>): void
    {
        this.setState({
            selectedAssetCollections: values
        });
    }

    public render(): JSX.Element
    {
        return (
            <Grid container component="main">
                <Grid xs={12}>
                    <Box
                        sx={{
                            my: 4,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={this.onSubmit.bind(this)}>

                            <Grid container>
                                <Grid xs={12} md={2}>
                                    <Typography fontWeight={600} paddingTop={2}>Create session</Typography>
                                </Grid>

                                <Grid xs={12} md={8}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="session-title"
                                        label="Title"
                                        name="title"
                                        autoComplete="title"
                                        autoFocus
                                        onChange={this.onTitleChanged.bind(this)}
                                        value={this.state.title}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="session-description"
                                        label="Description"
                                        name="description"
                                        autoComplete="description"
                                        onChange={this.onDescriptionChanged.bind(this)}
                                        value={this.state.description}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid xs={12} md={2}>
                                    <Typography fontWeight={600} paddingTop={2}>Environment</Typography>
                                </Grid>

                                <Grid xs={12} md={8}>
                                    <FormControl fullWidth>
                                        {this.state.environmentList ?
                                            <Autocomplete
                                                disablePortal
                                                filterSelectedOptions
                                                renderInput={(params) => <TextField {...params} label="Environment" />}
                                                onChange={this.onEnvironmentChanged.bind(this)}
                                                options={this.state.environmentList}
                                                value={this.state.selectedEnvironment}
                                            />
                                            : null
                                        }
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid xs={12} md={2}>
                                    <Typography fontWeight={600} paddingTop={2}>Time & date</Typography>
                                </Grid>

                                <Grid xs={12} md={8}>
                                    <Grid container sx={{ pl: 0 }}>
                                        <Grid xs={12} md={6}>
                                            <DateTimePicker
                                                label="Start"
                                                value={this.state.start ?? new Date()}
                                                onChange={this.onStartDateChanged.bind(this)}
                                                renderInput={(params) => <TextField {...params} sx={{ width: "100%" }} />}
                                            />
                                        </Grid>

                                        <Grid xs={12} md={6}>
                                            <DateTimePicker
                                                label="End"
                                                value={this.state.end ?? new Date()}
                                                onChange={this.onEndDateChanged.bind(this)}
                                                renderInput={(params) => <TextField {...params} sx={{ width: "100%" }} />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid xs={12} md={2}>
                                    <Typography fontWeight={600} paddingTop={2}>Presenters</Typography>
                                </Grid>

                                <Grid xs={12} md={8}>
                                    {this.state.userList ?
                                        <Autocomplete
                                            multiple
                                            disablePortal
                                            filterSelectedOptions
                                            renderInput={(params) => <TextField {...params} label="Presenter" />}
                                            onChange={this.onPresentersUpdated.bind(this)}
                                            options={this.state.userList}
                                            value={this.state.selectedPresenters}
                                        />
                                        : null
                                    }
                                    <Button
                                        variant="contained"
                                        style={{ float: "right", marginTop: "8px" }}
                                        onClick={this.onAddGroupToPresentersClicked.bind(this)}
                                    >
                                        Select group
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid xs={12} md={2}>
                                    <Typography fontWeight={600} paddingTop={2}>Attendees</Typography>
                                </Grid>

                                <Grid xs={12} md={8}>
                                    {this.state.userList ?
                                        <Autocomplete
                                            multiple
                                            disablePortal
                                            filterSelectedOptions
                                            renderInput={(params) => <TextField {...params} label="Attendee" />}
                                            onChange={this.onAttendeesUpdated.bind(this)}
                                            options={this.state.userList}
                                            value={this.state.selectedAttendees}
                                        />
                                        : null
                                    }
                                    <Button
                                        variant="contained"
                                        style={{ float: "right", marginTop: "8px" }}
                                        onClick={this.onAddGroupToAttendeesClicked.bind(this)}
                                    >
                                        Select group
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid xs={12} md={2}>
                                    <Typography fontWeight={600} paddingTop={2}>Assets</Typography>
                                </Grid>

                                <Grid xs={12} md={8}>
                                    {this.state.campusAssetCollections ?
                                        <Autocomplete
                                            multiple
                                            disablePortal
                                            filterSelectedOptions
                                            renderInput={(params) => <TextField {...params} label="Asset Collections" />}
                                            onChange={this.onAssetCollectionsUpdated.bind(this)}
                                            options={this.state.campusAssetCollections}
                                            value={this.state.selectedAssetCollections}
                                        />
                                        : null
                                    }
                                </Grid>
                            </Grid>

                            {this.state.errorMessage ?
                                <ErrorMessage message={this.state.errorMessage} />
                                : null
                            }

                            <Grid md={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                {this.props.session ?
                                    <Button
                                        onClick={this.onDeleteClicked.bind(this)}
                                        variant="text"
                                        sx={{ mt: 3, mb: 2, mr: 1 }}
                                    >
                                        Delete session?
                                    </Button>
                                    : null
                                }

                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    {this.props.session == null ?
                                        "Create"
                                        :
                                        "Save"
                                    }
                                </Button>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>

                {this.state.showAddUsersByGroupModal ?
                    <BasicModal onClose={this.onHideUserGroupModal.bind(this)} size={ModalSize.Large}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Typography component="h5" variant="h5" align="center">
                                User groups
                            </Typography>

                            <Typography sx={{ my: 4 }} component="p" align="center">
                                Select a user group to add to the {this.state.addUserGroupTarget} list
                            </Typography>

                            <FormControl fullWidth>
                                <InputLabel id="user-group-label">Group</InputLabel>
                                <Select
                                    labelId="user-group-label"
                                    id="user-group"
                                    label="User group"
                                    defaultValue={this.state.selectedUserGroupId}
                                    onChange={this.onAddByUserGroupChanged.bind(this)}
                                >
                                    {
                                        this.state.userGroups.map(g => <MenuItem key={g.id} value={g.id}>{g.name}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                            <Button sx={{ mt: 4, alignSelf: "flex-end" }} variant="contained" onClick={this.onAddUsersByGroupClicked.bind(this)}>Add group</Button>
                        </Box>
                    </BasicModal>
                    : null
                }
            </Grid>
        );
    }
}