export class APIHelper 
{
    public static removeHeader(headerKey: string, headers: { [name: string]: string })
    {
        for (let k in headers)
        {
            if (k === headerKey)
            {
                delete headers[k];
            }
        }
        return headers;
    }

    public static replaceHeaderValue(headerKey: string, newHeadervalue: string, headers: { [name: string]: string })
    {
        for (let k in headers)
        {
            if (k === headerKey)
            {
                headers[k] = newHeadervalue;
            }
        }
        return headers;
    }
}

