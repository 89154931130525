import React, { Component } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import { Asset } from "../../../../api/models/Asset";
import { AssetService } from "../../../../services/AssetService";
import { CampusService } from "../../../../services/CampusService";
import { Campus } from "../../../../api/models/Campus";
import { Spacer } from "../../../components/Spacer";
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from "@mui/material";

export interface DeleteAssetFormProps
{
    onDeleted: () => void;
    asset: Asset;
}

interface DeleteAssetFormState 
{
    errorMessage: string;
    saving: boolean;
}

export class DeleteAssetForm extends Component<DeleteAssetFormProps, DeleteAssetFormState>
{
    constructor(props: DeleteAssetFormProps)
    {
        super(props);

        this.state = {
            errorMessage: null,
            saving: false,
        };
    }

    private async onDeleteConfirmerd(): Promise<void>
    {
        const assetService: AssetService = new AssetService();

        try
        {
            await assetService.deleteAssetById(this.props.asset.id);
            this.props.onDeleted();
        }
        catch (error)
        {
            console.warn("Error", error);

            this.setState({
                errorMessage: error.message,
                saving: false
            });
        }
    }

    public render(): JSX.Element
    {
        return (
            <Grid container component="main">
                <Grid item xs={12} >
                    <Box
                        sx={{
                            my: 4,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        Asset name: {this.props.asset?.name}<br />

                        <Spacer height={5} />
                        Are you sure you want to delete this asset?

                        <Button
                            fullWidth
                            disabled={this.state.saving}
                            variant="outlined"
                            onClick={this.onDeleteConfirmerd.bind(this)}
                            sx={{ mt: 2 }}
                        >
                            <DeleteIcon /> Delete asset
                        </Button>

                        <Typography sx={{ pt: 2, fontSize: 13 }} color="error">
                            Note: This will delete the asset permanently. This cannot be reversed.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}