import React, { Component } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { App } from "../../../App";
import { PageErrorMessage } from "../../components/PageErrorMessage";
import Header from "../../components/Header";
import { RouterProps } from "../../RouterProps";
import { withRouter } from "../../withRouter";
import { CampusService } from "../../../services/CampusService";
import { Campus } from "../../../api/models/Campus";
import { CampusForm } from "./components/CampusForm";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { CampusAdminRoute } from "./components/CampusAdminRoute";

export interface CampusEditPageProps extends RouterProps
{
}

interface CampusEditPageState 
{
    campus: Campus;
    loading: boolean;
    pageError: Error;
}

class CampusEditPage extends Component<CampusEditPageProps, CampusEditPageState>
{
    private campusService: CampusService;

    constructor(props: CampusEditPageProps)
    {
        super(props);

        this.campusService = new CampusService();

        this.state = {
            campus: null,
            pageError: null,
            loading: true
        };
    }

    public componentDidMount(): void
    {
        this.getCampus();
    }

    private async getCampus(): Promise<void> 
    {
        try
        {
            const campusId: number = this.props.router.params.campusId;
            const campus: Campus = await this.campusService.getCampusById(campusId);

            App.I.setPageTitle(`Edit - ${campus.name}`);

            this.setState({
                campus,
                loading: false
            });

        }
        catch (error)
        {
            this.setState({
                pageError: error,
                loading: false
            });
        }
    }

    private async onCampusUpdated(campus: Campus): Promise<void>
    {
        this.setState({
            loading: true,
        });

        App.I.sendNotification(`Campus  ${campus.name} updated succesfully`);

        this.getCampus();
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Header currentCampus={this.state.campus} />

                <Container component="main" maxWidth="xl">

                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4, mb: 4 }}>
                        <Link color="inherit" href="/">
                            Dashboard
                        </Link>
                        <Link color="inherit" href="/campuses">
                            Campuses
                        </Link>
                        <Link color="inherit" href={`/campuses/${this.state.campus?.id}`}>
                            {this.state.campus?.name}
                        </Link>
                        <Typography color="text.primary">Edit</Typography>
                    </Breadcrumbs>

                    {this.state.loading === false ?
                        <CampusAdminRoute campus={this.state.campus}>
                            {this.state.pageError != null ?
                                <PageErrorMessage error={this.state.pageError} />
                                :
                                <Grid container spacing={2}>
                                    <Grid xs={12} md={12}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                            }}
                                        >
                                            {this.state.campus ?
                                                <CampusForm
                                                    onCampusUpdated={this.onCampusUpdated.bind(this)}
                                                    campus={this.state.campus}
                                                />
                                                : null
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            }
                        </CampusAdminRoute>
                        : null
                    }
                </Container>
            </>
        );
    }
}

export default withRouter<CampusEditPageProps>(CampusEditPage);