import React, { Component } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { App } from "../../../App";
import Header from "../../components/Header";
import { RouterProps } from "../../RouterProps";
import { Campus } from "../../../api/models/Campus";
import { CampusService } from "../../../services/CampusService";
import { withRouter } from "../../withRouter";
import { PageErrorMessage } from "../../components/PageErrorMessage";
import { BaseBox } from "../../components/BaseBox";
import PersonIcon from '@mui/icons-material/Person';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { ImportCampusUsersForm } from "./components/ImportCampusUsersForm";
import { List, ListItem } from "@mui/material";

export interface CampusImportUsersPageProps extends RouterProps
{
}

interface CampusImportUsersPageState 
{
    campus: Campus;
    loading: boolean;
    pageError: Error;
}

class CampusImportUsersPage extends Component<CampusImportUsersPageProps, CampusImportUsersPageState>
{
    private campusService: CampusService;

    constructor(props: CampusImportUsersPageProps)
    {
        super(props);

        this.campusService = new CampusService();

        this.state = {
            campus: null,
            pageError: null,
            loading: true
        };

        App.I.setPageTitle("Campus User Import");
    }

    public componentDidMount(): void
    {
        this.init();
    }

    private async init(): Promise<void>
    {
        await this.getCampus();
    }

    private async getCampus(): Promise<void> 
    {
        try
        {
            const campusId: number = this.props.router.params.campusId;
            const campus: Campus = await this.campusService.getCampusById(campusId);

            App.I.setPageTitle(campus.name);

            this.setState({
                campus,
                loading: false
            });
        }
        catch (error)
        {
            this.setState({
                pageError: error,
                loading: false
            });
        }
    }

    private onInviteUsersSuccess(): void 
    {
        App.I.sendNotification("Invited emails succesfully");
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Header />

                <Container component="main" maxWidth="xl">
                    {this.state.loading === false ?
                        <>
                            {this.state.pageError != null ?
                                <PageErrorMessage error={this.state.pageError} />
                                :
                                <Box sx={{ mt: 4 }}>

                                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4, mb: 4 }}>
                                        <Link color="inherit" href="/">
                                            Dashboard
                                        </Link>
                                        <Link color="inherit" href="/campuses">
                                            Campuses
                                        </Link>
                                        <Link color="inherit" href={`/campuses/${this.state.campus?.id}`}>
                                            {this.state.campus.name}
                                        </Link>
                                        <Typography color="text.primary">Users Import - {this.state.campus?.name}</Typography>
                                    </Breadcrumbs>

                                    <BaseBox style={{ marginBottom: "2rem" }}>
                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid xs={12} md={2}>
                                                    {/* <CampusAvatar file={this.state.campus.file} /> */}
                                                </Grid>

                                                <Grid xs={12} md={6}>
                                                    <Box style={{ marginTop: "100px" }}>
                                                        <Typography variant="h3" component="h2" fontWeight={600}>
                                                            Import users
                                                        </Typography>
                                                        <Typography component="p">
                                                            {this.state.campus.name}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </BaseBox>

                                    <Grid container spacing={4}>
                                        <Grid xs={12} sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}>
                                            <Box
                                                sx={{
                                                    padding: 2,
                                                    width: "1280px",
                                                    maxWidth: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                }}
                                                component={Paper}
                                            >
                                                <Box sx={{ width: "500px", maxWidth: "100%" }}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                        }}
                                                    >

                                                        <PersonIcon sx={{ fontSize: "2.5rem", mb: 2, mt: 2 }} />

                                                        <Typography component="h4" variant="h4">
                                                            Import users
                                                        </Typography>
                                                    </Box>

                                                    <Typography sx={{ mt: 2, width: "500px", maxWidth: "100%" }}>
                                                        Invite users via email to the {this.state.campus.name} campus. Further
                                                        instructions on how to register to this campus will be given in the emails.
                                                    </Typography>
                                                    <Grid sx={{ mt: 2 }} component={Paper}>
                                                        <List sx={{ py: 1, fontSize: "1rem" }}>
                                                            <ListItem>File Format: Ensure your Excel file is in either .xls or .xlsx format.</ListItem>
                                                            <ListItem>Columns in the Excel File: Arrange your columns in this order:</ListItem>
                                                            <ListItem>Email: This is a mandatory column. Users will receive an invitation to the campus at the provided email addresses.</ListItem>
                                                            <ListItem>Name: Required. The name here will be displayed to others. It could be a first name, full name, initials, student ID, or any non-identifying nickname or ID.</ListItem>
                                                            <ListItem>Group: Optional. The "Group" column is a flexible way to categorize individuals on your campus. You can use it to denote classes, subjects, departments, roles, or any other categorization that suits your needs. At a minimum, we recommend creating two groups to distinguish between staff and students, for instance: "teachers" and "students". New group names will be automatically created if they don't exist in our system.</ListItem>
                                                            <ListItem>Data Compliance: Before uploading, ensure all data in your Excel file complies with your college's data policies, ensuring privacy and rights protection.</ListItem>
                                                            <ListItem>Post-upload: Users in the Excel file will receive invitation emails.</ListItem>

                                                        </List>
                                                    </Grid>
                                                </Box>

                                                <ImportCampusUsersForm
                                                    campus={this.state.campus}
                                                    onSuccess={this.onInviteUsersSuccess.bind(this)}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Box>
                            }
                        </>
                        : null
                    }
                </Container >
            </>
        );
    }
}

export default withRouter<CampusImportUsersPageProps>(CampusImportUsersPage);
