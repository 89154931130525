import React, { Component } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { UserService } from "../../../services/UserService";
import { User } from "../../../api/models/User";
import Header from "../../components/Header";
import { ReadyPlayMe } from "../../../diverse/readyplayme/ReadyPlayMe";
import { ReadyPlayMeViewer } from "../../../diverse/readyplayme/ReadyPlayMeViewer";
import { ReadyPlayMeModelCreatedEvent } from "../../../diverse/readyplayme/events/ReadyPlayMeModelCreatedEvent";
import { ReadyPlayMeUserCreatedEvent } from "../../../diverse/readyplayme/events/ReadyPlayMeUserCreatedEvent";
import { Box, Button, Paper } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import CircularProgress from '@mui/material/CircularProgress';
import { App } from "../../../App";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { UserForm } from "./UserForm";
import { BaseBox } from "../../components/BaseBox";

export interface UserPageProps
{
}

interface UserPageState 
{
    user: User;
    readyPlayMeGLBUrl: string;
    readyPlayMeUserId: number;
    showSetupAvatar: boolean;
    loadingViewerProgress: number;
    loadingViewer: boolean;
}

export class UserPage extends Component<UserPageProps, UserPageState>
{
    private readyPlayMe: ReadyPlayMe;
    private iframeRef: React.RefObject<HTMLIFrameElement>;
    private userService: UserService;

    constructor(props: UserPageProps)
    {
        super(props);

        this.iframeRef = React.createRef();
        this.userService = new UserService();
        this.readyPlayMe = new ReadyPlayMe();

        this.state = {
            user: null,
            readyPlayMeUserId: null,
            readyPlayMeGLBUrl: null,
            showSetupAvatar: false,
            loadingViewerProgress: 0,
            loadingViewer: true
        };

        App.I.setPageTitle("Your profile");
    }

    public componentDidMount(): void
    {
        this.readyPlayMe.addEventListener(ReadyPlayMeModelCreatedEvent.MODEL_CREATED, this.onModelCreated, this);
        this.readyPlayMe.addEventListener(ReadyPlayMeUserCreatedEvent.USER_CREATED, this.onUserCreated, this);
        this.readyPlayMe.initIframe(this.iframeRef.current);

        this.setState({
            user: UserService.user,
            readyPlayMeUserId: UserService.user.readyPlayMeUserId,
            readyPlayMeGLBUrl: UserService.user.readyPlayMeGLBUrl,
            showSetupAvatar: UserService.user.readyPlayMeGLBUrl === null,
            loadingViewer: UserService.user.readyPlayMeGLBUrl != null,
        });
    }

    public componentWillUnmount(): void
    {
        this.readyPlayMe.removeEventListener(ReadyPlayMeModelCreatedEvent.MODEL_CREATED, this.onModelCreated);
        this.readyPlayMe.removeEventListener(ReadyPlayMeUserCreatedEvent.USER_CREATED, this.onUserCreated);
    }

    private onModelCreated({ glbUrl, userId }: ReadyPlayMeModelCreatedEvent): void 
    {
        this.setState({
            readyPlayMeGLBUrl: glbUrl,
            readyPlayMeUserId: userId,
            showSetupAvatar: false,
            loadingViewer: true
        },
            () => this.updateUser()
        );
    }

    private onViewerLoadingProgress(loadingViewerProgress: number): void 
    {
        this.setState({
            loadingViewerProgress
        });
    }

    private onViewerLoadingComplete(): void 
    {
        this.setState({
            loadingViewer: false
        });
    }

    private async updateUser(): Promise<void>
    {
        await this.userService.updateUser({
            id: UserService.user.id,
            readyplayme_glb_url: this.state.readyPlayMeGLBUrl,
            readyplayme_user_id: this.state.readyPlayMeUserId
        });
    }

    private async resetUserReadyPlayMeProfile(): Promise<void>
    {
        await this.userService.updateUser({
            id: UserService.user.id,
            readyplayme_glb_url: null,
            readyplayme_user_id: null
        });
    }

    private onUserCreated({ userId }: ReadyPlayMeUserCreatedEvent): void 
    {
        this.setState({
            readyPlayMeUserId: userId
        });
    }

    private async onCreateNewProfileClicked(): Promise<void> 
    {
        await this.resetUserReadyPlayMeProfile();

        this.setState({
            showSetupAvatar: true,
            loadingViewer: UserService.user.readyPlayMeGLBUrl != null,
        });
    }

    private onUserUpdated(): void 
    {
        App.I.sendNotification("Updated");
        document.location = "/user"; // reload
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Header />

                <Container component="main" maxWidth="xl">

                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4, mb: 4 }}>
                        <Link color="inherit" href="/">
                            Dashboard
                        </Link>
                        <Typography color="text.primary">Edit profile</Typography>
                    </Breadcrumbs>

                    <Grid container spacing={2}>
                        <Grid xs={12} md={4}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                                component={Paper}
                            >
                                <BaseBox>
                                    <Box sx={{ mt: 12, }}>
                                        <Typography component="h2" variant="h5" sx={{ mt: 4, mb: 1 }}>
                                            {this.state.showSetupAvatar ?
                                                "Create your avatar"
                                                :
                                                null
                                            }
                                        </Typography>

                                        {this.state.readyPlayMeUserId !== null && this.state.showSetupAvatar === false ?
                                            <>
                                                <Typography component="h2" variant="h5" sx={{ mt: 4, mb: 1 }}>
                                                    Your ReadyPlayMe profile
                                                </Typography>
                                            </>
                                            :
                                            <>
                                                <Typography component="p" sx={{ mb: 4 }}>
                                                    Create your avatar by using the wizard.
                                                </Typography>
                                            </>
                                        }
                                    </Box>

                                    {this.state.loadingViewer && this.state.showSetupAvatar === false ?
                                        <>
                                            <Typography component="p">
                                                Your ReadyPlayMe profile is loading
                                            </Typography>
                                            <div
                                                style={{
                                                    margin: "auto",
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    paddingTop: "1rem",
                                                }}>
                                                <CircularProgress value={this.state.loadingViewerProgress} />
                                            </div>
                                        </>
                                        : null
                                    }

                                    {this.state.readyPlayMeGLBUrl && this.state.showSetupAvatar === false ?
                                        <Box sx={{
                                            background: "#fff",
                                            border: "4px solid #77ccbe",
                                            height: "250px",
                                            width: "250px",
                                            padding: "1rem",
                                            margin: "auto",
                                            mt: 2
                                        }}>
                                            <ReadyPlayMeViewer
                                                src={this.state.readyPlayMeGLBUrl + "?useHands=false"}
                                                interactable={false}
                                                onLoadingComplete={this.onViewerLoadingComplete.bind(this)}
                                                onLoadingProgress={this.onViewerLoadingProgress.bind(this)}
                                            />
                                        </Box>
                                        : null
                                    }

                                    {this.state.readyPlayMeUserId != null && this.state.showSetupAvatar === false ?
                                        <Box sx={{ m: "auto", textAlign: "center" }}>
                                            <Button
                                                variant="contained"
                                                sx={{ mb: 2, mt: 4, }}
                                                onClick={this.onCreateNewProfileClicked.bind(this)}
                                            >
                                                Create new Avatar?
                                            </Button>
                                        </Box>
                                        : null
                                    }


                                    <iframe
                                        id="frame"
                                        allow="camera *; microphone *; clipboard-write"
                                        ref={this.iframeRef}
                                        title="Avatar"
                                        style={{
                                            width: "100%",
                                            height: "750px",
                                            margin: "0",
                                            border: "none",
                                            opacity: this.state.showSetupAvatar ? 1 : 0
                                        }}>
                                    </iframe>
                                </BaseBox>
                            </Box>
                        </Grid>

                        <Grid xs={12} md={8}>
                            <Box
                                sx={{
                                    paddingTop: 2,
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                    paddingBottom: 2,
                                }}
                                component={Paper}
                            >
                                {this.state.user ?
                                    <Box sx={{ width: "100%" }}>
                                        <UserForm
                                            user={this.state.user}
                                            onUpdated={this.onUserUpdated.bind(this)}
                                        />
                                    </Box>
                                    : null
                                }

                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </>
        );
    }
}