import React, { Component, ReactNode } from "react";
import { Box, List, Typography } from "@mui/material";
import { Campus } from "../../../../api/models/Campus";
import { CampusRowItem } from "./CampusRowItem";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

export interface CampusListProps
{
    campuses: Array<Campus>;
    onCampusClicked: (campus: Campus) => void;
}

interface CampusListState 
{
}

export class CampusList extends Component<CampusListProps, CampusListState>
{
    constructor(props: CampusListProps)
    {
        super(props);

        this.state = {
        };
    }

    private buildList(): ReactNode 
    {
        let rows: Array<ReactNode> = [];
        for (let i = 0; i < this.props.campuses.length; i++)
        {
            const campus: Campus = this.props.campuses[i];

            rows.push(
                <Grid xs={12} sm={6} md={6} lg={4} key={"grid_campus_row_item" + campus.id}>
                    <CampusRowItem
                        key={"campus_row_item" + campus.id}
                        campus={campus}
                        onClick={this.props.onCampusClicked.bind(this, campus)}
                    />
                </Grid>
            );
        }
        return rows;
    }

    public render(): JSX.Element
    {
        return (
            <Grid container spacing={2} sx={{ mt: 2 }}>
                {this.buildList()}
            </Grid>
        );
    }
}