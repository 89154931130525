import React, { Component } from "react";
import { Asset } from "../../../../api/models/Asset";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { App } from "../../../../App";

export interface AssetThumbnailProps
{
    asset: Asset;
}

interface AssetThumbnailState 
{
}

export class AssetThumbnail extends Component<AssetThumbnailProps, AssetThumbnailState>
{
    constructor(props: AssetThumbnailProps)
    {
        super(props);

        this.state = {
        };
    }

    public render(): JSX.Element
    {
        let thumbnailUrl: string = this.props.asset?.thumbnail?.url;

        return (
            <div
                className="thumbnail"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#666286",
                    backgroundImage: `url(${thumbnailUrl})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "4px",
                    minHeight: "180px",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "100%",
                    height: "100%",
                    textAlign: "center"
                }}>
                {this.props.asset?.thumbnail?.url == null ?
                    <InsertDriveFileIcon sx={{ fontSize: 55, fill: "#9692b5" }} />
                    : null
                }
            </div>
        );
    }
}