import { BaseAPI } from "./BaseAPI";
import { StrapiAPIPostRequest } from "./response/StrapiAPIPostRequest";
import { APIResponse } from "./rest/APIResponse";
import { RestRequest } from "./rest/RestRequest";
import { StrapiAPIFilterQuery } from "./StrapiAPIFilterQuery";
import { StrapiAPIFilterQueryBuilder } from "./StrapiAPIFilterQueryBuilder";

export interface CreateSessionRequest
{
    name: string;
    description: string;
    start: Date;
    end: Date;
    host: number;
    campus: number;
    environment: number;
    attendees: Array<number>;
    presenters: Array<number>;
    asset_collections: Array<number>;
}

export interface UpdateSessionRequest
{
    id: number;
    name: string;
    description: string;
    start: Date;
    end: Date;
    attendees?: Array<number>;
    presenters?: Array<number>;
    environment: number;
    asset_collections: Array<number>;
}

export class SessionAPI extends BaseAPI
{
    constructor(
        baseUrl: string,
        headers: { [name: string]: string } = {}
    )
    {
        super(baseUrl, headers);
    }

    public async createSession(requestData: CreateSessionRequest): Promise<APIResponse>
    {
        const jsonRequest: StrapiAPIPostRequest = { data: requestData };
        let request: RestRequest = new RestRequest(this.url("/sessions"), this.headers);
        let response: APIResponse = await request.post(jsonRequest);
        return response;
    }

    public async updateSession(requestData: UpdateSessionRequest): Promise<APIResponse>
    {
        const jsonRequest: StrapiAPIPostRequest = { data: requestData };
        let request: RestRequest = new RestRequest(this.url(`/sessions/${requestData.id}`), this.headers);
        let response: APIResponse = await request.put(jsonRequest);
        return response;
    }

    public async deleteSessionById(id: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/sessions/${id}`), this.headers);
        let response: APIResponse = await request.delete();
        return response;
    }

    public async getSessions(): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url("/sessions?populate=*"), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async getSessionsByCampus(campusId: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/sessions/by-campus/${campusId}`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async getSessionsByQuery(queries: Array<StrapiAPIFilterQuery>): Promise<APIResponse>
    {
        let queryString: string = StrapiAPIFilterQueryBuilder.build(queries);
        let request: RestRequest = new RestRequest(this.url(`/sessions${queryString}`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async getSessionById(id: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/sessions/${id}?populate=environment,asset_collections`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async getSessionsByUser(): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/sessions/by-user?populate=environment`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async getSessionAttendeesById(id: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/sessions/${id}/attendees`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }
}
