import { Box, SxProps, Typography } from "@mui/material";
import React, { Component } from "react";
import PersonIcon from '@mui/icons-material/Person';

export interface CampusUserIconProps
{
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

interface CampusUserIconState
{
}

export class CampusUserIcon extends Component<CampusUserIconProps, CampusUserIconState>
{
    constructor(props: CampusUserIconProps)
    {
        super(props);

        this.state = {
        };
    }

    public render()
    {
        return (
            // <div>
            //     <img src={icon} alt="Campus icon" style={{ maxWidth: "100%", ...this.props.style }} />
            // </div>
            <div>
                <PersonIcon style={{ ...this.props.style }} />
            </div>
        );
    }
}