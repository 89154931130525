import { AssetCollection } from "./AssetCollection";
import { Campus } from "./Campus";
import { Environment } from "./Environment";

export class Session 
{
    constructor(
        private _id: number,
        private _name: string,
        private _description: string,
        private _start: Date,
        private _end: Date,
        private _campus: Campus,
        private _environment: Environment,
        private _asset_collections: Array<AssetCollection>
    )
    {
    }

    public static fromJson(json: any): Session
    {
        let environment = null;
        if (json.environment)
        {
            environment = new Environment(
                json.environment.data.id,
                json.environment.data.attributes.name,
                json.environment.data.attributes.assetBundle,
                json.environment.data.attributes.thumbnail,
                json.environment.data.attributes.environment_type
            );
        }
        return new Session(
            json.id,
            json.name,
            json.description,
            json.start,
            json.end,
            json.campus,
            environment,
            json.asset_collections
        );
    }

    public get id(): number { return this._id; }
    public get name(): string { return this._name; }
    public get description(): string { return this._description; }
    public get start(): Date { return this._start; }
    public get end(): Date { return this._end; }
    public get campus(): Campus { return this._campus; }
    public get environment(): Environment { return this._environment; }
    public get asset_collections(): Array<AssetCollection> { return this._asset_collections; }
}
