import React, { Component } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { App } from "../../../App";
import Header from "../../components/Header";
import { RouterProps } from "../../RouterProps";
import { Campus } from "../../../api/models/Campus";
import { CampusService } from "../../../services/CampusService";
import { withRouter } from "../../withRouter";
import { Breadcrumbs, Button, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { UserService } from "../../../services/UserService";
import { UserGroup } from "../../../api/models/UserGroup";
import { UserGroupService } from "../../../services/UserGroupService";
import { UserGroupForm } from "../user-groups/components/UserGroupForm";
import { BaseBox } from "../../components/BaseBox";
import { CampusAvatar } from "./components/CampusAvatar";
import { CampusAdminRoute } from "./components/CampusAdminRoute";

export interface CampusUserGroupsPageProps extends RouterProps
{
}

interface CampusUserGroupsPageState 
{
    currentCampus: Campus;
    userGroup: UserGroup;
    userGroups: Array<UserGroup>;
    loading: boolean;
    pageError: Error;
}

class CampusUserGroupsPage extends Component<CampusUserGroupsPageProps, CampusUserGroupsPageState>
{
    private campusService: CampusService;
    private userService: UserService;
    private userGroupService: UserGroupService;

    constructor(props: CampusUserGroupsPageProps)
    {
        super(props);

        this.campusService = new CampusService();
        this.userService = new UserService();
        this.userGroupService = new UserGroupService();

        this.state = {
            currentCampus: null,
            userGroup: null,
            userGroups: null,
            pageError: null,
            loading: true
        };

        App.I.setPageTitle("Campus User Manager");
    }

    public componentDidMount(): void
    {
        try
        {
            this.fetchData();
        }
        catch (err)
        {
            this.setState({
                pageError: err
            });
        }
    }

    private async fetchData(): Promise<void> 
    {
        try
        {
            let currentCampus = await this.getCurrentCampus();

            if (currentCampus == null)
            {
                new Error("Campus not found");
                return;
            }

            let userGroups = await this.getCampusUserGroups(currentCampus.id);

            this.setState({
                currentCampus,
                userGroups,
                loading: false
            });
        }
        catch (err)
        {
            this.setState({
                pageError: err
            });
        }
    }

    private async getCurrentCampus(): Promise<Campus>
    {
        let campusId: number = this.props.router.params.campusId;
        let campuses: Array<Campus> = await this.userService.getUserCampuses();
        let currentCampus: Campus = null;

        for (let i = 0; i < campuses.length; i++)
        {
            if (campuses[i].id == campusId)
            {
                currentCampus = campuses[i];
                break;
            }
        }

        return currentCampus;
    }

    private async getCampusUserGroups(campusId: number): Promise<Array<UserGroup>>
    {
        return await this.userGroupService.getUserGroupsByCampus(campusId);
    }

    private onUserGroupUpdated(): void 
    {
        App.I.sendNotification("User Groups updated");
        this.setState({
            loading: false
        });
        this.fetchData();
    }

    private onUserGroupCreated(): void 
    {
        this.setState({
            loading: true
        },
            () => this.onUserGroupUpdated()
        );
    }

    private async onManageUserGroupClicked(group: UserGroup): Promise<void> 
    {
        document.location = `/campuses/${this.state.currentCampus.id}/user-groups/${group.id}`;
    }

    private onUsersClicked(): void
    {
        document.location.href = `/campuses/${this.state.currentCampus.id}/users`;
    }

    private onInvitedUsersClicked(): void
    {
        document.location.href = `/campuses/${this.state.currentCampus.id}/invited-users`;
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Header />

                <Container component="main" maxWidth="xl">

                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4, mb: 4 }}>
                        <Link color="inherit" href="/">
                            Dashboard
                        </Link>
                        <Link color="inherit" href="/campuses">
                            Campuses
                        </Link>
                        <Link color="inherit" href={`/campuses/${this.state.currentCampus?.id}`}>
                            {this.state.currentCampus?.name}
                        </Link>
                        <Link color="inherit" href={`/campuses/${this.state.currentCampus?.id}/users`}>
                            User Manager
                        </Link>
                        <Typography color="text.primary">User Groups</Typography>
                    </Breadcrumbs>

                    <BaseBox style={{ marginBottom: "1rem" }}>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid xs={12} md={2}>
                                    <CampusAvatar file={this.state.currentCampus?.icon} />
                                </Grid>

                                <Grid xs={12} md={6}>
                                    <Box style={{ marginTop: "100px" }}>
                                        <Typography variant="h3" component="h2" fontWeight={600}>
                                            User Group Manager
                                        </Typography>
                                        <Typography sx={{ mt: 1 }} component="p">
                                            {this.state.currentCampus?.name}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </BaseBox>

                    <Grid xs={12} lg={12} sx={{ mb: 2 }}>
                        <Button variant="text" onClick={this.onUsersClicked.bind(this)}>
                            Users
                        </Button>
                        <Button variant="text" onClick={null}
                            sx={{ pt: 1, ml: 2, borderBottom: "4px solid #77ccbe", borderRadius: 0 }}>
                            User groups
                        </Button>
                        <Button variant="text" onClick={this.onInvitedUsersClicked.bind(this)}
                            sx={{ pt: 1, ml: 2 }}>
                            Invited users
                        </Button>
                    </Grid>

                    <Box>
                        <Grid container spacing={2}>
                            {
                                this.state.loading ? null :
                                    <CampusAdminRoute campus={this.state.currentCampus}>


                                        <Grid xs={12} md={3}>
                                            <Box
                                                component={Paper}
                                            >
                                                <Typography component="h6" variant="h6" sx={{ pl: 4, pt: 4 }}>
                                                    Create User Group
                                                </Typography>

                                                {this.state.currentCampus ?
                                                    <UserGroupForm
                                                        campus={this.state.currentCampus}
                                                        userGroup={this.state.userGroup}
                                                        onUpdated={this.onUserGroupCreated.bind(this)}
                                                    />
                                                    : null
                                                }
                                            </Box>
                                        </Grid>

                                        <Grid xs={12} md={9}>
                                            <Box component={Paper}>
                                                <Typography component="h6" variant="h6" sx={{ pl: 3, pt: 4 }}>
                                                    User Groups
                                                </Typography>
                                                {
                                                    this.state.userGroups?.length > 0 ?

                                                        <TableContainer component={Paper} sx={{ px: 3, py: 4 }}>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Name</TableCell>
                                                                        <TableCell></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {
                                                                        this.state.userGroups?.map((group: UserGroup) =>
                                                                            <TableRow key={group.id}>
                                                                                <TableCell>
                                                                                    {group.name}
                                                                                </TableCell>
                                                                                <TableCell sx={{ py: 1, textAlign: "right", width: "1%", whiteSpace: "nowrap" }}>
                                                                                    <Button variant="contained" sx={{ py: 0.25 }} onClick={this.onManageUserGroupClicked.bind(this, group)}>
                                                                                        Manage
                                                                                    </Button>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        :
                                                        <Typography component="p" sx={{ p: 2 }}>
                                                            No groups
                                                        </Typography>
                                                }
                                            </Box>
                                        </Grid>
                                    </CampusAdminRoute>
                            }
                        </Grid>
                    </Box>
                </Container>
            </>
        );
    }
}

export default withRouter<CampusUserGroupsPageProps>(CampusUserGroupsPage);