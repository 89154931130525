import React, { Component, ReactNode } from "react";
import { Asset } from "../../../../api/models/Asset";
import { AssetItem } from "./AssetItem";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { UserService } from "../../../../services/UserService";
import { AssetOrGroupSearchResult, SearchResultType } from "../../../../search/AssetOrGroupResult";
import { FilterVisibleState } from "./FilterBar";

export interface AssetListProps
{
    assets: Array<Asset>;
    onEditAsset?: (asset: Asset) => void;
    onRemoveAsset?: (asset: Asset) => void;
    onDeleteAsset?: (asset: Asset) => void;
    searchResults?: Array<AssetOrGroupSearchResult>;
    filter?: FilterVisibleState;
    searching?: boolean;
    canEditGroup?: boolean;
}

interface AssetListState 
{
    rows: Array<ReactNode>;
}

export class AssetList extends Component<AssetListProps, AssetListState>
{
    constructor(props: AssetListProps)
    {
        super(props);

        this.state = {
            rows: []
        };
    }

    public componentDidMount(): void
    {
        if (this.props.searching)
        {
            this.buildFromSearchResults();
        }
        else 
        {
            this.buildFromAssets();
        }
    }

    public componentDidUpdate(prevProps: Readonly<AssetListProps>, prevState: Readonly<AssetListState>, snapshot?: any): void
    {
        if (this.props.searchResults?.length !== prevProps.searchResults?.length
            || this.props.filter !== prevProps.filter)
        {
            if (this.props.searching === false)
            {
                this.buildFromAssets();
            }
            else
            {
                this.buildFromSearchResults();
            }
        }
    }

    private buildFromSearchResults(): void 
    {
        let results = [];

        for (let i = 0; i < this.props.searchResults.length; i++)
        {
            const result = this.props.searchResults[i];

            // This way the results are in order from the search array
            for (let j = 0; j < this.props.assets.length; j++)
            {
                const asset = this.props.assets[j];

                if (result.id === asset.id)
                {
                    results.push(asset);
                }
            }
        }

        this.setState({
            rows: this.buildRows(results),
        });
    }

    private buildFromAssets(): void 
    {
        this.setState({
            rows: this.buildRows(this.props.assets)
        });
    }

    private assetAllowed(asset: Asset): boolean 
    {
        if (this.props.filter === FilterVisibleState.PublicOnly && asset.isPublic === false)
        {
            return false;
        }

        if (this.props.filter === FilterVisibleState.PrivateOnly && asset.isPublic !== false)
        {
            return false;
        }

        // Both
        return true;
    }

    private buildRows(assets: Array<Asset>): Array<ReactNode> 
    {
        let rows: Array<ReactNode> = [];

        for (let i = 0; i < assets.length; i++)
        {
            const asset: Asset = assets[i];

            if (this.assetAllowed(asset) === false)
            {
                continue;
            }

            rows.push(
                <Grid xs={12} sm={4} lg={2} key={"grid_" + asset.id}>
                    <AssetItem
                        key={asset.id}
                        asset={asset}
                        onEditAsset={this.props.onEditAsset?.bind(this, asset)}
                        onRemoveAsset={this.props.onRemoveAsset?.bind(this, asset)}
                        onDeleteAsset={this.props.onDeleteAsset?.bind(this, asset)}
                        canEdit={asset.hasPermissionToEdit(UserService.user)}
                        canEditGroup={this.props.canEditGroup}
                    />
                </Grid>
            );
        }
        return rows;
    }

    public render(): JSX.Element
    {
        return (
            <Grid container spacing={0} sx={{ width: "100%" }}>
                {this.state.rows}
            </Grid>
        );
    }
}