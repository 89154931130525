import { APIHelper } from "./APIHelper";
import { BaseAPI } from "./BaseAPI";
import { APIResponse } from "./rest/APIResponse";
import { RestRequest } from "./rest/RestRequest";

export interface GetAssetRequest 
{
    page: number;
}

export interface CreateAssetRequest
{
    owner: number;
    name: string;
    keywords: string;
    description: string;
    campuses: Array<number>;
    is_public: boolean;
    physical_width_mm: number;
    physical_height_mm: number;
}

export interface UpdateAssetRequest
{
    id: number;
    name: string;
    description: string;
    start: Date;
    end: Date;
}

export class AssetAPI extends BaseAPI
{
    constructor(
        baseUrl: string,
        headers: { [name: string]: string } = {}
    )
    {
        super(baseUrl, headers);
    }

    public async createAsset(requestData: FormData): Promise<APIResponse>
    {
        let modifiedHeaders = APIHelper.removeHeader("Content-Type", this.headers)
        let request: RestRequest = new RestRequest(this.url("/assets"), modifiedHeaders);
        let response: APIResponse = await request.post(requestData, false);
        return response;
    }

    public async uploadAsset(requestData: FormData): Promise<APIResponse>
    {
        let modifiedHeaders = APIHelper.removeHeader("Content-Type", this.headers)
        let request: RestRequest = new RestRequest(this.url("/upload"), modifiedHeaders);
        let response: APIResponse = await request.post(requestData, false);
        return response;
    }

    public async getAssets(requestParams: GetAssetRequest): Promise<APIResponse>
    {
        let url: string = "/assets?populate=file,campuses,owner,is_public";

        if (requestParams)
        {
            let { page } = requestParams;
            url += `&page=${page}`;
        }

        let request: RestRequest = new RestRequest(this.url(url), this.headers);
        let response: APIResponse = await request.get();

        return response;
    }

    public async getAssetById(id: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/assets/${id}?populate=file,campuses,is_public`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async deleteAssetById(id: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/assets/${id}`), this.headers);
        let response: APIResponse = await request.delete();
        return response;
    }

    public async updateAsset(id: number, requestData: FormData): Promise<APIResponse>
    {
        let modifiedHeaders = APIHelper.removeHeader("Content-Type", this.headers)
        let request: RestRequest = new RestRequest(this.url(`/assets/${id}`), modifiedHeaders);
        let response: APIResponse = await request.put(requestData, false);
        return response;
    }

    public async getUserAssets(): Promise<APIResponse>
    {
        let modifiedHeaders = APIHelper.removeHeader("Content-Type", this.headers)
        let request: RestRequest = new RestRequest(this.url(`/assets/get-user-assets`), modifiedHeaders);
        let response: APIResponse = await request.get();
        return response;
    }
}
