export interface AssetOrGroupSearchResult
{
    id: number;
    name: string;
    keywords: string;
    description: string;
    type: SearchResultType;
}

export enum SearchResultType 
{
    Asset,
    AssetGroup
}