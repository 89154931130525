import React, { Component } from "react";
import Typography from "@mui/material/Typography";


export interface ErrorMessageProps
{
    message: string;
}

interface ErrorMessageState 
{
}

export class ErrorMessage extends Component<ErrorMessageProps, ErrorMessageState>
{
    constructor(props: ErrorMessageProps)
    {
        super(props);

        this.state = {
        };
    }

    public render()
    {
        return (
            <Typography
                variant="h6"
                sx={{ color: "red" }}
            >
                {this.props.message}
            </Typography>
        );
    }
}