import { Box, Typography } from "@mui/material";
import React, { Component } from "react";
import { User } from "../../api/models/User";
import Button from "@mui/material/Button";
import profileHeaderImage from "../assets/images/profile-header.png";
import { ReadyPlayMeAvatar } from "./ReadyPlayMeAvatar";
import { Campus } from "../../api/models/Campus";
import { BaseAvatar } from "./BaseAvatar";
import VerifiedUser from "@mui/icons-material/VerifiedUser";
import hatSVG from "../assets/images/hat-green.svg";
import { BaseBox } from "./BaseBox";

export interface CampusBoxProps
{
    campus: Campus;
    onCampusClicked: () => void;
}

interface CampusBoxState 
{
}

export class CampusBox extends Component<CampusBoxProps, CampusBoxState>
{
    constructor(props: CampusBoxProps)
    {
        super(props);

        this.state = {
        };
    }

    public render()
    {
        return (
            <BaseBox>
                <BaseAvatar style={{ marginTop: "30px", marginLeft: "auto", marginRight: "auto" }}>
                    <img src={hatSVG} />
                </BaseAvatar>

                <Typography component="h1" variant="h4" align="center">
                    {this.props.campus.name}
                </Typography>

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={this.props.onCampusClicked.bind(this)}
                    sx={{ mt: 3, mb: 2, alignSelf: "flex-end" }}
                >
                    See more
                </Button>
            </BaseBox>
        );
    }
}