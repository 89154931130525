import { Box } from "@mui/material";
import React, { Component } from "react";


export interface SpacerProps
{
    height: number;
}

interface SpacerState 
{
}

export class Spacer extends Component<SpacerProps, SpacerState>
{
    constructor(props: SpacerProps)
    {
        super(props);

        this.state = {
        };
    }

    public render()
    {
        return (
            <Box style={{ marginTop: this.props.height, marginBottom: this.props.height }} />
        );
    }
}