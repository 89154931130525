import { Box, SxProps, Typography } from "@mui/material";
import React, { Component } from "react";
import icon from "../../assets/images/subscription-icon.svg";
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';

export interface CampusSubscriptionIconProps
{
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

interface CampusSubscriptionIconState
{
}

export class CampusSubscriptionIcon extends Component<CampusSubscriptionIconProps, CampusSubscriptionIconState>
{
    constructor(props: CampusSubscriptionIconProps)
    {
        super(props);

        this.state = {
        };
    }

    public render()
    {
        return (
            // <div>
            //     <img src={icon} alt="Campus subscription icon" style={{ maxWidth: "100%", ...this.props.style }} />
            // </div>
            <div>
                <SubscriptionsIcon sx={{ ...this.props.style }} />
            </div>
        );
    }
}