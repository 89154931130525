import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import { Session } from "../../../../api/models/Session";
import { Box, Divider, ListItem } from "@mui/material";
import hatSVG from "../../../assets/images/hat.svg";
import hatGreenSVG from "../../../assets/images/hat-green.svg";
import dayjs from "dayjs";

export interface SessionRowItemProps
{
    session: Session;
    showDayOnly?: boolean;
    active?: boolean;
    onClick: (session: Session) => void;
}

interface SessionRowItemState 
{
}

export class SessionRowItem extends Component<SessionRowItemProps, SessionRowItemState>
{
    constructor(props: SessionRowItemProps)
    {
        super(props);

        this.state = {
        };
    }

    public render(): JSX.Element
    {
        const activeStyle: React.CSSProperties =
        {
            background: "#77CCBE",
            color: "#1E2146",
        };

        const inactiveStyle: React.CSSProperties =
        {
            background: "#1E2146",
            color: "#fff",
        };

        const style: React.CSSProperties =
        {
            borderRadius: "25px",
            padding: "0.8rem",
            paddingLeft: "4rem",
            width: "99%",
            ...this.props.active ? activeStyle : inactiveStyle
        };

        return (
            <>
                <ListItem button onClick={this.props.onClick.bind(this)}>

                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        top: 0,
                    }}>
                        <div style={{
                            background: this.props.active ? "#81d9ca" : "rgb(72 74 108)",
                            border: this.props.active ? "4px solid rgb(113 197 183)" : "4px solid #1E2146",
                            width: "65px",
                            height: "65px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "100%",
                        }}>
                            {this.props.active ? <img src={hatGreenSVG} alt="Green hat" /> : <img src={hatSVG} alt="Blue hat" />}
                        </div>
                    </div>

                    <Box style={{ display: "flex", flexDirection: "column", ...style }}>
                        <Typography variant="h5" component="h5" style={{ fontWeight: "600" }}>
                            {this.props.session.name}
                        </Typography>
                        <Typography component="p" sx={{ fontSize: "0.8rem" }}>
                            {this.props.showDayOnly ?
                                <>
                                    {dayjs(this.props.session.start).format("hh:mma")}
                                    {" - "}
                                    {dayjs(this.props.session.end).format("hh:mma")}
                                </>
                                :
                                <>
                                    {dayjs(this.props.session.start).format("DD/MM/YYYY hh:mma")}
                                    {" - "}
                                    {dayjs(this.props.session.end).format("DD/MM/YYYY hh:mma")}
                                </>
                            }
                        </Typography>
                    </Box>
                </ListItem>

                <Divider light />
            </>
        );
    }
}