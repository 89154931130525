import { StrapiAPIFilterQuery } from "./StrapiAPIFilterQuery";

export class StrapiAPIFilterQueryBuilder
{
    public static build(filters: Array<StrapiAPIFilterQuery>): string
    {
        let filterString = "?";
        for (let i = 0; i < filters.length; i++)
        {
            let query: StrapiAPIFilterQuery = filters[i];
            if (i == 0)
            {
                filterString += `filters[${query.field}][${query.operator}]=${query.value}`
            }
            else 
            {
                filterString += `&filters[${query.field}][${query.operator}]=${query.value}`
            }
        }
        return filterString;
    }
} 