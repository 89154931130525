import { AppAPI } from "../api/AppAPI";
import { APIResponse } from "../api/rest/APIResponse";
import { APIErrorResponseHandler } from "../api/APIErrorResponseHandler";
import { Environment, EnvironmentType } from "../api/models/Environment";

export class EnvironmentService 
{
    public async getCampusEnvironments(campusId: number): Promise<Array<Environment>>
    {
        const response: APIResponse = await AppAPI.I.environments.getCampusEnvironments(campusId);
        APIErrorResponseHandler.validate(response);

        const jsonResponse: Array<any> = response.json;

        let environments: Array<Environment> = [];
        for (let i = 0; i < jsonResponse.length; i++)
        {
            let json = jsonResponse[i];

            let environment: Environment = Environment.fromJson(json);
            environments.push(environment);
        }

        return environments;
    }
}