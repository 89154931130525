import { Logger } from "./Logger";

export class DIEvent
{
    public target: EventDispatcher = null as any;
    private defaultPrevented: boolean = false;

    constructor(private _type: string)
    {

    }

    public preventDefault(): void
    {
        this.defaultPrevented = true;
    }

    public isDefaultPrevented(): boolean { return this.defaultPrevented; }
    public get type(): string { return this._type; }
}

class EventListener
{
    constructor(public type: string, public listener: Function, public thisArg: any) { }
}

export interface IEventDispatcher
{
    hasEventListener(type: string, listener: Function): boolean;
    addEventListener(type: string, listener: Function, thisArg?: any): void;
    removeEventListener(type: string, listener: Function): void;
    dispatchEvent(event: DIEvent): void;
}

export class EventDispatcher implements IEventDispatcher
{
    private listeners: Array<EventListener>;

    public on: Function;

    constructor()
    {
        this.listeners = Array<EventListener>();
        this.on = this.addEventListener;
    }

    public hasEventListener(type: string, listener: Function): boolean
    {
        for (var i: number = 0, l: number = this.listeners.length; i < l; i++)
        {
            if (this.listeners[i].type === type && this.listeners[i].listener === listener)
            {
                return true;
            }
        }
        return false;
    }

    public addEventListener(type: string, listener: Function, thisArg: any = null): void
    {
        if (this.hasEventListener(type, listener))
        {
            Logger.warn("DIEvent ** listener exists:, " + type, listener);
            return;
        }
        this.listeners.push(new EventListener(type, listener, thisArg));
    }

    public removeEventListener(type: string, listener: Function): void
    {
        for (var i: number = 0; i < this.listeners.length; i++)
        {
            if (this.listeners[i].listener === listener && this.listeners[i].type === type)
            {
                this.listeners.splice(i, 1);
                return;
            }
        }
    }

    public dispatchEvent(event: DIEvent): void
    {
        event.target = this;
        // clone so listeners notified regardless of modifications to subscription within loop
        var listeners: EventListener[] = this.listeners.slice(0);
        for (var i: number = 0; i < listeners.length; i++)
        {
            if (listeners[i].type === event.type)
            {
                listeners[i].listener.call(listeners[i].thisArg, event);
            }
        }
    }
}