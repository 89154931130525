import React, { Component } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { App } from "../../../App";
import Header from "../../components/Header";
import Paper from "@mui/material/Paper";
import { SessionForm } from "./components/SessionForm";
import { Session } from "../../../api/models/Session";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Page } from "../../../Page";
import { withRouter } from "../../withRouter";
import { Campus } from "../../../api/models/Campus";
import { CampusService } from "../../../services/CampusService";
import { RouterProps } from "../../RouterProps";
import { Breadcrumbs } from "@mui/material";
import Link from '@mui/material/Link';


export interface SessionCreatePageProps extends RouterProps
{
}

interface SessionCreatePageState 
{
    campus: Campus;
    loading: boolean;
    pageError: Error;
}

class SessionCreatePage extends Component<SessionCreatePageProps, SessionCreatePageState>
{
    private campusService: CampusService;

    constructor(props: SessionCreatePageProps)
    {
        super(props);

        this.campusService = new CampusService();

        this.state = {
            campus: null,
            loading: true,
            pageError: null
        };

        App.I.setPageTitle("Create session");
    }

    public componentDidMount(): void
    {
        this.init();
    }

    private async init(): Promise<void>
    {
        await this.getCampus();

        this.setState({
            loading: false
        });
    }

    private async getCampus(): Promise<void> 
    {
        try
        {
            const campusId: number = this.props.router.params.campusId;
            const campus: Campus = await this.campusService.getCampusById(campusId);

            this.setState({
                campus,
            });
        }
        catch (error)
        {
            this.setState({
                pageError: error,
                loading: false
            });
        }
    }

    private async onSessionCreated(session: Session): Promise<void>
    {
        document.location.href = `/campuses/${this.state.campus.id}/sessions/${session.id}`;
    }

    public render(): JSX.Element
    {
        console.log(this.state.campus);
        return (
            <>
                <Header />

                <Container component="main" maxWidth="xl">
                    <Typography component="h1" variant="h3" sx={{ mt: 4 }}>
                        Create session
                    </Typography>

                    {this.state.campus ?
                        <Grid container spacing={2}>
                            <Grid xs={12} md={12}>
                                <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4, mb: 4 }}>
                                    <Link color="inherit" href="/">
                                        Dashboard
                                    </Link>
                                    <Link color="inherit" href={`/campuses`}>
                                        Campuses
                                    </Link>
                                    <Link color="inherit" href={`/campuses/${this.state.campus.id}`}>
                                        {this.state.campus.name}
                                    </Link>
                                    <Link color="inherit" href={`/campuses/${this.state.campus.id}/sessions`}>
                                        Sessions
                                    </Link>
                                    <Typography color="text.primary">Create session</Typography>
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                        : null
                    }

                    {this.state.campus ?
                        <Grid container spacing={2}>
                            <Grid xs={12} md={12}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                    component={Paper}
                                >
                                    <SessionForm
                                        onSessionCreated={this.onSessionCreated.bind(this)}
                                        campus={this.state.campus}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        : null
                    }
                </Container>
            </>
        );
    }
}

export default withRouter<SessionCreatePageProps>(SessionCreatePage);
