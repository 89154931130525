import { getCookie, setCookie, removeCookie } from "typescript-cookie";

export class AppStorage 
{
    private static readonly JWT_KEY: string = "token";

    public saveToken(token: string): void
    {
        setCookie(AppStorage.JWT_KEY, token);
    }

    public clearToken(): void 
    {
        return removeCookie(AppStorage.JWT_KEY);
    }

    public get token(): string
    {
        return getCookie(AppStorage.JWT_KEY) ?? null;
    }
}