import { Box, SxProps, Typography } from "@mui/material";
import React, { Component } from "react";
import Avatar from "@mui/material/Avatar";

export interface BaseAvatarProps
{
    style?: SxProps<any>;
    children?: React.ReactNode;
}

interface BaseAvatarState
{
}

export class BaseAvatar extends Component<BaseAvatarProps, BaseAvatarState>
{
    constructor(props: BaseAvatarProps)
    {
        super(props);

        this.state = {
        };
    }

    public render()
    {
        return (
            <Avatar sx={{ width: 94, height: 94, border: "3px solid #77CCBE", backgroundColor: "#fff", ...this.props.style }}>
                {this.props.children}
            </Avatar>
        );
    }
}