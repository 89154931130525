import React, { Component } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { Logger } from "../diverse/Logger";
import { App } from "../App";
import { LoadingPage } from "./pages/LoadingPage";
import { UserService } from "../services/UserService";
import { User } from "../api/models/User";
import { BaseTheme } from "./BaseTheme";
import { Page } from "../Page";
import { DashboardPage } from "./pages/DashboardPage";
import { Alert, CssBaseline, Snackbar } from "@mui/material";
import { NotificationEvent } from "../events/NotificationEvent";
import { UserPage } from "./pages/users/UserPage";
import { UserEvent } from "../events/UserEvent";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ForgotPasswordPage } from "./pages/auth/ForgotPasswordPage";
import { RegisterPage } from "./pages/auth/RegisterPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import SessionCreatePage from "./pages/sessions/SessionCreatePage";
import SessionDetailPage from "./pages/sessions/SessionDetailPage";
import { LoginPage } from "./pages/auth/LoginPage";
import { CampusPage } from "./pages/campus/CampusPage";
import CampusDetailPage from "./pages/campus/CampusDetailPage";
import CampusEditPage from "./pages/campus/CampusEditPage";
import CampusUserManagerPage from "./pages/campus/CampusUserManagerPage";
import InvitePage from "./pages/auth/InvitePage";
import CampusImportUsersPage from "./pages/campus/CampusImportUsersPage";
import AssetsPage from "./pages/assets/AssetsPage";
import AssetGroupsPage from "./pages/assets/AssetGroupsPage";
import SessionsPage from "./pages/sessions/SessionsPage";
import CampusUserGroupsPage from "./pages/campus/CampusUserGroupsPage";
import { ResetPasswordPage } from "./pages/auth/ResetPasswordPage";
import CampusCreatePage from "./pages/campus/CampusCreatePage";
import { PostRegisterPage } from "./pages/auth/PostRegisterPage";
import { InviteUserPage } from "./pages/campus/InviteUserPage";
import UserGroupEditPage from "./pages/user-groups/UserGroupEditPage";
import CampusInvitedUsersPage from "./pages/campus/CampusInvitedUsersPage";

export interface MainProps
{
    onUIReadyCB: () => void;
}

interface MainState 
{
    user: User;
    isLoggedIn: boolean;
    loading: boolean;
    showNotification: boolean;
    notification: string;
}

export class MainUI extends Component<MainProps, MainState>
{
    constructor(props: MainProps)
    {
        super(props);

        this.state = {
            isLoggedIn: false,
            loading: true,
            showNotification: false,
            notification: null,
            user: null
        };
    }

    public componentDidMount(): void
    {
        this.props.onUIReadyCB();

        App.I.addEventListener(UserEvent.LOGGED_IN, this.onUserLoggedIn, this);
        App.I.addEventListener(UserEvent.LOGGED_OUT, this.onUserLoggedOut, this);
        App.I.addEventListener(NotificationEvent.RECEIVED, this.onNotificationReceived, this);
    }

    private onUserLoggedIn(): void 
    {
        this.setState({
            isLoggedIn: true,
            loading: false,
            user: UserService.user
        });
    }

    private onUserLoggedOut(): void 
    {
        this.setState({
            isLoggedIn: false,
            loading: false,
            user: null
        });
    }

    private onNotificationReceived(event: NotificationEvent): void 
    {
        this.setState({
            notification: event.message,
            showNotification: true
        })
    }

    private onCloseNotificationn(): void 
    {
        this.setState({
            showNotification: false,
            notification: null
        });
    }

    public render(): JSX.Element
    {
        return (
            <ThemeProvider theme={BaseTheme()}>
                {/* TODO */}
                <CssBaseline />

                <LocalizationProvider dateAdapter={AdapterDateFns}>

                    <BrowserRouter>
                        <Routes>
                            {this.state.loading === false ?
                                <>
                                    <Route path={Page.Dashboard} element={this.state.isLoggedIn ? <DashboardPage /> : <Navigate to={Page.Login} />} />
                                    <Route path={Page.Login} element={this.state.isLoggedIn ? <Navigate to={Page.Dashboard} /> : <LoginPage />} />
                                    <Route path={Page.Register} element={this.state.isLoggedIn ? <Navigate to={Page.Dashboard} /> : <RegisterPage />} />
                                    <Route path={Page.ForgotPassword} element={this.state.isLoggedIn ? <Navigate to={Page.Dashboard} /> : <ForgotPasswordPage />} />
                                    <Route path={Page.CampusInvite} element={this.state.isLoggedIn ? <InviteUserPage /> : <InvitePage />} />
                                    <Route path={Page.ResetPassword} element={this.state.isLoggedIn ? <Navigate to={Page.Dashboard} /> : <ResetPasswordPage />} />
                                    <Route path={Page.PostRegister} element={<PostRegisterPage />} />

                                    {this.state.isLoggedIn ?
                                        <>
                                            {/* Campuses */}
                                            <Route path={Page.Campuses} element={<CampusPage />} />
                                            <Route path={Page.CampusDetailRoute} element={<CampusDetailPage />} />
                                            <Route path={Page.CampusCreateRoute} element={<CampusCreatePage />} />
                                            <Route path={Page.CampusEditRoute} element={<CampusEditPage />} />
                                            <Route path={Page.CampusEditUsersRoute} element={<CampusUserManagerPage />} />
                                            <Route path={Page.CampusEditUserGroupsRoute} element={<CampusUserGroupsPage />} />
                                            <Route path={Page.CampusInvitedUsersRoute} element={<CampusInvitedUsersPage />} />
                                            <Route path={Page.CampusUserGroupEdit} element={<UserGroupEditPage />} />
                                            <Route path={Page.CampusImportUsersRoute} element={<CampusImportUsersPage />} />

                                            {/* Sessions */}
                                            <Route path={Page.CampusSessions} element={<SessionsPage />} />
                                            <Route path={Page.CampusSessionCreate} element={<SessionCreatePage />} />
                                            <Route path={Page.CampusSessionDetailRoute} element={<SessionDetailPage />} />


                                            {/* Campus Asset Library */}
                                            <Route path={Page.CampusAssetsRoute} element={<AssetsPage />} />
                                            <Route path={Page.CampusAssetGroupsRoute} element={<AssetGroupsPage />} />
                                            <Route path={Page.CampusAssetGroupsEditRoute} element={<AssetGroupsPage />} />

                                            {/* Users */}
                                            <Route path={Page.User} element={<UserPage />} />
                                            <Route path="*" element={<NotFoundPage />} />
                                        </>
                                        : null
                                    }
                                </>
                                : null
                            }
                        </Routes>
                    </BrowserRouter>

                    <Snackbar
                        open={this.state.showNotification}
                        autoHideDuration={6000}
                        onClose={this.onCloseNotificationn.bind(this)}
                        action={"Close"}
                    >
                        <Alert severity="success" style={{ background: "green", color: "#fff", fontWeight: "bold" }}>
                            {this.state.notification}
                        </Alert>
                    </Snackbar>

                </LocalizationProvider>
            </ThemeProvider>
        );
    }

    public componentWillUnmount(): void
    {
        App.I.removeEventListener(UserEvent.LOGGED_IN, this.onUserLoggedIn);
        App.I.removeEventListener(UserEvent.LOGGED_OUT, this.onUserLoggedOut);
        App.I.removeEventListener(NotificationEvent.RECEIVED, this.onNotificationReceived);
    }
}