import React, { Component } from "react";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Box, FormControlLabel, Paper, Switch, Typography } from "@mui/material";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { Campus } from "../../../../api/models/Campus";
import { CampusService, CampusUser } from "../../../../services/CampusService";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export interface EditCampusUserFormProps
{
    user: CampusUser;
    onUserUpdated: () => void;
    campus: Campus;
}

interface EditCampusUserFormState 
{
    errorMessage: string;
    isAdmin: boolean;
}

export class EditCampusUserForm extends Component<EditCampusUserFormProps, EditCampusUserFormState>
{
    private campusService: CampusService;

    constructor(props: EditCampusUserFormProps)
    {
        super(props);

        this.campusService = new CampusService();

        this.state = {
            errorMessage: null,
            isAdmin: this.props.user.isAdmin
        };
    }

    public componentDidMount(): void
    {

    }

    private async onSaveUserClicked(e: React.MouseEvent): Promise<void> 
    {
        e.preventDefault();
        await this.saveUser();
    }

    private async saveUser(): Promise<void>
    {
        try
        {
            if (this.state.isAdmin != this.props.user.isAdmin)
            {
                await this.campusService.setCampusUserStatus({
                    userId: this.props.user.id,
                    campusId: this.props.campus.id,
                    isAdmin: this.state.isAdmin
                });
            }

            this.props.onUserUpdated();
        }
        catch (error)
        {
            this.setState({
                errorMessage: error.message
            });
        }
    }

    private onAdminSwitchChanged(e): void
    {
        this.setState({
            isAdmin: e.currentTarget.checked
        });
    }

    private async onRemoveUserClicked(e: React.MouseEvent): Promise<void>
    {
        await this.removeUser();
    }

    private async removeUser(): Promise<void>
    {
        try
        {
            await this.campusService.removeCampusUser({
                userId: this.props.user.id,
                campusId: this.props.campus.id,
            });

            this.props.onUserUpdated();
        }
        catch (error)
        {
            this.setState({
                errorMessage: error.message
            });
        }
    }

    public render(): JSX.Element
    {
        return (
            <Grid container component="main">
                <Grid xs={12}>
                    <Box
                        sx={{
                            mt: 4,
                            mx: 0,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ width: "100%", mt: 1 }} >

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: 4,
                                    position: "relative"
                                }}
                                component={Paper}
                            >
                                <Box>
                                    <Typography component="h2" variant="h6">
                                        {this.props.user.name}
                                    </Typography>

                                    <Typography sx={{ fontSize: "0.8rem" }}>
                                        {this.props.user.email}
                                    </Typography>

                                    <FormControlLabel
                                        sx={{ ml: 0, mt: 4 }}
                                        labelPlacement="start"
                                        control={<Switch defaultChecked={this.props.user.isAdmin} />}
                                        label="Admin"
                                        onChange={this.onAdminSwitchChanged.bind(this)}
                                    />
                                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button
                                            onClick={this.onSaveUserClicked.bind(this)}
                                            variant="contained"
                                            sx={{ mt: 0 }}
                                        >
                                            Save
                                        </Button>
                                        {this.state.errorMessage ?
                                            <ErrorMessage message={this.state.errorMessage} />
                                            : null
                                        }
                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-start" }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ backgroundColor: "#D66", m: 0 }}
                                    onClick={this.onRemoveUserClicked.bind(this)}
                                >
                                    Remove from campus <DeleteForeverIcon />
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid >
        );
    }
}