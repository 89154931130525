import
{
    IcosahedronGeometry,
    Mesh,
    PerspectiveCamera,
    Scene,
    WebGLRenderer,
    Color,
    Vector3,
    HemisphereLight,
    MeshPhysicalMaterial,
    DirectionalLight
} from "three";

interface Shape
{
    position: Vector3;
    colour: Color;
    detail: number;
    radius: number;
    rotationSpeed: number;
    rotationAxis: Vector3;
    mesh: Mesh;
    metalness: number;
    roughness: number;
}

export class ShapeAnimation 
{
    private camera!: PerspectiveCamera;
    private scene!: Scene;
    private renderer!: WebGLRenderer;
    private shapes: Array<Shape> = [
        {
            radius: 1,
            colour: new Color("#E700FF"), // Pink
            detail: 1,
            position: new Vector3(-6, -3, -2),
            rotationSpeed: 0.3 + Math.random() * 0.7,
            rotationAxis: new Vector3(Math.random() * 2 - 1, Math.random() * 2 - 1, Math.random() * 2 - 1).normalize(),
            mesh: null,
            metalness: 0.8,
            roughness: 0.8
        },
        {
            radius: 1.25,
            colour: new Color("#4893ff"), // Blue
            detail: 0,
            position: new Vector3(3.5, 6, -6),
            rotationSpeed: 0.3 + Math.random() * 0.7,
            rotationAxis: new Vector3(Math.random() * 2 - 1, Math.random() * 2 - 1, Math.random() * 2 - 1).normalize(),
            mesh: null,
            metalness: 0.65,
            roughness: 0.8
        },
        {
            radius: 0.8,
            colour: new Color("#56A8A0"), // Green
            detail: 0,
            position: new Vector3(-4, 3.5, -4),
            rotationSpeed: 0.3 + Math.random() * 0.7,
            rotationAxis: new Vector3(Math.random() * 2 - 1, Math.random() * 2 - 1, Math.random() * 2 - 1).normalize(),
            mesh: null,
            metalness: 0.65,
            roughness: 0.8
        }
    ];
    private running: boolean = false;
    private animationFrame: number;
    private st: number = 0; // start time
    private et: number = 0; // elapsed time

    constructor(private canvas: HTMLElement)
    {
        this.init();
    }

    public start(): void 
    {
        cancelAnimationFrame(this.animationFrame);
        this.canvas.style.display = "block";
        this.running = true;
        this.animate(0);
    }

    public stop(): void 
    {
        this.canvas.style.display = "none";
        this.running = false;
        cancelAnimationFrame(this.animationFrame);
    }

    private onWindowResize(): void
    {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
    }

    private animate(timestamp: number): void
    {
        if (!this.running) return;

        if (this.st == 0) this.st = timestamp;
        if (timestamp - this.et > 16.667)
        {
            this.et = timestamp;

            for (let shape of this.shapes)
            {
                if (shape.mesh != null)
                {
                    shape.mesh.rotateOnAxis(shape.rotationAxis, shape.rotationSpeed * 0.01);
                }
            }
            this.renderer.render(this.scene, this.camera);
        }

        this.animationFrame = requestAnimationFrame((timestamp: number) => this.animate(timestamp));
    }

    private init(): void
    {
        this.camera = new PerspectiveCamera(35, window.innerWidth / window.innerHeight, 1, 100);
        this.camera.position.z = 20;
        this.scene = new Scene();

        for (let i = 0; i < this.shapes.length; i++)
        {
            let shape: Shape = this.shapes[i];
            let geometry: IcosahedronGeometry = new IcosahedronGeometry(shape.radius, shape.detail);
            let mesh: Mesh = new Mesh(geometry, new MeshPhysicalMaterial({
                color: shape.colour,
                flatShading: true,
                metalness: shape.metalness,
                roughness: shape.roughness,

            }));
            mesh.position.set(shape.position.x, shape.position.y, shape.position.z);
            this.scene.add(mesh);
            shape.mesh = mesh;
        }


        let light: DirectionalLight = new DirectionalLight(0xFFFFFF, 0.9);
        light.position.set(0.5, 0, 0.5).normalize();
        this.scene.add(light);

        let hLight: HemisphereLight = new HemisphereLight(0xFFFFFF, 0x999999, 3);
        this.scene.add(hLight);

        this.renderer = new WebGLRenderer({ alpha: true, antialias: true });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setClearColor(0x000000, 0);

        this.canvas.appendChild(this.renderer.domElement);
        this.canvas.style.display = "";
        window.addEventListener("resize", this.onWindowResize.bind(this));
    }
}