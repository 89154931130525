export class Validator
{
    public static hasValidMinInput(value: string, min: number): boolean
    {
        if (value == "" || value === undefined || value == null)
        {
            return false;
        }

        return (value.length >= min);
    }

    public static hasMatchingValues(value: string, valueConfirm: string): boolean
    {
        return (value === valueConfirm);
    }
}
