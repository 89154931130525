import React, { Component } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { UserService } from "../../services/UserService";
import { User } from "../../api/models/User";
import { App } from "../../App";
import Header from "../components/Header";
import { SessionService } from "../../services/SessionService";
import { Session } from "../../api/models/Session";
import { SessionList } from "./sessions/components/SessionList";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { ProfileBox } from "../components/ProfileBox";
import { Page } from "../../Page";
import { CampusBox } from "../components/CampusBox";
import { Paper } from "@mui/material";

export interface DashboardPageProps
{
}

interface DashboardPageState 
{
    user: User;
    loadingSessions: boolean;
    sessions: Array<Session>;
}

export class DashboardPage extends Component<DashboardPageProps, DashboardPageState>
{
    private sessionService: SessionService;

    constructor(props: DashboardPageProps)
    {
        super(props);

        this.sessionService = new SessionService();

        this.state = {
            user: null,
            loadingSessions: false,
            sessions: []
        };

        App.I.setPageTitle("Dashboard");
    }

    public componentDidMount(): void
    {
        this.setState({
            user: UserService.user,
            loadingSessions: true
        });

        this.getSessions();
    }

    private async getSessions(): Promise<void>
    {
        let sessions = await this.sessionService.getSessionsByUser();
        this.setState({
            sessions: sessions
        });
    }

    private onSessionClicked(session: Session): void 
    {
        let sessionId: string = session.id.toString();
        let campusId: string = session.campus.id.toString();
        document.location.href = `/campuses/${campusId}/sessions/${sessionId}`;
    }

    private onEditProfileClicked(): void 
    {
        App.I.changePage(Page.User);
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Header />

                <Container component="main" maxWidth="xl">
                    <Grid container spacing={2}>

                        <Grid xs={12} md={4} sx={{ pr: 5 }}>
                            <Typography component="h2" variant="h3" sx={{ mt: 4, mb: 4 }}>
                                My Profile
                            </Typography>

                            {this.state.user ?
                                <ProfileBox user={this.state.user} onEditProfileClicked={this.onEditProfileClicked.bind(this)} />
                                : null
                            }

                            {this.state.user?.userCampuses.length > 0 ?
                                <>
                                    <Typography component="h2" variant="h3" sx={{ mt: 4, mb: 4 }}>
                                        My virtual Campus
                                    </Typography>

                                    <CampusBox campus={this.state.user.userCampuses[0]} onCampusClicked={() => { }} />
                                </>
                                : null
                            }
                        </Grid>

                        <Grid xs={12} md={8}>
                            <Typography component="h2" variant="h3" sx={{ mt: 4, mb: 4 }}>
                                Sessions
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    p: 4
                                }}
                                component={Paper}
                            >
                                <SessionList
                                    sessions={this.state.sessions}
                                    onSessionClicked={this.onSessionClicked.bind(this)}
                                    dateFormatTodayOnly={false}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </>
        );
    }
}