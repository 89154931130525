import React, { Component } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography } from "@mui/material";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { Campus } from "../../../../api/models/Campus";
import { AssetGroupService } from "../../../../services/AssetGroupService";
import { AssetCollection } from "../../../../api/models/AssetCollection";
import { CreateAssetGroupRequest } from "../../../../api/AssetGroupAPI";
import { Asset } from "../../../../api/models/Asset";
import { UserService } from "../../../../services/UserService";

export interface GroupFormProps
{
    onGroupDeleted?: () => void;
    onGroupCreated?: (asset: AssetCollection) => void;
    onGroupUpdated?: (asset: AssetCollection) => void;
    campus: Campus;
    group?: AssetCollection;
    groups?: Array<AssetCollection>;
    campuses?: Array<Campus>;
    assets?: Array<Asset>;
    parentGroup: AssetCollection;
}

interface GroupFormState 
{
    name: string;
    description: string;
    assetList: Array<AssetList>;
    campusList: Array<CampusList>;
    groupList: Array<GroupList>;
    selectedCampusList: Array<CampusList>;
    selectedAssetList: Array<AssetList>;
    selectedGroupList: GroupList;
    errorMessage: string;
    saving: boolean;
}

interface GroupList 
{
    groupId: number;
    label: string;
}

interface CampusList 
{
    campusId: number;
    label: string;
}

interface AssetList 
{
    assetId: number;
    label: string;
}

export class GroupForm extends Component<GroupFormProps, GroupFormState>
{
    private form: React.RefObject<HTMLFormElement>;
    private fileRef: React.RefObject<HTMLInputElement>;
    private groupService: AssetGroupService;

    constructor(props: GroupFormProps)
    {
        super(props);

        this.form = React.createRef();
        this.groupService = new AssetGroupService();

        this.fileRef = React.createRef();

        this.state = {
            name: this.props.group?.name,
            description: this.props.group?.description,
            campusList: [],
            assetList: [],
            selectedCampusList: [],
            selectedAssetList: [],
            selectedGroupList: null,
            groupList: [],
            errorMessage: null,
            saving: false,
        };
    }

    public componentDidMount(): void
    {
        let selectedCampusIds = this.props.group?.campuses?.map((campus) => campus.id) ?? [];
        let selectedAssetIds = this.props.group?.assets?.map((asset) => asset.id) ?? [];
        let selectedGroupIds = this.props.groups?.map((group) => group.id) ?? [];

        if (this.props.campuses)
        {
            this.buildCampusList(
                this.props.campuses,
                selectedCampusIds
            );
        }

        if (this.props.groups)
        {
            this.buildGroupList(
                this.props.groups,
                selectedGroupIds
            );
        }

        if (this.props.assets)
        {
            this.buildAssetList(
                this.props.assets,
                selectedAssetIds
            );
        }
    }

    private buildCampusList(campuses: Array<Campus>, selectedCampusIds: Array<number>): void
    {
        let campusListItems: Array<CampusList> = [];
        let defaultCampusListItems: Array<CampusList> = [];

        for (let i = 0; i < campuses.length; i++)
        {
            const campus: Campus = campuses[i];

            let userList: CampusList = {
                label: campus.name,
                campusId: campus.id
            };

            if (selectedCampusIds.indexOf(campus.id) > -1)
            {
                defaultCampusListItems.push(userList);
            }

            campusListItems.push(userList);
        }

        this.setState({
            campusList: campusListItems,
            selectedCampusList: defaultCampusListItems
        });
    }

    private buildGroupList(groups: Array<AssetCollection>, selectedGroupIds: Array<number>): void
    {
        let groupListItems: Array<GroupList> = [];
        let selectedGroupList: GroupList = null;

        /*
        for (let i = 0; i < groups.length; i++)
        {
            const group: AssetGroup = groups[i];
        }*/

        this.setState({
            groupList: groupListItems,
            selectedGroupList: selectedGroupList
        });
    }

    private buildAssetList(assets: Array<Asset>, selectedAssetIds: Array<number>): void
    {
        let assetListItems: Array<AssetList> = [];
        let defaultAssetListItems: Array<AssetList> = [];

        for (let i = 0; i < assets.length; i++)
        {
            const asset: Asset = assets[i];

            let userList: AssetList = {
                label: asset.name,
                assetId: asset.id
            };

            if (selectedAssetIds.indexOf(asset.id) > -1)
            {
                defaultAssetListItems.push(userList);
            }

            assetListItems.push(userList);
        }

        this.setState({
            assetList: assetListItems,
            selectedAssetList: defaultAssetListItems
        });
    }

    private async onSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> 
    {
        e.preventDefault();

        this.setState({
            saving: true
        });

        let formData = new FormData();

        let currentFormData: FormData = new FormData(this.form.current);

        let createGroupRequest: CreateAssetGroupRequest = {
            name: (currentFormData.get("name") as string),
            description: (currentFormData.get("description") as string),
            campuses: [this.props.campus.id],
            parent_asset_groups: [],
            assets: [],
            owner: UserService.user.id,
        };

        if (this.props.parentGroup != null)
        {
            createGroupRequest.parent_asset_groups.push(this.props.parentGroup.id);
        }

        if (this.props.group != null)
        {
            // Fix for assets being removed on edit
            delete createGroupRequest.assets;
        }

        // If file has changed, update
        if (this.fileRef.current.files.length > 0)
        {
            formData.append("files.file", currentFormData.get("file"));
        }

        formData.append("data", JSON.stringify(createGroupRequest));

        if (this.props.group == null)
        {
            const groupId = await this.onCreate(formData);
            if (createGroupRequest.parent_asset_groups.length > 0) {
                await this.groupService.addAssetGroupsToAssetGroup(this.props.parentGroup.id, [groupId as number])
            }
        }
        else 
        {
            await this.onEdit(formData);
        }

        this.setState({
            saving: false
        });
    }

    private async onCreate(formData: FormData): Promise<number | void>
    {
        try
        {
            const group: AssetCollection = await this.groupService.create(formData);
            this.form.current.reset();
            this.props.onGroupCreated(group);
            return group.id;
        }
        catch (error)
        {
            this.setState({
                errorMessage: error.message,
                saving: false
            });
        }
    }

    private async onEdit(formData: FormData): Promise<void>
    {
        try
        {
            const group: AssetCollection = await this.groupService.update(this.props.group.id, formData);
            this.props.onGroupUpdated(group);
        }
        catch (error)
        {
            this.setState({
                errorMessage: error.message,
                saving: false
            });
        }
    }

    private onConfirmDelete(): void 
    {
        const confirmed = window.confirm("Are you sure you want delete this collection? This cannot be reversed.");
        if (confirmed)
        {
            this.onDelete();
        }
    }

    private async onDelete(): Promise<void>
    {
        try
        {
            await this.groupService.delete(this.props.group.id);
            this.props.onGroupDeleted();
        }
        catch (error)
        {
            this.setState({
                errorMessage: error.message,
                saving: false
            });
        }
    }

    public render(): JSX.Element
    {
        return (
            <Grid container component="main">
                <Grid item xs={12}>
                    <Box
                        sx={{
                            my: 4,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={this.onSubmit.bind(this)} ref={this.form} style={{ minWidth: "100%" }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="group-name"
                                label="Name"
                                name="name"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                autoComplete="group-name"
                                defaultValue={this.state.name}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="group-description"
                                label="Description"
                                name="description"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                autoComplete="group-description"
                                defaultValue={this.state.description}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="asset-file"
                                label="Collection thumbnail"
                                name="file"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                autoComplete="file"
                                type="file"
                                inputRef={this.fileRef}
                            />

                            {this.state.errorMessage ?
                                <ErrorMessage message={this.state.errorMessage} />
                                : null
                            }

                            <Button
                                fullWidth
                                type="submit"
                                disabled={this.state.saving}
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {this.props.group == null ?
                                    "Create"
                                    :
                                    "Save"
                                }
                            </Button>

                            {this.props.group ?
                                <>
                                    <Button
                                        fullWidth
                                        disabled={this.state.saving}
                                        variant="outlined"
                                        onClick={this.onConfirmDelete.bind(this)}
                                        color="error"
                                    >
                                        <DeleteIcon /> Delete collection
                                    </Button>

                                    <Typography sx={{ pt: 2, fontSize: 13 }} color="error">
                                        Note: This will delete the collection permanently. This cannot be reversed.
                                    </Typography>
                                </>
                                : null
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}