import { Box, Typography } from "@mui/material";
import React, { Component } from "react";
import { User } from "../../api/models/User";
import Button from "@mui/material/Button";
import profileHeaderImage from "../assets/images/profile-header.png";
import { ReadyPlayMeAvatar } from "./ReadyPlayMeAvatar";
import { Campus } from "../../api/models/Campus";
import { BaseAvatar } from "./BaseAvatar";
import VerifiedUser from "@mui/icons-material/VerifiedUser";
import hatSVG from "../assets/images/hat-green.svg";

export interface BaseBoxProps
{
    children?: React.ReactNode;
    style?: React.CSSProperties;
}

interface BaseBoxState 
{
}

export class BaseBox extends Component<BaseBoxProps, BaseBoxState>
{
    constructor(props: BaseBoxProps)
    {
        super(props);

        this.state = {
        };
    }

    public render()
    {
        return (
            <Box
                style={{
                    backgroundColor: "rgb(52, 55, 89)",
                    borderTop: "8px solid #77CCBE",
                    borderRadius: "4px",
                    width: "100%",
                    padding: "1rem 1.2rem",
                    position: "relative",
                    ...this.props.style
                }}
            >
                <div style={{
                    backgroundImage: `url(${profileHeaderImage})`,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    width: "100%",
                    height: "100px",
                    zIndex: 0,
                    backgroundPosition: "bottom right"
                }}></div>

                <div style={{ zIndex: 1, position: "relative" }}>
                    {this.props.children}
                </div>
            </Box>
        );
    }
}