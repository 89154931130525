import { APIHelper } from "./APIHelper";
import { BaseAPI } from "./BaseAPI";
import { APIResponse } from "./rest/APIResponse";
import { RestRequest } from "./rest/RestRequest";

export interface LoginRequest
{
    identifier: string;
    password: string;
}

export interface RegisterRequest
{
    username: string;
    name: string;
    email: string;
    password: string;
    passwordConfirmed: string;
}

export interface UpdateUserRequest
{
    id?: number;
    name?: string;
    email?: string;
    readyplayme_user_id?: number;
    readyplayme_glb_url?: string;
    user_groups?: Array<number>;
    role?: number;
}

export interface ForgotPasswordRequest
{
    email: string;
}

export interface ResetPasswordRequest
{
    code: string;
    password: string;
    passwordConfirmation: string;
}

export class UserAPI extends BaseAPI
{
    constructor(
        baseUrl: string,
        headers: { [name: string]: string } = {}
    )
    {
        super(baseUrl, headers);
    }

    public async login(requestData: LoginRequest): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url("/auth/local"), this.headers);
        let response: APIResponse = await request.post(requestData);
        return response;
    }

    public async register(requestData: RegisterRequest): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url("/auth/local/register"), this.headers);
        let response: APIResponse = await request.post(requestData);
        return response;
    }

    public async getUser(): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url("/users/me?populate=*"), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async getUserCampuses(): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url("/user-campuses"), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async getUsers(): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url("/users?populate=*"), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async getUserRoles(): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url("/users-permissions/roles"), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async getUsersByCampus(campusId: number): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/campuses/${campusId}/users?populate=*`), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async updateUser(requestData: UpdateUserRequest): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/users/${requestData.id}`), this.headers);
        let response: APIResponse = await request.put(requestData);
        return response;
    }

    public async updateUserAvatar(userId: number, requestData: FormData): Promise<APIResponse>
    {
        let modifiedHeaders = APIHelper.removeHeader("Content-Type", this.headers);
        //let request: RestRequest = new RestRequest(this.url(`/users/${userId}`), modifiedHeaders);
        let request: RestRequest = new RestRequest(this.url(`/upload`), modifiedHeaders);
        let response: APIResponse = await request.post(requestData, false);
        return response;
    }

    public async forgotPassword(requestData: ForgotPasswordRequest): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/auth/forgot-password`), this.headers);
        let response: APIResponse = await request.post(requestData);
        return response;
    }

    public async resetPassword(requestData: ResetPasswordRequest): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url(`/auth/reset-password`), this.headers);
        let response: APIResponse = await request.post(requestData);
        return response;
    }
}
