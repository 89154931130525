import React, { Component } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { App } from "../../../App";
import { PageErrorMessage } from "../../components/PageErrorMessage";
import Header from "../../components/Header";
import { RouterProps } from "../../RouterProps";
import { withRouter } from "../../withRouter";
import { CampusService } from "../../../services/CampusService";
import { Campus } from "../../../api/models/Campus";
import { CampusForm } from "./components/CampusForm";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Page } from "../../../Page";

export interface CampusCreatePageProps extends RouterProps
{
}

interface CampusCreatePageState 
{
    pageError: Error;
}

class CampusCreatePage extends Component<CampusCreatePageProps, CampusCreatePageState>
{
    constructor(props: CampusCreatePageProps)
    {
        super(props);

        this.state = {
            pageError: null,
        };
    }

    public componentDidMount(): void
    {
        App.I.setPageTitle("Create campus");
    }

    private async onCampusCreated(campus: Campus): Promise<void>
    {
        App.I.sendNotification(`Campus ${campus.name} created successfully`);
        document.location = Page.Campuses;
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Header />

                <Container component="main" maxWidth="xl">

                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4, mb: 4 }}>
                        <Link color="inherit" href="/">
                            Dashboard
                        </Link>
                        <Link color="inherit" href="/campuses">
                            Campuses
                        </Link>
                        <Typography color="text.primary">Create</Typography>
                    </Breadcrumbs>

                    <Grid container spacing={2}>
                        <Grid xs={12} md={12}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <CampusForm onCampusCreated={this.onCampusCreated.bind(this)} />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </>
        );
    }
}

export default withRouter<CampusCreatePageProps>(CampusCreatePage);