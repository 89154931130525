import { ErrorPassword } from "../errors/ErrorPassword";
import { Validator } from "./Validator";

export class PaswordValidation 
{
    /**
     * @throws @see ErrorPassword
     * @param password 
     */
    public static validate(password: string)
    {
        if (Validator.hasValidMinInput(password, 6) === false)
        {
            throw new ErrorPassword("Please enter a password at least 6 characters long");
        }
    }

    /**
     * @throws @see ErrorPassword
     * @param password 
     * @param confirm 
     */
    public static validatePasswords(password: string, confirm: string)
    {
        if (Validator.hasMatchingValues(password, confirm) === false)
        {
            throw new ErrorPassword("Please enter a password that matches your previous");
        }
    }
}