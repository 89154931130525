import Fuse from "fuse.js";
import { Search } from "./Search";

export type FuseOptionKeyObject<T> = {
    name: string | string[];
    weight?: number;
    getFn?: (obj: T) => ReadonlyArray<string> | string;
}

export type FuseOptionKey<T> = FuseOptionKeyObject<T> | string | string[];

export class FuseSearch<T> implements Search<T>
{
    private data: Array<T> = [];
    private fuse: Fuse<T>;
    private _searchTerm: string = "";
    private _results: Array<T> = [];

    constructor()
    {
    }

    public search(searchTerm: string): Array<T>
    {
        if (this.fuse == null)
        {
            console.error("Search not initialized, setData must be called");
            return;
        }

        this.setSearchParams(searchTerm);

        this._results = [];
        let results = this.fuse.search(searchTerm);
        results.filter(r => this._results.push(r.item)) as Array<T>;
    }

    public setData(data: T[], keys: Array<FuseOptionKey<T>> = []): void 
    {
        this.data = data;
        this.fuse = new Fuse(this.data, { keys: keys, includeScore: true, threshold: 0.1 });

        const url = new URL(window.location.toString());
        const searchTerm = url.searchParams.get("search");
        if (searchTerm)
        {
            this.setSearchParams(searchTerm);
        }
    }

    public resetSearchTerm(): void 
    {
        this.setSearchParams(null);
    }

    private setSearchParams(searchTerm: string | null): void 
    {
        const url = new URL(window.location.toString());
        if (searchTerm === null)
        {
            url.searchParams.delete("search");
        }
        else 
        {
            url.searchParams.set("search", searchTerm);
        }
        window.history.pushState(null, '', url.toString());
        this._searchTerm = searchTerm;
    }

    public get results(): Array<T> { return this._results ?? []; }
    public get searchTerm(): string { return this._searchTerm; }
}