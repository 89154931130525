import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1024,
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
};

export enum ModalSize 
{
    Small,
    Medium,
    Large
}

function getModalSize(size: ModalSize)
{
    if (size === ModalSize.Large)
    {
        return 768;
    }

    if (size == ModalSize.Medium)
    {
        return 576;
    }
}

export default function BasicModal({ children, onClose, size })
{
    return (
        <div>
            <Modal
                open={true}
                onClose={onClose}
                sx={{
                    margin: "auto"
                }}
            >
                <Box sx={{
                    ...style,
                    width: getModalSize(size),
                }}>
                    <Button style={{ position: "absolute", top: 10, right: 10 }} onClick={onClose}>
                        <CancelIcon style={{ fill: "#77CCBE" }} fontSize="large" />
                    </Button>
                    {children}
                </Box>
            </Modal>
        </div>
    );
}