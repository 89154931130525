import { Component } from "react";
import EditIcon from '@mui/icons-material/Edit';
import {ListItemIcon, ListItemText, Typography} from "@mui/material";

export class EditAction extends Component
{
    public render(): JSX.Element
    {
        return (
          <>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
                <Typography variant="body2">
                    Edit
                </Typography>
            </ListItemText>
          </>
        );
    }
}