import React, { Component } from "react";
import { User } from "../../api/models/User";
import { BaseAvatar } from "./BaseAvatar";
import FaceIcon from '@mui/icons-material/Face';

export interface ProfileImageProps
{
    user: User;
    width?: number;
    height?: number;
}

interface ProfileImageState
{
    width: number;
    height: number;
}

export class ProfileImage extends Component<ProfileImageProps, ProfileImageState>
{
    constructor(props: ProfileImageProps)
    {
        super(props);

        this.state = {
            width: this.props.width ?? 64,
            height: this.props.width ?? 64,
        };
    }

    public render(): JSX.Element
    {
        return (
            <BaseAvatar style={{ width: this.state.width, height: this.state.height, mb: 2, border: "3px solid #77CCBE", backgroundColor: "#fff" }}>
                {
                    this.props.user?.avatar != null ?
                        <div style={{ width: this.state.width, height: this.state.height, backgroundImage: `url(${this.props.user.avatar?.url})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                        </div>
                        : <FaceIcon sx={{ color: "#000" }} />
                }
            </BaseAvatar>
        );
    }
}
