import React, { Component } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Button } from "@mui/material";
import { Session } from "../../../api/models/Session";
import { App } from "../../../App";
import { Page } from "../../../Page";
import { SessionService } from "../../../services/SessionService";
import { PageErrorMessage } from "../../components/PageErrorMessage";
import Header from "../../components/Header";
import { SessionList } from "./components/SessionList";
import { CalendarIcon } from "../../components/icons/CalendarIcon";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { withRouter } from "../../withRouter";
import { Campus } from "../../../api/models/Campus";
import { RouterProps } from "../../RouterProps";
import { CampusService } from "../../../services/CampusService";

export interface SessionsPageProps extends RouterProps
{
}

interface SessionsPageState 
{
    sessions: Array<Session>;
    loading: boolean;
    pageError: Error;
    validationError: Error;
    campus: Campus;
}

class SessionsPage extends Component<SessionsPageProps, SessionsPageState>
{
    private sessionService: SessionService;
    private campusService: CampusService;

    constructor(props: SessionsPageProps)
    {
        super(props);

        this.sessionService = new SessionService();
        this.campusService = new CampusService();

        this.state = {
            campus: null,
            sessions: [],
            loading: true,
            pageError: null,
            validationError: null
        };

        App.I.setPageTitle("Sessions");
    }

    public componentDidMount(): void
    {
        this.fetchData();
    }

    private async fetchData(): Promise<void> 
    {
        try
        {
            let campus = await this.getCampus();
            let sessions = await this.getSessions(campus.id);

            this.setState({
                campus,
                sessions,
                loading: false
            });
        }
        catch (error)
        {
            this.setState({
                pageError: error,
                loading: false
            });
        }
    }

    private async getCampus(): Promise<Campus> 
    {
        const campusId: number = this.props.router.params.campusId;
        const campus: Campus = await this.campusService.getCampusById(campusId);
        return campus;
    }

    private async getSessions(campusId: number): Promise<Array<Session>>
    {
        return await this.sessionService.getSessionsByCampus(campusId);
    }

    private onSessionClicked(session: Session): void 
    {
        document.location.href = `/campuses/${this.state.campus.id}/sessions/${session.id.toString()}`;
    }

    private onCreateSessionClicked(session: Session): void 
    {
        document.location.href = `/campuses/${this.state.campus.id}/session/create`;
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Header currentCampus={this.state.campus} />

                <Container component="main" maxWidth="xl">

                    {this.state.loading === false ?
                        <>
                            {this.state.pageError != null ?
                                <PageErrorMessage error={this.state.pageError} />
                                :
                                <>
                                    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4, mb: 4 }}>
                                        <Link color="inherit" href="/">
                                            Dashboard
                                        </Link>
                                        <Link color="inherit" href={`/campuses`}>
                                            Campuses
                                        </Link>
                                        <Link color="inherit" href={`/campuses/${this.state.campus.id}`}>
                                            {this.state.campus.name}
                                        </Link>
                                        <Typography color="text.primary">Sessions</Typography>
                                    </Breadcrumbs>

                                    <Grid container spacing={2}>
                                        <Grid xs={12} md={4}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                }}
                                                component={Paper}
                                            >
                                                <Container>
                                                    <Box sx={{ padding: 4 }}>

                                                        <Typography component="h2" variant="h3" align="center">
                                                            Session manager
                                                        </Typography>

                                                        <Box textAlign="center" sx={{ mt: 4, mb: 4, width: "80px", marginLeft: "auto", marginRight: "auto" }}>
                                                            <CalendarIcon style={{ fontSize: "4rem" }} />
                                                        </Box>

                                                        <Typography align="center">
                                                            Here you can create new sessions or manage existing ones.
                                                        </Typography>

                                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                            <Button
                                                                variant="contained"
                                                                sx={{ mt: 3, mb: 2 }}
                                                                onClick={this.onCreateSessionClicked.bind(this)}
                                                            >
                                                                Create a new session
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </Container>
                                            </Box>
                                        </Grid>

                                        <Grid xs={12} md={8}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    padding: 4
                                                }}
                                                component={Paper}
                                            >
                                                <Typography sx={{ mb: 2 }} component="h4" variant="h4" alignSelf="flex-start">Your sessions</Typography>
                                                <SessionList
                                                    sessions={this.state.sessions}
                                                    onSessionClicked={this.onSessionClicked.bind(this)}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </>
                        : null
                    }
                </Container>
            </>
        );
    }
}

export default withRouter<SessionsPageProps>(SessionsPage);
