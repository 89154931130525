import { Box, SxProps, Typography } from "@mui/material";
import React, { Component } from "react";
import icon from "../../assets/images/calendar.svg";
import FileCopyIcon from '@mui/icons-material/FileCopy';

export interface AssetIconProps
{
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

interface AssetIconState
{
}

export class AssetIcon extends Component<AssetIconProps, AssetIconState>
{
    constructor(props: AssetIconProps)
    {
        super(props);

        this.state = {
        };
    }

    public render()
    {
        return (
            // <div>
            //     <img src={icon} alt="Calendar icon" style={{ maxWidth: "100%" }} />
            // </div>
            <div>
                <FileCopyIcon style={{ ...this.props.style }} />
            </div>
        );
    }
}