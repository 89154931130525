import { APIResponse } from "../rest/APIResponse";
import { StrapiAPIErrorResponse } from "../response/StrapiAPIErrorResponse";

export class ErrorUnauthorized extends Error
{
    constructor(response: APIResponse)
    {
        super("Error from server");

        let message: string = this.message;

        try
        {
            let errorResponse: StrapiAPIErrorResponse = response.json;

            if (errorResponse.error.message)
            {
                message = errorResponse.error.message;
            }
        }
        catch (error)
        {
            message = error.toString();
        }

        this.message = message;

        Object.setPrototypeOf(this, ErrorUnauthorized.prototype);
    }
}