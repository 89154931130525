import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import { Session } from "../../../../api/models/Session";
import { Box, Divider, ListItem } from "@mui/material";
import { Campus } from "../../../../api/models/Campus";
import { CampusAvatar, CampusAvatarSize } from "./CampusAvatar";

export interface CampusRowItemProps
{
    campus: Campus;
    onClick: (campus: Campus) => void;
}

interface CampusRowItemState 
{
}

export class CampusRowItem extends Component<CampusRowItemProps, CampusRowItemState>
{
    constructor(props: CampusRowItemProps)
    {
        super(props);

        this.state = {
        };
    }

    public render(): JSX.Element
    {
        const inactiveStyle: React.CSSProperties =
        {
            background: "#343759",
            color: "#fff",
        };

        const style: React.CSSProperties =
        {
            borderRadius: "4px",
            display: "flex",
            flexDirection: "row",
            ...inactiveStyle
        };

        return (
            <ListItem
                key={"campus_row_item_" + this.props.campus.id}
                style={{ display: "flex", flexDirection: "row", alignItems: "center", ...style }}
                onClick={this.props.onClick.bind(this)} button>
                <Box sx={{ mr: 5, px: 2, py: 1 }}>
                    <CampusAvatar file={this.props.campus.icon} size={CampusAvatarSize.Medium} />
                </Box>
                <Box style={{ flexDirection: "column" }}>
                    <Typography variant="h5" component="h5" style={{ fontWeight: "600" }}>
                        {this.props.campus.name}
                    </Typography>
                    <Typography component="p">
                        {this.props.campus.description}
                    </Typography>
                </Box>
            </ListItem>
        );
    }
}